/*
 * 업무구분: 영업
 * 화 면 명: MSPFS312P
 * 화면설명: 상담텔러_P
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>

    <!-- popup -->
    <mo-modal class="fts-modal large" ref="modal" title="상담텔러번호">
      <template>
        <div class="wrap-modalcontents">
          <div class="input-top">
            <div class="left">
              <div class="wrap-input row">
                <label> 사원번호 </label>
                <mo-text-field
                    numeric
                    mask="##########"
                    type='number'  
                   v-model="ds_search.staf_eno" 
                   clearable 
                   placeholder="입력하세요" 
                   class="w130" 
                   /> <!-- class명 "w130" 추가 2023-04-07 jy -->
              </div>
              <div class="wrap-input row">
                <label> 성명 </label>
                <mo-text-field v-model="ds_search.bfcer_eply_nm" clearable placeholder="입력하세요" class="w130" /> <!-- class명 "w130" 추가 2023-04-07 jy -->
              </div>
            </div>
            <div class="right">
              <div class="wrap-button row">
                <mo-button primary class="btn-inquiry" @click="fn_searchDetail"> 조회 </mo-button>
              </div>
            </div>
          </div>
          <div class="wrap-table mt16 h-scroll">
            <table class="table row-type">
              <!-- <colgroup>
                <col width="40px">
                <col width="49%">
                <col width="49%">
              </colgroup> -->
              <thead>
                <tr>
                  <th class="w40"> </th>
                  <th class="w49p"> 사원번호 </th>
                  <th class="w49p"> 성명 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, idx) in ds_list" :key="idx" :class="selectedIdx == idx ? 'checked' : ''"  @click="fn_cellClick(idx)">
                  <td>
                    <mo-radio v-model="row.checked" @click="fn_cellClick(idx)" :disabled="ds_list.length == 0"/>
                  </td>
                  <td>{{row.staf_eno}}</td>
                  <td>{{row.bfcer_eply_nm}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Close">닫기</mo-button>
          <mo-button primary size="large" @click="fn_confirm" >확인</mo-button>
        </div>
      </template>

      <!-- alert modal popup -->
      <fs-alert-popup
        ref="alertPopup"
        :popupObj="pAlertPopupObj"
      ></fs-alert-popup>
    </mo-modal>

</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS312P',
  screenId: 'MSPFS312P',
  components: {
    'fs-alert-popup': FSAlertPopup
  },
  props: {
    popupObj: {type: Object, default: null}
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {},
  mounted () {
    this.$bizUtil.insSrnLog('MSPFS105P')
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      ds_search: {
        bfcer_eply_nm: '', // 수익증권사원명, Length : 50
        bfcer_fof_cd: '', // 수익증권지점코드, Length : 8
        staf_eno: '' // 임직원사번, Length : 10
      }, //
      ds_list: [
        // {checked: false,
        //   staf_eno: '',
        //   bfcer_eply_nm: ''
        // }
      ], // 검색결과
      valueRadio: null,
      selectedIdx: '', // 체크 true 인덱스 번호
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      popNum: 3,
      pTitle: '상담텔러',
      pAlertPopupObj: {}
    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    modal () {
      return this.$refs.modal
    }
  },
  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
       * Function명 : fn_historyBack
       * 설명       : 뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack () {
      this.$router.go(-1)
    },

    /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
    ******************************************************************************/
    fn_init () {
      this.ds_search = {
        bfcer_eply_nm: '', // 수익증권사원명, Length : 50
        bfcer_fof_cd: '', // 수익증권지점코드, Length : 8
        staf_eno: '' // 임직원사번, Length : 10
      }

      this.ds_list = [
        // {checked: false,
        //   staf_eno: '',
        //   bfcer_eply_nm: ''
        // }
      ]

      this.selectedIdx = ''
      this.popNum = 3
      this.pTitle = '상담텔러'
    },
    /**********************************************************************************
      * Function명 : fn_searchDetail
      * 설명       : 조회, WFtitleCRUDbtn 의 callback
    **********************************************************************************/
    fn_searchDetail () {
      if (this.ds_search.staf_eno.length < 2 && this.ds_search.bfcer_eply_nm.length < 2) {
        // this.fn_AlertPopup("사원번호, 사원명 둘중 하나는 2글자 이상 입력하여 주십시요!")
        FSCommUtil.gfn_validate(this, '사원번호, 사원명 둘중 하나는 2글자 이상 입력하여 주십시요!')
        return false
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570195_S'
      this.eaiCommObj.params = {
        bfcer_eply_nm: this.ds_search.bfcer_eply_nm, // 수익증권사원명, Length : 50
        bfcer_fof_cd: this.ds_search.bfcer_fof_cd, // 수익증권지점코드, Length : 8
        staf_eno: this.ds_search.staf_eno // 임직원사번, Length : 10
      }
      console.log('서비스 호출시 정보', this.eaiCommObj)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },
    /**********************************************************************************
      * Function명 : fn_callBack
      * 설명       : transaction 응답처리
    **********************************************************************************/
    fn_callBack (response) {
      console.log('상담텔러 response', response)
      this.ds_list = response.data.sy990500_O_00VO

      console.log('data check', this.ds_list)
      this.ds_list.forEach((item) => {
        item.checked = false
      })

      if (this.ds_list.length === 0) {
        let t_popupObj = {content: '사원이 존재하지 않습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
        return false
      }
    },
    /***********************************************************************************
      * Function명 : fn_cellClick
      * 설명       : 조회 데이터 클릭 이벤트
    ***********************************************************************************/
    fn_cellClick (idx) {
      this.ds_list.forEach((item) => {
        item.checked = false
      })
      this.selectedIdx = idx
      console.log('셀클릭, 인덱스 = ', idx)
      this.ds_list[idx].checked = true
    },

    /***********************************************************************************
      * Function명 : fn_confirm
      * 설명       : 확인 버튼 클릭 이벤트
    ***********************************************************************************/
    fn_confirm () {
      if ( FSCommUtil.gfn_isNull(this.selectedIdx) ) {
        // this.fn_AlertPopup("사원을 선택해 주세요");
        FSCommUtil.gfn_validate(this, '사원을 선택해 주세요')
        return
      }
      var objRtn = this.ds_list[this.selectedIdx]
      console.log('popup312 ObjectReturn', objRtn)
      this.$emit('fs-popup-callback', this.popNum, objRtn)

      this.fn_Close(objRtn)
    },

    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
    ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if ( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true

        if ( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if ( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if ( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
      }
      this.$refs.alertPopup.fn_Open()
    },

    /***********************************************************************************
      * Function명 : fn_Open
      * 설명       : 팝업 open
    ***********************************************************************************/
    fn_Open () {
      this.fn_init()

      setTimeout(() => {
        if ( FSCommUtil.gfn_isNull(this.popupObj) ) {
          this.popNum = 3
          this.pTitle = '상담텔러'
        } else {
          this.popNum = FSCommUtil.gfn_isNull(this.popupObj.popNum) ? 3 : this.popupObj.popNum
          this.pTitle = FSCommUtil.gfn_isNull(this.popupObj.pTitle) ? '상담텔러' : this.popupObj.pTitle
        }

        this.modal.open()
      }, 10)
    },
    /***********************************************************************************
      * Function명 : fn_close
      * 설명       : 팝업 close
    ***********************************************************************************/
    fn_Close () {
      this.modal.close()
    }
  }
}
</script>
<style scoped>
</style>
