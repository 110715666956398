/**
 * <PRE>
 * System Name : 사랑On NEXT 시스템(TSS2-MSP)
 * Business Name : 수익증권
 * Class Name : FSBizUtil.js
 * Description : 수익증권 공통 유틸 (AS-IS: ComBiz.js)
 * </PRE>
 */

  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  
  const FSBizUtil = {}
  const stdOldAge = 65


  /************************************************************************************************
   * Function명    : gfn_chkLnCont_Yn
   * 설명          : 고객번호로 대출신규 유무 체크 : 대출신규 유무 조회
                    2021.04.20 ASR210300475_불공정영업행위 금지 로직 반영
   * pData         : 조회 조건
   * alertFunc     : ALERT 함수
   ************************************************************************************************/
  FSBizUtil.gfn_chkLnCont_Yn = async function(pContext, bfcer_cust_no, alertFunc) {
    console.log('gfn_chkLnCont_Yn...')

    if( bfcer_cust_no ) {

      const context     = !pContext ? this : pContext
      let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

      t_eaiCommObj.lv_vm = context
      t_eaiCommObj.auth = 'S'
      t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570262_S'
      t_eaiCommObj.params = {
        bfcer_cust_no : bfcer_cust_no
      }

      let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)
      let result   = 'N'

      if( response && response.data ) {
        // response.data.lnContYn = 'Y'
        if( response.data.lnContYn == 'Y' ) {
          let t_content = '직전 1개월 이내 대출계약이 확인되어 계좌개설이 불가합니다. 고객님께 불공정 영업행위 금지 조항에 대해 설명해주세요.'
          result = 'Y'
          
          if( alertFunc ) {
            let t_popupObj = {
              content: t_content,
            }
            alertFunc.call(context, 0, t_popupObj)

          } else {
            FSCommUtil.gfn_validate(this, t_content)
          }
        }
        return result
      }

    }
  }

  /************************************************************************************************
   * Function명    : gfn_cust_no_sy000500
   * 설명          : 고객번호로 고객정보 조회 : 고령자정보조회
   * pData         : 조회 조건
   * alertFunc     : ALERT 함수
   ************************************************************************************************/
  FSBizUtil.gfn_cust_no_sy000500 = async function(pContext, pData, alertFunc) {
    console.log('gfn_cust_no_sy000500...')

    if( pData ) {

      const context     = !pContext ? this : pContext
      let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

      t_eaiCommObj.lv_vm = context
      t_eaiCommObj.auth = 'S'
      t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570136_S'
      t_eaiCommObj.params = {
        proc_gb : 'S',
        bfcer_cust_no : pData.bfcer_cust_no,
        pw_yn : 'N',
        corp_yn : 'N',
        fnd_cust_nm : '',
        rlnm_cnf_no_encr : '',
        rlnm_no_stnd_birth_mmdd : '',
        userGrpTc : '',
      }

      let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)
      let returnData = {isFlag: false}

      if( response && response.data ) {
        if( FSCommUtil.gfn_length(response.data.sy000500_O_00VO1) < 1 ) {

          let t_content = '고객이 존재하지 않습니다.'
          if( alertFunc ) {
            let t_popupObj = {
              content: t_content,
            }
            alertFunc.call(context, 0, t_popupObj)

          } else {
            FSCommUtil.gfn_validate(this, t_content)
          }
          return returnData

        } else {
          
          // 브릿지계좌전환신청은 체크 제외
          if( !FSCommUtil.gfn_isNull(pData.clasId) && pData.clasId == 'CU102300' ) {
            returnData.isFlag = true
            return returnData

          } else {

            // 65세이상 고령자인지 체크
            let chkOldAgeFlag = FSBizUtil.gfn_chkOldAge(this, response.data.sy000500_O_00VO1[0].rlnm_cnf_no_encr, response.data.sy000500_O_00VO1[0].rlnm_no_knd_cd, alertFunc, 'N')
            if( chkOldAgeFlag ) {
              returnData = response.data.sy000500_O_00VO1[0]
              returnData.isFlag = true
              return returnData

            } else {

              return returnData
            }

          }

        }
      }
      
    }
  }


  /************************************************************************************************
   * Function명    : gfn_chkOldAge
   * 설명          : 실명번호와 실명번호 구분을 가지고 65세이상 고령자인지 체크
   * rname_no      : 실명번호
   * rname_no_gb   : 실명번호 구분
   * alertFunc     : ALERT 함수
   * messageYn     : 메세지 표시 여부
   * 고객이 없거나 오류시 false / 그외는 true
   ************************************************************************************************/
  FSBizUtil.gfn_chkOldAge = function (pContext, rname_no, rname_no_gb, alertFunc, messageYn, clasId) {
    const context     = !pContext ? this : pContext

    let vRname_no     = rname_no
    let vRname_no_gb  = rname_no_gb
    let vBirth_dt     = "" //생일앞 6자리
    let tmp           = ""
    
    // 실명번호종류
    if( vRname_no_gb == "01" || vRname_no_gb == "03" || vRname_no_gb == "04" || vRname_no_gb == "05" ||  vRname_no_gb == "06" ) {
      if( FSCommUtil.gfn_length(vRname_no) < 13 ){
        // 메세지 처리
        let t_messageData = {
          content: '실명번호자리수 오류,실명번호 ['+ vRname_no +']'
        }
        FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
        return false
      }
      
      // 1step : 생일 8자리 구하기
      tmp         = FSCommUtil.gfn_substr(vRname_no, 6, 1) //실명번호 7번째자리 
      vBirth_dt   = FSCommUtil.gfn_left(vRname_no, 6)
      
      if( tmp == "3" || tmp == "4" || tmp == "7" || tmp == "8" ) {
        vBirth_dt = "20" + vBirth_dt
      } else {
        vBirth_dt = "19" + vBirth_dt
      }
      
      // 2step 만나이 계산
      let vThisYear = FSCommUtil.gfn_left(FSCommUtil.gfn_strToday(), 4)        //오늘년도 4자리
      let vThisDate = FSCommUtil.gfn_substr(FSCommUtil.gfn_strToday(), 4, 4)   //오늘날짜 4자리
      
      
      /* ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하) S
        ㄴ만 80세 이상 초고령자 펀드 판매 금지 */
      
      if(clasId != "CS700500") { // 설문등록시 제외
      
        var chk80 = ( parseInt( vThisYear ) - 80  ) + "" + vThisDate
        if(vBirth_dt <= chk80) {

          // 메세지 처리
          let t_messageData = {
            type: 4,
            content: "<금융취약소비자 펀드 가입 제한>\n\n▶회사 판매 정책에 따라 금융취약소비자(만 80세 이상)의 펀드가입을 제한하고 있습니다."
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)

          return false
        }
      }

          /* ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하) E
        ㄴ만 80세 이상 초고령자 펀드 판매 금지 */		
          
          
      /* ASR201100735_그룹신규(#1010) 브릿지계좌신규 등 업무보완 (2020.12.22 이승하) 
        ㄴ고령자 기준 나이 70->65 */
      let isChkAge  = ( parseInt( vThisYear ) - stdOldAge  ) + "" +  vThisDate  ;  // 오늘기준으로 만 65세가 되는 생일의 기준일자
    
      // 3step  65세 이상 고령자 인지 
      if(vBirth_dt  <= isChkAge) {	// 고령자일경우 0세이상에대한 인지 alert 표출한다.
        console.log("만65세이상")

        // 메세지 처리
        if( FSCommUtil.gfn_isNull(messageYn) ) {
          let t_messageData = {
            type: 3,
            content: "<고령투자자 보호 안내>\n\n▶모든 고령 투자자에 대해 '조력자 지정 신청서 및 설명 확인서' 징구하시기 바랍니다.\n\n▶투자권유 유의상품 권유시 FP센터장이 '고령투자자에 대한 투자권유 유의상품에 대한 적정성 확인'을 해야 합니다."
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
        }

        return true

      } else {
        console.log("만65세미만")
        return true
      }
      
    } else {
      // 실명구분으로 나이체크 불가
      return true
    }
  }

  /************************************************************************************************
   * Function명    : gfn_chkOldAge_Yn
   * 설명          : 실명번호와 실명번호 구분을 가지고 65세이상 고령자인지 체크 하여 값 Y/N으로 리턴 
   * rname_no      : 실명번호
   * rname_no_gb   : 실명번호 구분
   * alertFunc     : ALERT 함수
   * messageYn     : 메세지 표시 여부
   * 고객이 없거나 오류시 false / 그외는 true
   ************************************************************************************************/
  FSBizUtil.gfn_chkOldAge_Yn = function (pContext, rname_no, rname_no_gb, alertFunc, messageYn) {

    const context     = !pContext ? this : pContext

    let vRname_no     = rname_no
    let vRname_no_gb  = rname_no_gb
    let vBirth_dt     = ""  //생일앞 6자리
    let tmp           = ""
    let retValue      = ""

    // 실명번호종류
    if( vRname_no_gb == "01" || vRname_no_gb == "03" || vRname_no_gb == "04" || vRname_no_gb == "05" ||  vRname_no_gb == "06" ) {
      if( FSCommUtil.gfn_length(vRname_no) != 13 ){
        // 메세지 처리
        let t_messageData = {
          content: '실명번호자리수 오류,실명번호 ['+ vRname_no +']'
        }
        FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)

        return false
      }
      
      // 1step : 생일 8자리 구하기
      tmp       = FSCommUtil.gfn_substr(vRname_no, 6, 1) //실명번호 7번째자리 
      vBirth_dt = FSCommUtil.gfn_left(vRname_no, 6)
      
      if( tmp == "3" || tmp == "4" || tmp == "7" || tmp == "8" ) {
        vBirth_dt = "20" + vBirth_dt
      } else {
        vBirth_dt = "19" + vBirth_dt
      }
       
      // 2step   만나이 계산	 
      let vThisYear = FSCommUtil.gfn_left(FSCommUtil.gfn_strToday(), 4)       //오늘년도 4자리
      let vThisDate = FSCommUtil.gfn_substr(FSCommUtil.gfn_strToday(), 4, 4)  //오늘날짜 4자리
      
      
      /* ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하) S
         →만 80세 이상 초고령자 펀드 가입 불가 */
         let chk80 = ( parseInt( vThisYear ) - 80  ) + "" +  vThisDate
          //alert(vBirth_dt) ;
          if(vBirth_dt <= chk80) {

            // 메세지 처리
            if( FSCommUtil.gfn_isNull(messageYn) ) {
              let t_messageData = {
                type: 4,
                content: "<금융취약소비자 펀드 가입 제한>\n\n▶회사 판매 정책에 따라 금융취약소비자(만 80세 이상)의 펀드가입을 제한하고 있습니다."
              }
              FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
            }

            retValue = "O"
            return retValue
          }
          // ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하) E
  
      
      /* ASR201100735_그룹신규(#1010) 브릿지계좌신규 등 업무보완 (2020.12.22 이승하) 
         ㄴ고령자 기준 나이 70->65 */
      // var isChkAge  = ( parseInt( vThisYear ) - 70  ) +""+  vThisDate  ;  // 오늘기준으로 만 70세가 되는 생일의 기준일자
      let isChkAge  = ( parseInt( vThisYear ) - stdOldAge  ) + "" +  vThisDate // 오늘기준으로 만 65세가 되는 생일의 기준일자 
      
      // 3step  70세 이상 고령자 인지 
      if( vBirth_dt  <= isChkAge ) {
        console.log("만65세이상")
        retValue ="Y";
        return retValue 
      } else {
        console.log("만65세미만")
        retValue ="N"
        return retValue
      }
      
    } else {
      //실명구분으로 나이체크 불가
      retValue ="N"
      return retValue
    }
  }

 
  /************************************************************************************************
   * Function명    : gfn_chkCoprtPesonAsgn_Yn
   * 설명          : 고령자의 조력자 관련 처리
   * pData         : 조회 조건
   * alertFunc     : ALERT 함수
   ************************************************************************************************/
   FSBizUtil.gfn_chkCoprtPesonAsgn_Yn = function (pContext, pData, alertFunc) {
    console.log('gfn_chkCoprtPesonAsgn_Yn...')

    const context = !pContext ? this : pContext

    if ( pData.old_yn === 'Y' && pData.coprt_peson_asgn_yn === 'N' ) {

      // 고령자여부 Y && 조력자등록여부 N
      let t_popupObj = {
        cancel: false,
        confirm: true,
        confirmFunc: function () {
          this.$router.push({
            name: 'MSPFS301M', 
            params: {
              // rmvl_srn_id: 'MSPFS300M',
              menu_id: pData.menu_id,
              ed_cust_no: pData.cust_no,
              ed_group_no: pData.group_no,
              fnd_cust_nm: pData.cust_nm,
            }
          })
        },
      }
      alertFunc.call(context, 3, t_popupObj)

      return false

    } else if( pData.old_yn == 'Y' && pData.coprt_peson_asgn_yn == 'Y' && pData.coprt_peson_asgn_pop_chk_yn != true ) {
      /**
       * coprt_peson_asgn_pop_chk_yn 값이 트루이면 해당 팝업을 한번 호출한거기 때문에 두번이상은 제외. 단, 재조회 버튼시에는 다시 실행.
       * 해당 팝업을 종료하고 fn_SearchValid 나머지를 fn_SearchValid 를 다시 호출해서 처리
       * confirmFunc 에서 data coprt_peson_asgn_pop_chk_yn 를 true로 줘서 해당 라인에 왔을시 아래 else로 분기가 이동
       * true를 리턴하여 3가지 로직 자금세탁방지 KYC RULE Chec , FATCA(해외금융계좌신고제도) Check, 신탁수탁 시스템 투자성향 설문 데이터 조회
       * 를 체크하게 한다.
       */
      let t_popupObj = {
        cancel: false,
        confirm: true,
        confirmFunc: function () {
          context.fn_Cust316p(pData)
          context.$data.coprtPesonAsgnPopChkYn = true
        }
      }
      // 고령자여부 Y && 조력자등록여부 Y
      alertFunc.call(context, 3, t_popupObj)
      
      return false // 23.08.14 리턴 false 로 줘서 팝업 확인 콜백에서 처리하도록 수정함

    } else {

      return true
    }
  }


  /**************************************************************************************************************
   * Function명    : gfn_chkCoprtPesonAsgn_Yn
   * 설명          : 고령자 유의상품 처리
   * cust_no       : 고객번호
   * fund_cd       : 펀드코드
   * proc_gb       : 처리구분 
   * return        : 1(skip), 2(결재진행중), 3(센터장부재로 대기 또는 이미등록된 미결재건존재), 공백(에러)
   ***************************************************************************************************************/
  FSBizUtil.gfn_agedPesonPrdtChk = async function(pContext, cust_no, fund_cd, proc_gb, tr_code) {
    console.log( "===================== 고령자 유의상품 처리 ================" )
    
    const context = !pContext ? this : pContext
    
    if( FSCommUtil.gfn_length( cust_no ) < 7 ) {
      FSCommUtil.gfn_validate(context, '고객번호를 확인하세요.')
      return
    }
    
    if( FSCommUtil.gfn_length( cust_no ) > 7 ) {
      cust_no = FSCommUtil.gfn_substr(cust_no, 0, 7)
    }

    let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

    t_eaiCommObj.lv_vm = context
    t_eaiCommObj.auth = 'S'
    t_eaiCommObj.commHeaderVO.eaiId = proc_gb == 'I' ? 'C391_F10570261_S' : 'C391_F10570260_S'
    t_eaiCommObj.params = {
      bfcer_cust_no : cust_no,
      bfcer_fnd_cd  : fund_cd,
      tr_code       : tr_code,
    }

    let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

    if( response.data.error_msg == '809900' ) {
      if( FSCommUtil.gfn_trim(response.data.befr_cnf_trt_yn) == '' ) {
        FSCommUtil.gfn_validate(context, '고령자 투자유의 상품 신청 처리중 오류가 발생했습니다.')

      } else if( response.data.befr_cnf_trt_yn == '2' ) {
        FSCommUtil.gfn_validate(context, '고령자 투자유의 상품 사전확인 승인 처리가 진행중 입니다. 잠시 기다려 주십시오.')          

      } else if( response.data.befr_cnf_trt_yn == '3' ) {
        FSCommUtil.gfn_validate(context, '고령자 투자유의 상품 사전확인 승인 처리가 완료되기 전까지 해당상품은 입력이 불가능 합니다.')          
      }
    }

    return response.data.befr_cnf_trt_yn
  }  



  export default FSBizUtil
  // eslint-disable-next-line
