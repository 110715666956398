/**
 * <PRE>
 * System Name : 사랑On NEXT 시스템(TSS2-MSP)
 * Business Name : 수익증권
 * Class Name : FSFatcaUtil.js
 * Description : 수익증권 공통 유틸 (AS-IS: ComFatca.js)
 * </PRE>
 */

  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'

  
  const FSFatcaUtil = {}


  /************************************************************************************************
   * Function명    : fatca_check
   * 설명          : FATCA(해외금융계좌신고제도)
   * acct_no       : 계좌번호/고객번호
   * parent_tr_cd  : 화면 ClsId
   * popupFunc     : 팝업 open 함수
   * alertFunc     : ALERT 함수
   ************************************************************************************************/
   FSFatcaUtil.fatca_check = async function(pContext, cust_no, parent_tr_cd, popupFunc, alertFunc) {
    console.log('fatca_check...')

    const context     = !pContext ? this : pContext
    let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

    t_eaiCommObj.lv_vm = context
    t_eaiCommObj.auth = 'S'
    t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570265_S'
    t_eaiCommObj.params = {
      bfcer_cust_no : cust_no,
      parent_tr_cd : parent_tr_cd,
    }

    let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

    if( response && response.data ) {
      console.log(response.data)

      let cd = FSCommUtil.gfn_trim(response.data.rt_cd)
      console.log('cd=' + cd)
      // cd = '4'

      if(cd == "0") {
        console.log('[FATCA] 각종에러발생')

      } else if(cd == "1") {
        return false

      // 본인확인서기발급 or 20140630기존고객 or 법인실사제외대상
      } else if(cd == "2") {
        return true
        
      // 개인팝업띄운다.
      } else if(cd == "4") {

        let oArg = {
          parent_tr_cd: parent_tr_cd, 
          bfcer_cust_no: cust_no
        }
        popupFunc.call(context, 'MSPFS303P', oArg)
        return false

      } else {
        console.log('[FATCA] 각종에러발생')

        let t_messageData = {
          content: response.data.error_msg
        }
        FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
        return false
      }

    }    

  }

  export default FSFatcaUtil
  // eslint-disable-next-line
