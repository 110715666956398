/*
 * 업무구분: 영업
 * 화 면 명: MSPFS301P
 * 화면설명: AML_실제소유자여부확인_P - CU009901P CU009901S (개인)
 * 작 성 일: 2023.05.09
 * 작 성 자: 이태흥
 */

<template>   

    <mo-modal class="fts-modal medium" ref="modal" title="AML 실제소유자여부확인">
      <template>
        <div class="wrap-modalcontents">
          
          <p class="exp">KYC 확인작업이 필요합니다. 아래 항목을 입력하고 확인버튼을 눌러 주세요.</p>
          <div class="wrap-table mt-1">
            <table class="table col-type">
              <tbody>
                <tr>
                  <th class="w128">
                    거래금액
                  </th>
                  <td>
                    <div class="wrap-input">
                      <mo-decimal-field
                        numeric
                        ref="trd_amt"
                        class="input-long" 
                        v-model="lv_trd_amt.value" 
                        mask="number" 
                        maxlength="19"
                        placeholder="입력하세요" 
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    고객구분
                  </th>
                  <td>
                    개인
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-3">
            <p class="exp" v-if="lv_real_owner_gb.value=='N'">아래 설문에 응답하시기 바랍니다.</p>
            <div class="wrap-table mt-1">
              <table class="table col-type">
                <tbody>
                  <tr>
                    <th class="w128">
                      실제소유자여부
                    </th>
                    <td>
                      <mo-radio-wrapper 
                        :items="real_owner_gb_radio" 
                        v-model="lv_real_owner_gb.value" 
                        class="row"
                        :class="lv_real_owner_gb.error ? 'error' : ''"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="exp-txt mtm10" v-if="lv_real_owner_gb.value=='N'"><em class="colorR">실제소유자 '아니오'를 선택하셨습니다. 아래 항목을 빠짐없이 기입해 주십시오.</em></p>
            </div>
          </div>

          <div v-if="lv_real_owner_gb.value=='N'">
            <h2 class="mt-3">실제소유자가 아닌 경우</h2>
            <div class="wrap-table mt-2">
              <table class="table col-type">
                <tbody>
                  <tr>
                    <th class="w167">
                      <span class="emphasis">실제소유자의 성명</span>
                    </th>
                    <td>
                      <div class="wrap-input">
                        <mo-text-field 
                          ref="real_owner_nm"
                          class="w130" 
                          :class="lv_real_owner_nm.error ? 'error' : ''"
                          v-model="lv_real_owner_nm.value" 
                          placeholder="입력하세요" 
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="emphasis">실제소유자의 실명번호</span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field 
                          class="w80" 
                          ref="rname_no"
                          :class="lv_rname_no.error ? 'error' : ''"
                          v-model="lv_rname_no.value" 
                          placeholder="앞 6자리" 
                          type="number"
                          maxlength="6"
                        />
                        <m-trans-key-input 
                          v-if="isMobile && isMtrans"
                          ref="rname_no2"
                          class="w80"
                          :class="lv_rname_no2.error ? 'error' : ''"
                          v-model="lv_rname_no2.value" 
                          type="numberMax7" 
                          start="1" 
                          end="-1" 
                          dialog="Y"
                          :isClear="lv_isClear"
                          @masked-txt="fn_SetMaskedTxt"
                        />
                        <mo-text-field 
                          v-else
                          ref="rname_no2"                        
                          type="password" 
                          :class="lv_rname_no2.error ? 'error' : ''"
                          v-model="lv_rname_no2.value" 
                          placeholder="뒤 7자리" 
                          class="w80" 
                          maxlength="7" 
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="emphasis">실제소유자의 국적</span>
                    </th>
                    <td>
                      <div class="wrap-input">
                        <mo-dropdown 
                          :items="lv_commCode.code981" 
                          v-model="lv_ctzn_natn_cd.value" 
                          class="w130"
                          :class="lv_ctzn_natn_cd.error ? 'error' : ''"
                          placeholder="선택하세요" 
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
          <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
        </div>
      </template>


    </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS301P",
    screenId: "MSPFS301P",
    props: {
        popupObj: {type:Object, default:null},
    },
    components: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SetCommCode()
    },
    mounted() {
      this.fn_Init()

      this.$bizUtil.insSrnLog("MSPFS301P")
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        lv_commCode: {},
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans: this.getStore('fsStore').getters.getState.isMtrans, // 보안키패드 적용 여부
        lv_masked_val: '',          // 마스크 변수 체크
        lv_isClear: false,            // 보안키패드 초기화
        lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
        // 입력사항
        lv_trd_amt: {},         // 거래금액
        lv_corp_gb: {},         // 개인/법인구분
        lv_real_owner_gb: {},   // 실제소유자구분
        lv_real_owner_nm: {},   // 실제소유자명
        lv_rname_no: {},        // 실제소유자실명번호(개인일떄만 필요)
        lv_rname_no2: {},       // 실제소유자실명번호(개인일떄만 필요)
        lv_ctzn_natn_cd: {},    // 실제소유자국적


        // submit data
        lv_AmlResultData: {},

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      modal() {
        return this.$refs.modal
      },

      real_owner_gb_radio() {
        let rtn= []
        rtn.push({value: 'Y', text: '예'})
        rtn.push({value: 'N', text: '아니오'})
        return rtn
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_SetMaskedTxt
       * 설명       : 보안키패드 체크
       ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_val = val                
      },  
      fn_Open() {
        this.fn_Init()

        this.modal.open()
      },
      fn_Close() {
        this.modal.close()
      },
      fn_Confirm() {
        console.log('fn_Confirm...')

        if( !this.fn_ValidateForm() ) {
          return
        }

        if( FSCommUtil.gfn_isNull(this.lv_trd_amt.value) ) {
          this.lv_trd_amt.value = 0
        }

        // 전자서식 호출
        this.fn_ReportPrint()

        this.lv_AmlResultData = {
          cust_no: this.popupObj.cust_no, // 고객번호 추가
          trd_amt: this.lv_trd_amt.value,
          corp_gb: this.lv_corp_gb.value,
          real_owner_gb: this.lv_real_owner_gb.value,
          real_owner_nm: this.lv_real_owner_nm.value,
          rname_no: this.lv_rname_no.value + this.lv_rname_no2.value,
          ctzn_natn_cd: this.lv_ctzn_natn_cd.value,
          birth_dt: '',     // 법인 제외
          ques_info_Cd: '', // 법인 제외
        }

        this.$emit('fs-popup-callback', 'MSPFS301P', this.lv_AmlResultData)
        this.modal.close()
      },


      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = [
          {'cId':'981', 'dayChkYn':'N'},
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },
      fn_SetCommCodeResult(pResult) {
          this.lv_commCode = pResult
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS301P] fn_Init...')

        // 입력사항
        this.lv_trd_amt           = FSCommUtil.gfn_dataSet(2, '', false, '', 'trd_amt')       // 거래금액
        this.lv_corp_gb           = FSCommUtil.gfn_dataSet(0, 'N')                            // 개인/법인구분
        this.lv_real_owner_gb     = FSCommUtil.gfn_dataSet(0, '', false, '')                  // 실제소유자구분
        this.lv_real_owner_nm     = FSCommUtil.gfn_dataSet(0, '', false, '', 'real_owner_nm') // 실제소유자명
        this.lv_rname_no          = FSCommUtil.gfn_dataSet(0, '', false, '', 'rname_no')      // 실제소유자실명번호(개인일떄만 필요)
        this.lv_rname_no2         = FSCommUtil.gfn_dataSet(0, '', false, '', 'rname_no2')     // 실제소유자실명번호(개인일떄만 필요)        
        this.lv_ctzn_natn_cd      = FSCommUtil.gfn_dataSet(0, '', false, '')                  // 실제소유자국적


        // submit data
        this.lv_AmlResultData     = {}

        // 거래금액 focus
        this.$refs['trd_amt'].focus()

        // 보안키패드 초기화
        this.lv_isClear = !this.lv_isClear ? true : false
      },

      /******************************************************************************
       * Function명 : fn_ValidateForm
       * 설명       : 필수값 체크
       ******************************************************************************/
      fn_ValidateForm() {
        console.log('fn_ValidateForm...')

        let t_return = true


        // 개인
        if(this.lv_corp_gb.value == 'N') {

          let t_vaildList1 = [
            this.lv_real_owner_gb
          ]

          if( FSCommUtil.gfn_isValidList(this, t_vaildList1, t_return) ) {
            t_return = false
          }
        
          if( t_return && this.lv_real_owner_gb.value == 'N' ) {
            let t_vaildList2 = [
              this.lv_real_owner_nm,
              this.lv_rname_no,
              this.lv_rname_no2,
              this.lv_ctzn_natn_cd,              
            ]

            if( FSCommUtil.gfn_isValidList(this, t_vaildList2, t_return) ) {
              t_return = false
            }

            if( FSCommUtil.gfn_length(this.lv_rname_no.value) != 6 ) {
              FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
              this.lv_rname_no.error = true
              this.$refs['rname_no'].focus()
              t_return = false

            }
            
            if(this.isMobile && this.isMtrans ) {
              if ( FSCommUtil.gfn_length(this.lv_masked_val) != 7 ) {
                this.lv_rname_no2.error = true
                FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
                t_return = false
              }           
            } else {
              if ( FSCommUtil.gfn_length(this.lv_rname_no2.value) != 7 || !FSCommUtil.gfn_isNum(this.lv_rname_no2.value) ){
                FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
                this.lv_rname_no2.error = true
                this.$refs['rname_no2'].focus()
                t_return = false
              }
            }

            // else if( FSCommUtil.gfn_length(this.lv_rname_no2.value) != 7 || !FSCommUtil.gfn_isNum(this.lv_rname_no2.value) ) {
            //   FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
            //   this.lv_rname_no2.error = true
            //   this.$refs['rname_no2'].focus()
            //   t_return = false
            // }

          }
        }
        return t_return
      },

      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      fn_ReportPrint () {
        console.log('fn_ReportPrint....')

        let formList = []
        let FS000023 = {formId: 'FS000023'}
        let nationality = ''
        let selectCd = this.lv_ctzn_natn_cd.value

        this.lv_commCode.code981.forEach(function(item) {
          if( item.value == selectCd ) {
            nationality = item.text
          }
        })

        FS000023.mappingData = {
          realOwnerGb         : this.lv_real_owner_gb.value == 'Y' ? '1' : '2',   //소유자여부 체크박스
          realOwnerNm         : this.lv_real_owner_nm.value,                      //확인사항 성명
          realOwnerRnameNo    : this.isMobile ? this.lv_rname_no.value + this.lv_masked_val : this.lv_rname_no.value + this.lv_rname_no2.value, //확인사항 실명번호
          nationality         : nationality,                                      //확인사항 국적
          fpNm                : this.lv_basInfo.userNm,                           //처리자명
        }
        formList.push(FS000023)

        FSInfoUtil.commReportInfo(this, formList, '', false, 'MSPFS301P')
        // FSInfoUtil.commReportInfo(this, formList)
      },


    }
  }
</script>
<style scoped>
</style>