/*
 * 업무구분: 영업
 * 화 면 명: MSPFS308P SY000500P
 * 화면설명: 고객정보_P
 * 작 성 일: 2023.05.03
 * 작 성 자: 이태흥
 */
<template>

  <mo-modal class="fts-modal large" ref="modal" title="고객조회">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top labelmw48">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left mr0 gap40">
                <div class="wrap-input row">
                  <label> 고객번호 </label>
                  <mo-text-field 
                    ref="cust_no"
                    v-model="lv_cust_no.value" 
                    class="w130"
                    type="number"
                    :class="lv_cust_no.error ? 'error' : ''"
                    @input="fn_clear('appointCust')"
                  />
                </div>
                <div class="wrap-input row">
                  <label class="emphasis"> 비밀번호 </label> <!-- 비밀번호 보안키패드 적용 20230811 정지윤 -->
                  <m-trans-key-input
                    v-if="isMobile && isMtrans"
                    ref="password"
                    class="w80"
                    :class="lv_password.error ? 'error' : ''"
                    v-model="lv_password.value"
                    type="numberMax4"
                    dialog="Y"
                    start="0" 
                    end="-1"
                    :isClear="lv_isClear"
                    @masked-txt="fn_SetMaskedTxt"         
                  />
                  <mo-text-field 
                    v-else
                    ref="password"
                    v-model="lv_password.value" 
                    type="password" 
                    class="w130"
                    :class="lv_password.error ? 'error' : ''"
                    :disabled="lv_password.disabled"
                    maxlength="4"
                    @input="fn_clear('password')"
                  />
                </div>
                <div class="wrap-input row">
                  <label> 고객유형 </label>
                  <mo-radio 
                    v-model="lv_corp_yn.value" 
                    disabled
                  >개인</mo-radio>
                </div>
              </div>
              <div class="right w20p"></div>
            </div>
            <div class="row w100p">
              <div class="left mr0 gap40">
                <div class="wrap-input row">
                  <label> 고객명 </label>
                  <mo-text-field 
                    ref="cust_nm"
                    v-model="lv_cust_nm.value" 
                    class="w130" 
                    :class="lv_cust_nm.error ? 'error' : ''"
                    @input="fn_clear('custNm')"
                    @keyup="fn_RegEx($event, lv_cust_nm)"
                  />
                </div>
                <div class="wrap-input row">
                  <label> 생년월일 </label>
                  <mo-date-picker 
                    v-model="lv_rlnm_no_stnd_birth_mmdd.value" 
                    :bottom="false" 
                    class="w130"
                    :class="lv_rlnm_no_stnd_birth_mmdd.error ? 'error' : ''"    
                    @input="fn_clear('birth')"                
                  />
                </div>
              </div>
            </div>
            <div class="row w100p gap28">
              <div class="wrap-input row">
                <label class="emphasis"> 고객조회사유 </label>
                <mo-dropdown 
                  :items="lv_commCode.code383" 
                  v-model="lv_bfcer_inqr_rsn_cd.value" 
                  class="w306"
                  :class="lv_bfcer_inqr_rsn_cd.error ? 'error' : ''"
                  placeholder="전체"
                  ref="dropdown1"
                  @input="fn_clear('inqr_rsn_cd')"
                />
              </div>
            </div>
            <div class="row w100p gap28">
              <div class="left w100p mr0">
                <div class="wrap-input row">
                  <label class="emphasis"> 상세사유 </label>
                  <mo-text-field 
                    ref="inqr_dtl_rsn"
                    class="input-long" 
                    :class="lv_inqr_dtl_rsn.error ? 'error' : ''"                    
                    v-model="lv_inqr_dtl_rsn.value"
                    :disabled="lv_inqr_dtl_rsn.disabled"
                    maxlength="40"    
                    @input="fn_clear('inqr_dtl_rsn')"
                
                  />
                </div>
              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button primary class="btn-inquiry" @click="fn_CustListSearch()"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrap-table h-scroll-200 mt16">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w40" v-if="lv_isAccn != true"> </th>
                <th> 고객번호 </th>
                <th> 고객명 </th>
                <th> 주민(사업자)등록번호 </th>
                <th v-if="lv_isAccn != true"> 휴대폰 </th>
                <th class="w100" v-if="lv_isAccn == true"> 계좌정보 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(listArray, idx) in lv_custSearchList" :key="idx" :class="{'checked': lv_selectIdx == String(idx+1)}" @click="lv_selectIdx = String(idx+1)" >
                <td v-if="lv_isAccn != true">
                  <mo-radio v-model="lv_selectIdx" :value="`${String(idx+1)}`"></mo-radio>
                </td>
                <td>{{ listArray.bfcer_cust_no }}</td>
                <td>{{ listArray.fnd_cust_nm }}</td>
                <td>{{ listArray.rlnm_cnf_no_encr }}</td>
                <td v-if="lv_isAccn != true">{{ listArray.phone_no }}</td>
                <td v-if="lv_isAccn == true">
                  <mo-button @click="fn_OpenAccnPopup()">계좌정보</mo-button>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </template>

    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
        <mo-button primary size="large" @click="fn_CustHistory()" v-if="lv_isAccn != true">확인</mo-button>
      </div>
    </template>

    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>    

    <!-- popup326 -->
    <msp-fs-326p
      ref="popup326"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup326Obj"
    ></msp-fs-326p>

  </mo-modal>

</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
  import MSPFS326P from '~/src/ui/fs/MSPFS326P'  // 고객계좌검색 팝업


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS308P",
    screenId: "MSPFS308P",
    components: {
      'fs-alert-popup': FSAlertPopup,
      'msp-fs-326p': MSPFS326P,
    }, 
    props: {
      popupObj: {type:Object, default:null},
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pAlertPopupObj: {},        
        lv_commCode: {},
        pCustInfoObj: {},
        pPopup326Obj: {},

        // 보안키패드
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
        lv_isClear: false,              // 보안키패드 초기화
        lv_masked_val: '',              // 마스크 변수 체크

        // 검색조건
        lv_cust_no: {},                 // 고객번호
        lv_corp_yn: {},                 // 법인여부        
        lv_cust_nm: {},                 // 고객명

        lv_password: {},                // 비밀번호
        lv_pw_yn: {},                   // 비밀번호여부

        lv_rlnm_cnf_no_encr: {},        // 실명확인번호
        lv_rlnm_no_stnd_birth_mmdd: {}, // 생년월일

        lv_bfcer_inqr_rsn_cd: {},       // 고객조회사유
        lv_inqr_dtl_rsn: {},            // 상세사유

        // 조회결과
        lv_custSearchList: [],
        lv_selectIdx: null,
        lv_isAccn: false,
        lv_accn_list:[],

        lv_popupId: 0,


        /***********************************************************************************
         * F10570136 : 고객목록조회
         * F10570137 : 고객정보이력등록
         ***********************************************************************************/
        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
        
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SetCommCode()
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPFS308P')
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      modal() {
        return this.$refs.modal
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
      // 고객명 injection 문자 치환
      // 'lv_cust_nm.value': {
      //   handler(after, before) {
      //     if( after != '' ) {
      //       let reg = /[';|+\-%_]/gim
      //       let regStr = after.replace(reg, '') // 빈 문자열로 치환
      //       this.lv_cust_nm.value = regStr 
      //     }
      //   },
      //   immediate: false,
      //   deep: true
      // },

      // 고객조회사유 선택
      'lv_bfcer_inqr_rsn_cd.value': {
        handler(after, before) {
          if( after != '' ) {
            this.lv_inqr_dtl_rsn.value = ''

            if( after == '30' ) {
              this.lv_inqr_dtl_rsn.disabled = false
            } else {
              this.lv_inqr_dtl_rsn.disabled = true
            }
          }
        },
        deep: true
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_RegEx ( event, targetData ) {
        console.log(event.code)
        let reg = /[';|+\-%_]/gim
        let regStr = event.currentTarget.value.replace(reg, '') // 빈 문자열로 치환
        event.currentTarget.value = regStr  // 컴포넌트 입력 부분 
        targetData.value = regStr           // 컴포넌트 바인드된 v-model 값 수정
      },
      fn_Open() {
        this.fn_Init()

        setTimeout(() => {
          this.lv_popupId = FSCommUtil.gfn_isNull(this.popupObj.popupId) ? 0 : this.popupObj.popupId
          this.lv_isAccn  = FSCommUtil.gfn_isNull(this.popupObj.isAccn) ? false : this.popupObj.isAccn

          this.lv_screenId = ''
          if( !FSCommUtil.gfn_isNull(this.popupObj.screenId) ) {
            if( this.popupObj.screenId == 'MSPFS110M' || this.popupObj.screenId == 'MSPFS111M' ) {
              this.lv_password.disabled = true
            }
          }

          this.modal.open()
        }, 10)
      },
      fn_Close() {
        this.modal.close()
        this.closeDropdown()
      },
      closeDropdown() {
        this.$refs.dropdown1.close()
      },

      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = [
          {'cId':'383', 'dayChkYn':'N'},
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },
      fn_SetCommCodeResult(pResult) {
          this.lv_commCode = pResult
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS308P] fn_Init...')

        // 검색조건
        this.lv_cust_no                 = FSCommUtil.gfn_dataSet(0, '')                             // 고객번호
        this.lv_corp_yn                 = FSCommUtil.gfn_dataSet(0, true)                           // 법인여부
        this.lv_cust_nm                 = FSCommUtil.gfn_dataSet(0, '')                             // 고객명
        
        this.lv_password                = FSCommUtil.gfn_dataSet(0, '', false, '', 'password')      // 비밀번호
        this.lv_pw_yn                   = FSCommUtil.gfn_dataSet(0, '')                             // 비밀번호여부


        this.lv_rlnm_cnf_no_encr        = FSCommUtil.gfn_dataSet(0, '')                             // 실명확인번호
        this.lv_rlnm_no_stnd_birth_mmdd = FSCommUtil.gfn_dataSet(0, '')                             // 생년월일

        this.lv_bfcer_inqr_rsn_cd       = FSCommUtil.gfn_dataSet(0, '')                             // 고객조회사유
        this.lv_inqr_dtl_rsn            = FSCommUtil.gfn_dataSet(0, '', false, '', 'inqr_dtl_rsn')  // 상세사유

        // 조회결과
        this.lv_custSearchList          = []
        this.lv_selectIdx               = null
        this.lv_isAccn                  = false
        this.lv_accn_list               = []

        // 보안키패드 초기화
        this.lv_isClear = !this.lv_isClear ? true : false
        this.lv_masked_val = ''
      },


      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },


      /******************************************************************************
       * Function명 : fn_CustListSearch, fn_CustListSearchResult
       * 설명       : 고객목록조회
       ******************************************************************************/
      fn_CustListSearch() {
        console.log('fn_CustListSearch.....')

        // 초기화
        this.lv_password.error = false

        let passwd = this.isMobile ? this.lv_masked_val : this.lv_password.value

        if( this.lv_password.disabled == false ) {
          if( FSCommUtil.gfn_isNull(passwd) ) {
            FSCommUtil.gfn_validate(this, '비밀번호를 입력하십시요.')
            this.lv_password.error = true
            this.$refs['password'].focus()
            return

          } else if( FSCommUtil.gfn_length(passwd) < 4 ) {
            FSCommUtil.gfn_validate(this, '비밀번호 입력오류입니다.')
            this.lv_password.error = true
            this.$refs['password'].focus()
            return
          }
        }

	      if( !this.fn_Validate() ) {
          return
        }

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570136_S'
        this.eaiCommObj.params = {
          bfcer_cust_no           : this.lv_cust_no.value,                  // 고객번호
          corp_yn                 : 'N',                                    // 법인여부
          fnd_cust_nm             : this.lv_cust_nm.value,                  // 고객명
          password_enc            : this.lv_password.value,                 // 패스워드
          pw_yn                   : this.lv_password.disabled ? 'N' : 'Y',  // 패스워드여부
          rlnm_cnf_no_encr        : '',                                     // 실명확인번호
          rlnm_no_stnd_birth_mmdd : this.lv_rlnm_no_stnd_birth_mmdd.value.replace(/-/g, ''),  // 생년월일
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustListSearchResult)
      },

      fn_CustListSearchResult(pResultData) {
        console.log('fn_CustListSearchResult.....')

        let t_popupObj = {}
        let t_data = pResultData.data.sy000500_O_00VO1
        this.lv_accn_list = pResultData.data.sy000500_O_00VO2
        console.log(pResultData.data)
        console.log(t_data)
        

        // 초기화
        this.lv_custSearchList = []
        this.lv_selectIdx = null

        if( FSCommUtil.gfn_length(t_data) > 0 ) {

          // 조회 list 매핑
          this.lv_custSearchList = t_data
          this.lv_custSearchList.forEach((item, idx) => {
            let val = this.lv_custSearchList[idx].rlnm_cnf_no_encr

            // 실명번호
            this.lv_custSearchList[idx].rlnm_cnf_no_encr = FSCommUtil.gfn_substr(val, 0, 6) + '-' + FSCommUtil.gfn_substr(val, 6, 7)

            // 휴대폰번호
            this.lv_custSearchList[idx].phone_no = item.bman_celph_dstno_encr + '-' + item.bman_celph_tlnum_no_encr + '-' + item.bman_celph_bkno_sno_encr

          })

        } else {
          t_popupObj = {content: FSMessageUtil.commMessage([])[pResultData.data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)

        }
      },


      /******************************************************************************
       * Function명 : fn_Validate
       * 설명       : 필수값 체크
       ******************************************************************************/
      fn_Validate() {
        console.log('fn_Validate.....')

        // 초기화
        this.lv_cust_no.error = false
        this.lv_cust_nm.error = false
        this.lv_rlnm_no_stnd_birth_mmdd.error = false
        this.lv_bfcer_inqr_rsn_cd.error = false
        this.lv_inqr_dtl_rsn.error = false


        if( FSCommUtil.gfn_isNull(this.lv_cust_no.value) && (FSCommUtil.gfn_isNull(this.lv_cust_nm.value) || FSCommUtil.gfn_length(this.lv_cust_nm.value) < 2) ) {
          FSCommUtil.gfn_validate(this, '고객명을 2글자 이상 입력하거나 고객번호를 입력하세요.')
          this.lv_cust_no.error = true
          this.lv_cust_nm.error = true
          this.$refs['cust_nm'].focus()
          return false
        }

        if( !FSCommUtil.gfn_isNull(this.lv_cust_nm.value) && FSCommUtil.gfn_isNull(this.lv_rlnm_no_stnd_birth_mmdd.value) ) {
          FSCommUtil.gfn_validate(this, '개인고객은 생년월일을 입력하세요.')
          this.lv_rlnm_no_stnd_birth_mmdd.error = true
          return false
        }

        if( FSCommUtil.gfn_isNull(this.lv_bfcer_inqr_rsn_cd.value) ) {
          FSCommUtil.gfn_validate(this, '고객조회사유를 선택하세요.')
          this.lv_bfcer_inqr_rsn_cd.error = true
          return false
        }

        if( this.lv_bfcer_inqr_rsn_cd.value == '30' && FSCommUtil.gfn_isNull(this.lv_inqr_dtl_rsn.value) ) {
          FSCommUtil.gfn_validate(this, "고객조회사유가 '기타'인 경우 상세 사유 내용을 입력하세요.")
          this.lv_inqr_dtl_rsn.error = true
          this.$refs['inqr_dtl_rsn'].focus()
          return false
        }

        return true
      },


      /******************************************************************************
       * Function명 : fn_CustHistory, fn_CustHistoryResult
       * 설명       : 고객정보이력등록
       ******************************************************************************/
      fn_CustHistory() {
        console.log('fn_CustHistory.....')

        let t_popupObj = {}

        // list 선택 체크
        if( FSCommUtil.gfn_isNull(this.lv_selectIdx) ) {
          t_popupObj.confirm = false
          t_popupObj.content = '고객을 선택하여 주십시오.'
          this.fn_AlertPopup(0, t_popupObj)
          return
        }

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'I'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570137_S'
        this.eaiCommObj.params = {
          bfcer_cust_no           : this.lv_custSearchList[this.lv_selectIdx - 1].bfcer_cust_no,  // 고객번호
          bfcer_inqr_rsn_cd       : this.lv_bfcer_inqr_rsn_cd.value,                          // 조회사유코드
          inqr_dtl_rsn            : this.lv_inqr_dtl_rsn.value,                               // 상세사유
          cust_inqr_link_srn_addr : 'SY000500P'                                               // 프로그램ID
        }
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustHistoryResult)
      },

      fn_CustHistoryResult() {
        console.log('fn_CustHistoryResult.....')

        // 메인 화면으로 고객번호 값 넘긴 후 팝업 종료
        this.$emit('fs-popup-callback', this.lv_popupId, this.lv_custSearchList[this.lv_selectIdx - 1].bfcer_cust_no)
        this.modal.close()
      },


      /******************************************************************************
       * Function명 : fn_OpenAccnPopup
       * 설명       : 계좌정보LIST 팝업
       ******************************************************************************/
      fn_OpenAccnPopup() {
        console.log('fn_OpenAccnPopup.....')

        this.pPopup326Obj.accn_list = this.lv_accn_list
        this.$refs.popup326.fn_Open()
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        console.log('fn_Popup_CallBack.....')

        // 메인 화면으로 그룹번호(계좌번호) 값 넘긴 후 팝업 종료
        this.$emit('fs-popup-callback', this.lv_popupId, pData)
        this.modal.close()
      },


      /******************************************************************************
       * Function명 : fn_clear
       * 설명       : validation  초기화
       ******************************************************************************/
      fn_clear(param) {
        switch(param) {
          case 'appointCust' :
            this.lv_cust_no.error = false // 고객번호 validation 초기화
            break
          case 'password' :
            this.lv_password.error = false // 비밀번호  validation 초기화
            break
          case 'custNm' :
            this.lv_cust_nm.error = false // 고객명 validation 초기화
            break
          case 'birth' :
            this.lv_rlnm_no_stnd_birth_mmdd.error = false // 생년월일 validation 초기화
            break
          case 'inqr_rsn_cd' :
            this.lv_bfcer_inqr_rsn_cd.error = false //  고객조회사유 validation 초기화
            break
          case 'inqr_dtl_rsn' :
            this.lv_inqr_dtl_rsn.error = false // 상세사유 validation 초기화
            break
        }
      },

      /******************************************************************************
      * Function명 : fn_SetMaskedTxt
      * 설명       : 보안키패드 체크
      ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_val = val
      },

    }
  }
</script>
<style scoped>
</style>