/*
 * 업무구분: 영업
 * 화 면 명: MSPFS323P
 * 화면설명: FO/FP직원_P
 * AS-IS: SY990400P
 * 작 성 일: 2023.06.01
 * 작 성 자: 이태흥
 */
<template>

  <mo-modal class="fts-modal large" ref="modal" :title="lv_title">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label> 사원번호 </label>
              <mo-decimal-field 
                numeric
                ref="staf_eno"
                v-model="lv_staf_eno.value"
                placeholder="입력하세요" 
                class="w150" 
                mask="##########" 
                :class="lv_staf_eno.error ? 'error' : ''"
              />
            </div>
            <div class="wrap-input row">
              <label> 성명 </label>
              <mo-text-field 
                ref="bfcer_eply_nm"              
                v-model="lv_bfcer_eply_nm.value"
                placeholder="입력하세요" 
                class="w150" 
                :class="lv_bfcer_eply_nm.error ? 'error' : ''"
              />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button primary class="btn-inquiry" @click="fn_Search()"> 조회 </mo-button>
            </div>
          </div>
        </div>
        <div class="wrap-table mt16 h-scroll">
          <table class="table row-type">
            <!-- <colgroup>
              <col width="40px">
              <col width="49%">
              <col width="49%">
            </colgroup> -->
            <thead>
              <tr>
                <th class="w40"> </th>
                <th class="w49p"> 사원번호 </th>
                <th class="w49p"> 성명 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in lv_search_list" :key="idx" :class="{'checked': lv_select_radio == String(idx+1)}" @click="lv_select_radio = String(idx+1)">
                <td>
                  <mo-radio v-model="lv_select_radio" :value="`${String(idx+1)}`"></mo-radio>
                </td>
                <td>
                  {{row.staf_eno}}
                </td>
                <td>
                  {{row.bfcer_eply_nm}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
      </div>
    </template>
  </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS323P",
    screenId: "MSPFS323P",
    props: {
        popupObj: {type:Object, default:null},
    },    
    components: {

    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {

      
      this.$bizUtil.insSrnLog('MSPFS323P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {

        lv_title: '',

        // 검색조건
        lv_staf_eno: {},        // 사원번호
        lv_bfcer_eply_nm: {},   // 사원명


        // 조회결과
        lv_search_list: [],
        lv_select_radio: null,


        /***********************************************************************************
         * F10570198 : FO찾기(임직원목록조회)
         ***********************************************************************************/
        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      modal() {
        return this.$refs.modal
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_Open() {
        setTimeout(() => {
          this.fn_Init()
          
          if( this.popupObj.pDsdomname == '168' ) {
            this.lv_title = 'FO직원'
          } else {
            this.lv_title = 'FP직원'
          }

          if( FSCommUtil.gfn_isNull(this.popupObj.pFund_type) ) {
            FSCommUtil.gfn_validate(this, '펀드를 먼저 선택해 주시기 바랍니다.')
          } else {
            this.modal.open()
          }
        }, 10)
      },

      fn_Close() {
        this.modal.close()
      },

      fn_Confirm() {
        if( FSCommUtil.gfn_isNull(this.lv_select_radio) ) {
          FSCommUtil.gfn_validate(this, '사원을 선택해주세요.')
          return
        }

        // 메인 화면으로 고객번호 값 넘긴 후 팝업 종료
        this.$emit('fs-popup-callback', 'MSPFS323P', this.lv_search_list[this.lv_select_radio - 1], this.popupObj.subId)
        this.modal.close()
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS323P] fn_Init...')


        // 검색조건
        this.lv_staf_eno          = FSCommUtil.gfn_dataSet(0, '')                             // 사원번호
        this.lv_bfcer_eply_nm     = FSCommUtil.gfn_dataSet(0, '')                             // 사원명


        // 조회결과
        this.lv_search_list       = []
        this.lv_select_radio      = null

      },


      /******************************************************************************
       * Function명 : fn_Search, fn_SearchResult
       * 설명       : FO찾기(임직원목록조회)
       ******************************************************************************/
      fn_Search() {
        console.log('fn_Search...')

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570198_S'
        this.eaiCommObj.params = {
          staf_eno          : this.lv_staf_eno.value,         // 사원번호
          bfcer_eply_nm     : this.lv_bfcer_eply_nm.value,    // 사원명
          bfcer_fnd_cd      : this.popupObj.pBfcer_fnd_cd,    // 펀드코드
          bfcer_fnd_tp_cd   : this.popupObj.pFund_type,       // 펀드타입
          domname           : this.popupObj.pDsdomname,          
        }
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchResult)
      },

      fn_SearchResult(pResultData) {
        console.log('fn_SearchResult...')

        let t_data = pResultData.data
        console.log(t_data)

        if( t_data.error_msg == '809900' ) {
          this.lv_search_list = t_data.sy990600_O_00VO


        } else if( t_data.error_msg == '809990' ) {
          let empstr = this.popupObj.pDsdomname == '168' ? 'FO' : 'FP'
          FSCommUtil.gfn_validate(this, '해당 펀드의 권유자격을 가진 ' + empstr + '직원이 존재하지 않습니다.')
        }
      }

      

    }    
  }
</script>
<style scoped>
</style>