/**
 * <PRE>
 * System Name : 사랑On NEXT 시스템(TSS2-MSP)
 * Business Name : 수익증권
 * Class Name : FSFormUtil.js
 * Description : 수익증권 공통 유틸 (AS-IS: ComForm.js)
 * </PRE>
 */

  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSDateUtil from '~/src/ui/fs/comm/FSDateUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'

  
  const FSFormUtil = {}



  /************************************************************************************************
   * Function명    : gfn_chkInvest_servey
   * 설명          : 고객투자성향(설문등록정보) 체크
   * Parameter     : cust_grade(고객투자성향 등급), vail_std_dt(유효시작일 (설문등록일))
                    , rname_no_gb(실명번호구분), rname_no(실명번호), cust_nm(고객명)
                    , cust_no(고객번호)
   * alertFunc     : ALERT 함수                    
   * Return        : boolean
   ************************************************************************************************/
  FSFormUtil.gfn_chkInvest_servey = async function(pContext, cust_grade, avail_std_dt, rname_no_gb, rname_no, cust_nm, cust_no, alertFunc) {
    console.log('gfn_chkInvest_servey...')

    const context = !pContext ? this : pContext

    let salesYmd  = ""
    let nDay      = "-4"   // 5영업일 이내(당일포함)

    if( FSCommUtil.gfn_isNull(cust_grade) ) {
      let t_messageData = {
        cancel: false,
        confirm: true,
        confirmFunc: function () {
          context.$router.push({
            name: 'MSPFS110M',
            params: {
              rmvl_srn_id: 'MSPFS300M',
              bfcer_cust_no: cust_no,
            }
          })
        },
        content: '투자성향정보가 존재하지 않습니다.\n설문등록화면(7005)으로 이동합니다.'
      }
      FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)

      return false

    } else {
    
      salesYmd = await FSDateUtil.gfn_getSalesYmd(context, nDay)


      /* 5영업일내에 등록된 투자성향정보가 존재하지 않을때 
      * gfn_getSalesYmd() : 현재일자 기준 -5영업일자(당일포함)
      * avail_std_dt : 설문등록일자(TFOF02019.EXPD_STND_YMD)
      */
      if( salesYmd <= avail_std_dt ) {
        return true

      } else {

        let t_messageData = {
          cancel: false,          
          confirm: true,
          confirmFunc: function () {
            if( context.getStore('fsStore').getters.getState.isProcess == false ) {
              context.$router.push({
                name: 'MSPFS110M',
                params: {
                  rmvl_srn_id: 'MSPFS300M',
                  bfcer_cust_no: cust_no,
                }
              })
            }
          },
          content: '투자성향 진단일자가 5영업일이 경과했습니다.\n설문등록화면(7005)에서 투자성향정보를 갱신해 주세요.'
        }
        FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)

        return false
      }
    }

  }

  export default FSFormUtil
  // eslint-disable-next-line
