/*
 * 업무구분: 영업
 * 화 면 명: MSPFS309P
 * 화면설명: 세제상품전금융기관등록조회_P
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <!-- popup -->
    <mo-modal class="fts-modal large" ref="modal9" title="세제상품전금융기관등록조회">
      <template>
        <div class="wrap-modalcontents">
          <div class="input-top">
            <div class="column w100p gap12">
              <div class="row w100p">
                <div class="left mr0 gap40">
                  <div class="wrap-input row">
                    <label class="w74"> 주민등록번호 </label>
                    <!-- @input="fn_NumKeyUp"
                       <mo-text-field v-model="tmp_rcne" ref="rlnm_cnf_no_encr" @keyup="fn_searchCnfNo" class="w150" clearable /> -->
                    <mo-text-field
                      ref="ed_agnt_rname_no1"
                      class="input-long"
                      :class="lv_ed_agnt_rname_no.error1 ? 'error' : ''"
                      v-model="lv_ed_agnt_rname_no.value1"
                      clearable
                      placeholder="앞 6자리"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                      maxlength="6"
                      type="number"
                      @input="fn_searchCnfNo"
                      @keyup="fn_NextFocus"
                    />
                    
                    <m-trans-key-input
                      v-if="isMobile && isMtrans"
                      ref="ed_agnt_rname_no2"
                      class="input-long"
                      :class="lv_ed_agnt_rname_no.error2 ? 'error' : ''"
                      v-model="lv_ed_agnt_rname_no.value2"
                      type="numberMax7"
                      dialog="Y"
                      start="1" 
                      end="-1"                
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                    />

                    <mo-text-field
                      v-else
                      ref="ed_agnt_rname_no2"
                      type="password"
                      class="input-long"
                      :class="lv_ed_agnt_rname_no.error2 ? 'error' : ''"
                      v-model="lv_ed_agnt_rname_no.value2"
                      clearable
                      placeholder="뒤 7자리"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                      @input="fn_searchCnfNo"
                      maxlength="7"
                    />

                    <mo-text-field v-model="ds_search.fnd_cust_nm" class="w150" readonly />
                  </div>
                </div>
                <div class="right w20p"></div>
              </div>
              <div class="row w100p">
                <div class="left mr0 gap40">
                  <div class="wrap-input row">
                    <label class="w74"> 계좌번호 </label>
                    <mo-decimal-field  
                    numeric
                    class="w130 input-account" 
                      v-model="lv_ed_accn_no.value"
                      ref="ed_accn_no"
                      clearable
                      @keyup="fn_SearchValid($event)"
                      @paste="fn_SearchValid($event, 'P')"
                      mask="#######-###"
                    />
                    <mo-dropdown
                      ref="ed_acct_gb"
                      :items="lv_acct_list"
                      v-model="lv_ed_acct_gb.value"
                      class="w243"
                      placeholder=" "
                      :disabled="lv_ed_acct_gb.disabled"
                    />
                    <mo-text-field class="w80"
                    v-model="lv_ed_cust_nm.value"
                    readonly />
                  </div>
                </div>
              </div>

              <div class="row w100p">
                <div class="right w100p">
                  <div class="wrap-button row">
                    <mo-button class="btn-clear" @click="fn_init()"> 초기화 </mo-button>
                    <mo-button primary class="btn-inquiry" @click="fn_searchList" > 조회 </mo-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- fs-cust-info start -->
          <fs-cust-info
            ref="fsCustInfo"
            :propObj="pCustInfoObj"
            @fs-cust-name="fn_SetEdCustName"
          ></fs-cust-info>
          <!-- fs-cust-info end -->

          <div class="wrap-table mt16">
            <table class="table th-white th-border-3n">
              <tbody>
                <tr>
                  <th class="wsN"> 정보주체구분 </th>
                  <td> <mo-text-field v-model="infoScCd" readonly /> </td>
                  <th class="wsN"> 세금우대 총 한도금액 </th>
                  <td> <mo-text-field v-model="inqrReqSavEntTotAmt" mask="number" readonly /> </td>
                </tr>
                <tr>
                  <th class="wsN"> 금융소득종합과세대상 </th>
                  <td> <mo-text-field v-model="finIncomeTax" readonly /> </td>
                  <th class="wsN"> 세금우대 한도잔여액 </th>
                  <td> <mo-text-field v-model="inqrReqSavLmtRmdrAmt" mask="number" readonly /> </td>
                </tr>
                <tr>
                  <th class="wsN"> 세금우대 중복여부 </th>
                  <td> <mo-text-field v-model="dupScCd" readonly /> </td>
                  <th class="wsN"> 세금우대 총 등록건수 </th>
                  <td> <mo-text-field v-model="totRegCnt" mask="number" readonly /> </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="right-bg-white">
            <div class="wrap-table mt16 x-scroll">
              <table class="table th-row-gray text-center wsN">
                <thead>
                  <tr>
                    <th> 해지구분 </th>
                    <th> 저축종류 </th>
                    <th> 등록점 </th>
                    <th> 계좌번호 </th>
                    <th> 상속여부 </th>
                    <th> 우대해지일 </th>
                    <th> 만기일 </th>
                    <th> 가입금액 </th>
                    <th> 이자배당금액 </th>
                    <th> 저축신규일 </th>
                    <th> 최종변경일 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in ds_detail" :key="i">
                    <td> {{item.trmt_gb_nm}} </td>
                    <td> {{item.tax_prime_tp_nm}} </td>
                    <td> {{item.reg_branch}} </td>
                    <td> {{item.acct_no}} </td>
                    <td> {{item.inherit_yn}} </td>
                    <td> {{$bizUtil.dateDashFormat(item.trmt_dt)}} </td>
                    <td> {{$bizUtil.dateDashFormat(item.exp_dt)}} </td>
                    <td> {{$bizUtil.numberWithCommas(item.tax_prime_amt)}} </td>
                    <td> {{$bizUtil.numberWithCommas(item.inc_amt)}} </td>
                    <td> {{$bizUtil.dateDashFormat(item.open_dt)}} </td>
                    <td> {{$bizUtil.dateDashFormat(item.last_updt_dt)}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_closeModal">닫기</mo-button>
        </div>
      </template>
      <!-- alert modal popup -->
      <fs-alert-popup-inpop
        ref="alertPopupInPop"
        :popupObj="pAlertPopupObjInPop"
      ></fs-alert-popup-inpop>
    </mo-modal>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
   ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo' // 고객정보 (공통)

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS309P',
  screenId: 'MSPFS309P',
  props: {
    popupObj: {type: Object, default: null}
  },
  components: {
    'fs-alert-popup-inpop': FSAlertPopup,
    'fs-cust-info': FSCustInfo
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    this.fn_SetCommCode()
  },
  mounted () {
    this.fn_init()

    this.$bizUtil.insSrnLog(this.$options.screenId)
    // console.log('309P mounted =====', this.$route.params);
    // if(!FSCommUtil.gfn_isNull(this.popupObj)){
    //   console.log('309P mounted ===', this.popupObj)
    //   this.lv_ed_agnt_rname_no.value1 = this.popupObj.rlnm_cnf_no.substr(0, 6)
    //   this.lv_ed_agnt_rname_no.value2 = this.popupObj.rlnm_cnf_no.substr(6, 7)
    // }
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      isMobile  : window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans  : this.getStore('fsStore').getters.getState.isMtrans, // 보안키패드 적용 여부
      lv_isClear: false,            // 보안키패드 초기화
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      // 고객정보
      pCustInfoObj: {},
      pAlertPopupObjInPop:{},
      
      // 주민등록번호
      ds_search: {
        rlnm_cnf_no_encr: '',
        passbook_no: '',
        acct_seq_no: '',
        cust_no: '',
        fnd_cust_nm: ''
      },
      lv_ed_agnt_rname_no: {
        value1: '',
        value2: '',
        error1: false,
        error2: false,
        disabled: false
      },
      // 계좌정보
      lv_cust_info: {}, // 고객정보
      lv_acct_list: [], // 계좌조회목록

      // 검색조건
      lv_ed_accn_no: {
        value: '',
        error: false
      }, // 계좌번호
      lv_ed_acct_gb: {}, // 계좌종류
      lv_ed_cust_nm: {}, // 고객명

      infoScCd: '', // 정보주체구분
      inqrReqSavEntTotAmt: '', // 세금우대 총 한도금액
      finIncomeTax: '', // 금융소득종합과세대상
      inqrReqSavLmtRmdrAmt: '', // 세금우대 한도잔여액
      dupScCd: '', // 세금우대중복여부
      totRegCnt: '', // 세금우대 총 등록건수

      ds_detail: [],

      tmp_rcne: '', // 임시로 주민번호 저장
      lv_masked_val: '',          // 마스크 변수 체크

      /// //////////////////////////////////////////////////////

      // Accordion Toggle
      isActive: false,

      // as-is 전역변수
      // var bfcer_cust_no = "";
      // var svcId = "";
      // var brcd = this.gfn_userinfo("brcd");
      bfcer_cust_no: '',
      brcd: '', // ComBiz.gfn_userinfo("brcd"),
      lv_commCode: []
    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    modal9 () { return this.$refs.modal9 },
    account () {
      let rtn = []
      rtn.push({value: '1', text: '009 예수금(브릿지)'})
      rtn.push({value: '2', text: '009 예수금(브릿지)'})
      rtn.push({value: '3', text: '009 예수금(브릿지)'})
      rtn.push({value: '4', text: '009 예수금(브릿지)'})
      return rtn
    }
  },
  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val                
      if(FSCommUtil.gfn_length(this.lv_masked_val) >= 7) {          
        this.fn_searchCnfNo() 
      }
    },
    
    /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
    ******************************************************************************/
    fn_init () {
      console.log('fn_init....')

      // 검색조건
      this.lv_ed_accn_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no') // 계좌번호
      this.lv_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb') // 계좌종류
      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1) // 고객명

      this.ds_search = {
        rlnm_cnf_no_encr: '',
        passbook_no: '',
        acct_seq_no: '',
        cust_no: '',
        fnd_cust_nm: ''
      }

      this.lv_ed_agnt_rname_no = {
        value1: '',
        value2: '',
        error1: false,
        error2: false,
        disabled: false
      }
      // 계좌정보
      this.lv_cust_info = {} // 고객정보
      this.lv_acct_list = [] // 계좌조회목록

      // 검색조건
      this.lv_ed_accn_no = {
        value: '',
        error: false
      } // 계좌번호
      this.lv_ed_acct_gb = {} // 계좌종류
      this.lv_ed_cust_nm = {} // 고객명

      this.infoScCd = '' // 정보주체구분
      this.inqrReqSavEntTotAmt = '' // 세금우대 총 한도금액
      this.finIncomeTax = '' // 금융소득종합과세대상
      this.inqrReqSavLmtRmdrAmt = '' // 세금우대 한도잔여액
      this.dupScCd = '' // 세금우대중복여부
      this.totRegCnt = '' // 세금우대 총 등록건수

      this.ds_detail = []

      this.lv_isClear = !this.lv_isClear ? true : false
      this.lv_masked_val = '';

      // if(!FSCommUtil.gfn_isNull(this.popupObj)){
      //   console.log('309P mounted ===', this.popupObj)
      //   this.lv_ed_agnt_rname_no.value1 = this.popupObj.rlnm_cnf_no.substr(0, 6)
      //   this.lv_ed_agnt_rname_no.value2 = this.popupObj.rlnm_cnf_no.substr(6, 7)
      // }

      // 고객정보 초기화
      this.$refs.fsCustInfo.fn_Init()
    },

    /******************************************************************************
       * Function명 : fn_searchCnfNo
       * 설명       : 고객정보조회
    ******************************************************************************/
    fn_searchCnfNo () {
      console.log('fn_searchCnfNo@@@@')
      this.ds_search.fnd_cust_nm = ''
      this.ds_search.rlnm_cnf_no_encr = this.lv_ed_agnt_rname_no.value1 + this.lv_ed_agnt_rname_no.value2
      
      
      if (FSCommUtil.gfn_length(this.ds_search.rlnm_cnf_no_encr) < 13) {
        return
      }
      let t_data = {rlnm_cnf_no_encr: this.ds_search.rlnm_cnf_no_encr}
      FSInfoUtil.commInfo(this, 0, t_data, this.fn_searchCnfNoResult)
    },

    fn_searchCnfNoResult (pResultData) {
      console.log('고객정보조회 데이터 ', pResultData)
      if ( pResultData.data.error_msg === '809900' ) {
        this.ds_search.fnd_cust_nm = pResultData.data.sy001000_O_00VO[0].fnd_cust_nm
        this.$refs.fsCustInfo.fn_CustInfoSearchResult(pResultData)
      } else {
        let t_popupObj = {
          content: FSMessageUtil.commMessage([])[pResultData.data.error_msg]
        }
        this.fn_AlertPopup(0, t_popupObj)
      }
    },
    /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
    ******************************************************************************/
    fn_CustInfo () {
      console.log('fn_CustInfo.....pCustInfoObj', this.pCustInfoObj)
      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
      this.$refs.fsCustInfo.fn_CustInfoSearch()
    },

    fn_SetEdCustName (val) {
      if (this.lv_ed_accn_no.value !== '' || this.lv_ed_accn_no.value !== ' ' || this.lv_ed_accn_no.value !== undefined) {
        this.lv_ed_cust_nm.value = val
      }
      if (FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value)) {
        this.lv_ed_cust_nm.value = ''
      }
    },

    /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
    ******************************************************************************/
    fn_SearchValid (event, flag) {
      console.log('fn_SearchValid.....')
      let t_accn_no = this.lv_ed_accn_no.value
      // if( flag == 'P' ) {
      //   t_accn_no = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,10)
      // } else {
      //   if( !FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value) ) {
      //     t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0,10)
      //   }
      // }

      if ( FSCommUtil.gfn_length(t_accn_no) >= 10 ) {
        this.lv_ed_accn_no.error = false

        if ( !FSCommUtil.gfn_isNum(t_accn_no) ) {
          FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
        } else {
          if ( FSCommUtil.gfn_isNum(event.key) || event.key === 'v' || flag === 'P' || this.isMobile ) {
            // 계좌목록조회
            this.fn_AccnNoList(t_accn_no)
          }
        }
      } else if ( t_accn_no.length > 7 || event.key === 'Delete' || event.key === 'Backspace' ) {
        // 계좌목록/고객명 초기화
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true
        this.lv_ed_cust_nm.value = ''

        // 고객정보 펼치기 정보 초기화
        this.$refs.fsCustInfo.fn_Init()
      }
    },

    /******************************************************************************
       * Function명 : fn_AccnNoList, fn_AccnNoListResult
       * 설명       : 계좌목록조회
    ******************************************************************************/
    fn_AccnNoList (pAccnNo) {
      console.log('fn_AccnNoList.....')

      let t_data = {bfcer_cstgr_no: pAccnNo}
      FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
    },

    fn_AccnNoListResult (pResultData) {
      console.log('fn_AccnNoListResult.....')

      let t_data = pResultData.data.sy000000_O_00VO
      console.log(t_data)

      // 계좌목록조회 초기화
      this.lv_acct_list = []
      this.lv_ed_acct_gb.disabled = true

      if ( t_data.length > 0 ) {
        t_data.forEach((item, idx) => {
          this.lv_acct_list.push({value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no})
        })

        // 계좌목록조회 결과 첫번째 선택 및 활성화
        this.lv_ed_acct_gb.value = 0
        this.lv_ed_acct_gb.disabled = false

        // 고객정보조회
        this.fn_CustInfo()
      } else {
        let t_popupObj = {content: '검색할 계좌가 없습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
       * Function명 : fn_validateForm
       * 설명       : validation 체크
    ******************************************************************************/
    fn_validateForm () {
      var t_accn_no = ''
      if (!FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value)) {
        t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0, 10)
      }
      if (FSCommUtil.gfn_length(this.ds_search.rlnm_cnf_no_encr) <= 0) {
        if ( FSCommUtil.gfn_length(t_accn_no) !== 10 ) {
          FSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다')
          this.$refs['ed_accn_no'].focus()
          return false
        }
      }

      if (FSCommUtil.gfn_length(t_accn_no) !== 10 ) {
        if (FSCommUtil.gfn_length(this.ds_search.rlnm_cnf_no_encr) <= 0 ) {
          FSCommUtil.gfn_validate(this, '주민등록번호 입력오류입니다')// rlnm_cnf_no_encr
          this.$refs['rlnm_cnf_no_encr'].focus()
          return false
        }
      }

      return true
    },

    /******************************************************************************
       * Function명 : fn_searchList
       * 설명       : 조회
    ******************************************************************************/
    fn_searchList () {
      if (!this.fn_validateForm()) {
        return
      };

      this.ds_detail = []

      var t_accn_no = ''
      if (!FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value)) {
        t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0, 10)
      }

      var t_rlnm_no = ''
      if (!FSCommUtil.gfn_isNull(this.lv_ed_agnt_rname_no.value1) && !FSCommUtil.gfn_isNull(this.lv_ed_agnt_rname_no.value2)) {
        t_rlnm_no = this.lv_ed_agnt_rname_no.value1 + this.lv_ed_agnt_rname_no.value2
      }
    
      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570249_S'
      this.eaiCommObj.params = {
        acct_seq_no: t_accn_no,
        cust_no: t_accn_no.substr(0, 7),
        passbook_no: t_accn_no,
        rlnm_cnf_no_encr: t_rlnm_no
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },
    /******************************************************************************
       * Function명 : fn_callBack
       * 설명       : 조회 콜백
    ******************************************************************************/
    fn_callBack (response) {
      console.log('fn_callback RESPONSE', response)

      var data = response.data
      this.infoScCd = FSCommUtil.gfn_trim(data.infoScCd) === '' ? '해당사항 없음' : data.infoScCd // 정보주체구분
      this.inqrReqSavEntTotAmt = FSCommUtil.gfn_trim(data.inqrReqSavEntTotAmt) === '' ? '0' : data.inqrReqSavEntTotAmt // 세금우대 총 한도금액
      this.finIncomeTax = FSCommUtil.gfn_trim(data.lowIncmrScNo) === '' ? '해당없음' : data.lowIncmrScNo // 금융소득종합과세대상
      this.inqrReqSavLmtRmdrAmt = FSCommUtil.gfn_trim(data.inqrReqSavLmtRmdrAmt) === '' ? '0' : data.inqrReqSavLmtRmdrAmt // 세금우대 한도잔여액
      this.dupScCd = FSCommUtil.gfn_trim(data.dupScCd ) === '' ? '해당없음' : data.dupScCd // 세금우대중복여부
      this.totRegCnt = FSCommUtil.gfn_trim(data.totRegCnt) === '' ? '0' : data.totRegCnt // 세금우대 총 등록건수
      this.ds_detail = data.cu155500_O_00VO
      
      let t_list = []

      let lv_Vm = this

      if (lv_Vm.ds_detail) {
        lv_Vm.ds_detail.forEach((item, idx) => {
          var findIndx = lv_Vm.lv_commCode.code262.findIndex(i => i.value === item.trmt_gb)
          var findIndx2 = lv_Vm.lv_commCode.code263.findIndex(i => i.value === item.tax_prime_tp)

          t_list.push({
            trmt_gb: item.trmt_gb,
            trmt_gb_nm: item.trmt_gb === '00' ? '' : lv_Vm.lv_commCode.code262[findIndx].text,
            tax_prime_tp: item.tax_prime_tp,
            tax_prime_tp_nm: lv_Vm.lv_commCode.code263[findIndx2].text,
            reg_branch: item.reg_branch,
            acct_no: item.acct_no,
            inherit_yn: item.inherit_yn,
            trmt_dt: item.trmt_dt,
            exp_dt: item.exp_dt,
            tax_prime_amt: item.tax_prime_amt,
            inc_amt: item.inc_amt, // lv_Vm.lv_commCode.code262[findIndx].text,
            open_dt: item.open_dt,
            last_updt_dt: item.last_updt_dt

          })
        })
      }
      this.ds_detail = t_list
    },
    /******************************************************************************
       * Function명 : fn_IsActive
       * 설명       : 고객정보 펼치기/접기
    ******************************************************************************/
    fn_IsActive () {
      this.isActive = !this.isActive
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
    ******************************************************************************/
    fn_SetCommCode () {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId': '262', 'dayChkYn': 'N'}, // ds_tax_prime_gb
        {'cId': '263', 'dayChkYn': 'N'} // ds_tax_prime_save_gb
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult (pResult) {
      console.log('pResult @@@@@@@@@@@@@@@@@@@@@@@', pResult)
      this.lv_commCode = pResult
    },

    /***************************************************************************************
       * Function명 : fn_NextFocus
       * 설명       : next focus
    *****************************************************************************************/
    fn_NextFocus (event) {
      // 실명번호
      if ( FSCommUtil.gfn_length(this.lv_ed_agnt_rname_no.value1) === 6 ) {
        if ( FSCommUtil.gfn_isNum(event.key) || event.key === 'v' || this.isMobile ) {
          this.$refs['ed_agnt_rname_no2'].focus()
        }
      }
    },

    /******************************************************************************
       * Function명 : fn_openModal
       * 설명       : 팝업
    ******************************************************************************/
    fn_openModal () {
      console.log('309P open modal ', JSON.stringify(this.popupObj))
      this.$nextTick(() => {        
        if(!FSCommUtil.gfn_isNull(this.popupObj)){
          if (!FSCommUtil.gfn_isNull(this.popupObj.rlnm_cnf_no)) {
            console.log('309P openmodal =======', this.popupObj)
            this.lv_ed_agnt_rname_no.value1 = this.popupObj.rlnm_cnf_no.substr(0, 6)
            this.lv_ed_agnt_rname_no.value2 = this.popupObj.rlnm_cnf_no.substr(6, 7)
            this.fn_SetMaskedTxt(this.lv_ed_agnt_rname_no.value2)
          }
        }
      })
      this.modal9.open()
    },
    fn_closeModal () {
      var pData = {
        data9: 'data9'
      }
      this.$emit('callback309', 9, pData)
      this.modal9.close()
      this.closeDropdown()
      this.fn_init()
    },
    closeDropdown () {
      this.$refs.ed_acct_gb.close()
    },
    // Accordion Toggle
    toggle01: function () { this.isActive = !this.isActive }
  }
}
</script>
<style scoped>
</style>
