/*
 * 업무구분: 수익증권>조력자정보조회POPUP
 * 화 면 명: MSPFS316P
 * 화면설명: 조력자정보조회 POPUP
 * 작 성 일: 2023.12.08
 * 작 성 자: 강현철
 */
<template>
    <!-- popup -->
    <mo-modal class="fts-modal large noShadow" ref="modal1" title="조력자 정보 조회">
      <template>
        <div class="wrap-modalcontents" ref="modalScroll">
          <ur-box-container>
            <div class="wrap-table-title mt0">
              <h2 class="table-title"> 조력자 지정 </h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type mb0">
                <tbody>
                  <tr>
                    <th class="w140">
                      <span class="emphasis"> 조력자 지정 여부 </span>
                    </th>
                    <td colspan="3">
                      <mo-radio-wrapper :items="radio1" v-model="coprt_peson_asgn_yn" class="row" disabled/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ur-box-container>
          <ur-box-container>
            <div class="wrap-table-title">
              <h2 class="table-title"> 조력자 정보 </h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type mb10">
                <tbody>
                  <tr>
                    <th class="w140">
                      <span class="emphasis"> 조력자명 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field class="w100p" v-model="coprt_peson_nm.value" disabled />
                      </div>
                    </td>
                    <th class="w140">
                      <span class="emphasis"> 연락처 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-dropdown :items="lv_commCode.code370" v-model="coprt_peson_dstno_encr.value" class="w80" placeholder=" " ref="dropdown1" disabled />
                        <mo-text-field class="w80" v-model="coprt_peson_tlnum_no_encr.value" maxlength="4" type="number" disabled />
                        <mo-text-field class="w80" v-model="coprt_peson_bkno_sno_encr.value" maxlength="4" type="number" disabled />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th class="w140">
                      <span class="emphasis"> 본인과의 관계 </span>
                    </th>
                    <td colspan="3">
                      <div class="wrap-input row">
                        <mo-dropdown :items="lv_commCode.code420" v-model="coprt_peson_rltn_cd.value" class="w188" placeholder=" " ref="dropdown2" disabled />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ur-box-container>
          <ur-box-container>
            <div class="wrap-input mt16 mb10">
              <mo-checkbox v-model="coprt_peson_info_cnsnt_yn" disabled> 조력자 개인정보 수집에 대한 확인 여부 </mo-checkbox>
            </div>
            <div class="wrap-table">
              <table class="table col-type col2030 mb10">
                <tbody>
                  <tr>
                    <th class="w140 bg-white" colspan="4">
                      <span class="emphasis"> 고객님의 금융투자상품 투자와 관련하여 투자자보호를 위해 조력자에 대한 성명 및 연락처 정보 수집에 대한 동의 </span>
                    </th>
                  </tr>
                  <tr>
                    <th class="w140">
                      <span class="emphasis"> 녹취일자 </span>
                    </th>
                    <td>
                      <mo-date-picker v-model="coprt_peson_info_recd_ymd.value" :bottom="false" class="w130" disabled />
                    </td>
                    <th class="w140">
                      <span class="emphasis"> 녹취시간 </span>
                    </th>
                    <td>
                      <mo-text-field class="w100p" v-model="coprt_peson_info_recd_tm.value" placeholder="HH : MM : SS" mask="##:##:##" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ur-box-container>
          <ur-box-container>
            <div class="wrap-input mt16 mb10">
              <mo-checkbox v-model="coprt_stab_proct_gudn_yn" disabled> 조력자 상품가입에 대한 사실 안내 여부 </mo-checkbox>
            </div>
            <div class="wrap-table">
              <table class="table col-type col2030 mb10">
                <tbody>
                  <tr>
                    <th class="w140 bg-white" colspan="4">
                      <span class="emphasis"> 상품가입에 대한 사실 안내 </span>
                    </th>
                  </tr>
                  <tr>
                    <th class="w140">
                      <span class="emphasis"> 녹취일자 </span>
                    </th>
                    <td>
                      <mo-date-picker v-model="coprt_stab_proct_recd_ymd.value" :bottom="false" class="w130" disabled />
                    </td>
                    <th class="w140">
                      <span class="emphasis"> 녹취시간 </span>
                    </th>
                    <td>
                      <mo-text-field class="w100p" v-model="coprt_stab_proct_recd_tm.value" placeholder="HH : MM : SS" mask="##:##:##" disabled/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ur-box-container>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="closeModal(1)">닫기</mo-button>
        </div>
      </template>
    </mo-modal>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'

import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS316P",
  screenId: "MSPFS316P",
  components: {
  },
  props: {
    pCust316Obj : Object
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS316P");
    this.$refs.modalScroll.onscroll = this.closeDropdown
  },
  beforeDestroy() {
    this.$refs.modalScroll.onscroll = null
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      coprt_peson_asgn_yn: '', // 조력자 지정여부 예=Y 아니오=N
      coprt_peson_nm: {}, // 조력자명
      coprt_peson_dstno_encr: {
        value: '',
        disabled: false
      }, // 연락처1
      coprt_peson_tlnum_no_encr: {
        value: '',
        disabled: false
      }, // 연락처2
      coprt_peson_bkno_sno_encr: {
        value: '',
        disabled: false
      }, // 연락처3
      coprt_peson_rltn_cd: {
        value: '',
        disabled: false
      }, // 본인과의 관계
      coprt_peson_info_cnsnt_yn: 'N', // 조력자 개인정보 수집에 대한 확인 여부
      coprt_peson_info_recd_ymd: {
        value: '',
        disabled: true
      }, // 녹취일자
      coprt_peson_info_recd_tm: {
        value: '',
        disabled: true
      }, // 녹취시간
      coprt_stab_proct_gudn_yn: 'N', // 조력자 상품가입에 대한 사실 안내 여부
      coprt_stab_proct_recd_ymd: {
        value: '',
        disabled: true
      }, // 녹취일자
      coprt_stab_proct_recd_tm: {
        value: '',
        disabled: true
      }, // 녹취시간
      lv_commCode: {}
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal1() {return this.$refs.modal1},

    radio1() {
      let rtn= []
      rtn.push({value: 'Y', text: '예'})
      rtn.push({value: 'N', text: '아니오'})
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Open() {
      setTimeout(() => {
        this.fn_SetCommCode()
        this.modal1.open()
      }, 10)
    },
    /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
    ******************************************************************************/
    fn_SetCommCode () {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId': '370', 'dayChkYn': 'N'}, 
        {'cId': '420', 'dayChkYn': 'N'},
        {'cId': '1050', 'dayChkYn': 'N'}
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult (pResult) {
      this.lv_commCode = pResult
      this.fn_searchList()
    },
    fn_searchList () {
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570176_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.pCust316Obj.cust_no,
        bfcer_cust_nm: this.pCust316Obj.cust_nm,
        proc_gb: 'S'
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackSelect)
    },
    /***************************************************************************************
       * Function명 : fn_callBackSelect fn_callBackIUD
       * 설명       : callback(고객정보 있으면 바로 기존 조력자 정보 조회)
    *****************************************************************************************/
    fn_callBackSelect (response) {
      console.log('조력자목록조회response', response)
      if (response.data.si430500_O_00VO !== null && response.data.si430500_O_00VO.length > 0) { // 조회된 목록이 없을 때
        var data = response.data.si430500_O_00VO[0]
        this.coprt_peson_asgn_yn = data.coprt_peson_asgn_yn
        this.coprt_peson_bkno_sno_encr.value = data.coprt_peson_bkno_sno_encr
        this.coprt_peson_dstno_encr.value = data.coprt_peson_dstno_encr
        this.coprt_peson_info_cnsnt_yn = data.coprt_peson_info_cnsnt_yn
        this.coprt_peson_info_recd_tm.value = data.coprt_peson_info_recd_tm
        this.coprt_peson_info_recd_ymd.value = moment(data.coprt_peson_info_recd_ymd).format('YYYY-MM-DD')// 협조자상품녹취일자
        this.coprt_peson_nm.value = data.coprt_peson_nm
        this.coprt_peson_rltn_cd.value = data.coprt_peson_rltn_cd
        this.coprt_peson_tlnum_no_encr.value = data.coprt_peson_tlnum_no_encr
        this.coprt_stab_proct_gudn_yn = data.coprt_stab_proct_gudn_yn
        this.coprt_stab_proct_recd_tm.value = data.coprt_stab_proct_recd_tm
        this.coprt_stab_proct_recd_ymd.value = moment(data.coprt_stab_proct_recd_ymd).format('YYYY-MM-DD')

      }
    },
    closeModal(type) {
      this.$emit('fs-popup-callback')
      this["modal"+type].close()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
      this.$refs.dropdown2.close()
    }
  }
}
</script>
<style scoped>
</style>