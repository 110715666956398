/**
 * <PRE>
 * System Name : 사랑On NEXT 시스템(TSS2-MSP)
 * Business Name : 수익증권
 * Class Name : FSAmlUtil.js
 * Description : 수익증권 공통 유틸 (AS-IS: ComAml.js)
 * 수정일 : 2024-09-24 강현철  사유 : ASR240701139 / AML 및 고객확인 체크보완
 * </PRE>
 */

import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'

const FSAmlUtil = {}

let lv_edd_pep_appr_yn = '' // EDD/PEP  결재 여부

/************************************************************************************************
 * Function명    : gfn_aml_real_owner_check
 * 설명          : 실제소유자여부 당일진행여부 확인 (당일진행 이력이 있는 경우 aml 체크는 pass)
 * pData         : 계좌번호/고객번호
 * alertFunc     : ALERT 함수
 * popupFunc     : 팝업 open 함수
 * 실제 소유 여부 true / false
 ************************************************************************************************/
FSAmlUtil.gfn_aml_real_owner_check = async function(pContext, pData, alertFunc, popupFunc) {
  console.log('gfn_aml_real_owner_check...')

  if (pData) {
    // EDD/PEP 결재 여부 초기화
    lv_edd_pep_appr_yn = ''

    const context = !pContext ? this : pContext
    let t_eaiCommObj = FSCommUtil.gfn_eaiCommObj()

    if (FSCommUtil.gfn_length(pData) == 13 || FSCommUtil.gfn_length(pData) == 10) {
      pData = FSCommUtil.gfn_substr(pData, 0, 7)
    }

    t_eaiCommObj.lv_vm = context
    t_eaiCommObj.auth = 'S'
    t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570268_S'
    t_eaiCommObj.params = {
      bfcer_accn_no: pData
    }

    let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

    if (response && response.data) {
      let resultCd = FSCommUtil.gfn_trim(response.data.rt_cd)
      let bResult = false
      console.log(resultCd)
      // resultCd = 3

      if (resultCd == 3) {
        // 당일 실제소유자여부 작성이력 없음! (팝업 띄워줘야 함!)
        bResult = false
      } else if (resultCd == 2) {
        // 당일 실제소유자여부 작성이력 있음! (팝업 띄워줄 필요 없음!)
        bResult = true
      } else if (resultCd == 4) {
        // 당일 실제소유자여부 작성이력은 있는데 & EDD/PEP 대상이면서 결재를 안받았다!
        lv_edd_pep_appr_yn = 'N' // EDD/PEP  결재 여부
        bResult = false
      } else {
        bResult = true
      }

      if (bResult == false && resultCd == 3) {
        bResult = await FSAmlUtil.gfn_aml_real_owner(context, 'N', pData, alertFunc, popupFunc)
      } else {
        bResult = true
      }

      return bResult
    }
  }
}

/************************************************************************************************
 * Function명     : gfn_aml_check
 * 설명           : 자금세탁방지 및 테러 자금방저정책 ( AML ) 체크
 * acct_no        : 계좌번호/고객번호
 * parent_tr_cd   : 화면 ClsId
 * parent_trt_amt : 거래금액
 * alertFunc      : ALERT 함수
 * popupFunc      : 팝업 open 함수
 * CDD/EDD 수행대상 여부 true / false
 ************************************************************************************************/
FSAmlUtil.gfn_aml_check = async function(pContext, acct_no, parent_tr_cd, parent_trt_amt, reCall_yn, reObjRtn, alertFunc, popupFunc) {
  console.log('gfn_aml_check...')

  const context = !pContext ? this : pContext

  /* ASR230200824 고객확인 관련 화면 개선 요청 (2023.04.04) 시작
   * 공통코드(878, AML_대상화면)에 등록된 TR_CD인지 확인 후 신규/지속 거래 구분 값 셋팅
   */
  let process_gb = await FSAmlUtil.gfn_aml_obj_tr_info(context, parent_tr_cd)
  let aml_busi_gb = ''
  console.log('process_gb=' + process_gb)

  if (process_gb == '3') {
    aml_busi_gb = 'FM01' // 신규거래
  } else if (process_gb == '1') {
    aml_busi_gb = 'FM99' // 지속거래
  } else {
    // 정의되지 않음(종료)
    console.log('(FSAmlUtil.js) AML체크 대상 아님(종료)')
    return true
  }

  let cust_no = ''
  if (FSCommUtil.gfn_length(acct_no) == 13 || FSCommUtil.gfn_length(acct_no) == 10) {
    cust_no = FSCommUtil.gfn_substr(acct_no, 0, 7)
  } else {
    cust_no = acct_no
  }

  let t_aml_cu116500_data = await FSAmlUtil.fn_aml_cu116500(context, cust_no)

  if (FSCommUtil.gfn_trim(t_aml_cu116500_data.cust_cnf_syst_cust_cnf_yn) != 'Y') {
    let t_messageData = {
      content: 'CDD정보(#1165)를 추가 입력한 후 작업하세요.'
    }
    FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
    return false
  }

  if (reCall_yn == 'Y') {
    process_gb = '3'
    aml_busi_gb = 'FM02'
  }

  // 로그인정보
  let t_basInfo = context.getStore('fsStore').getters.getBasInfo.data
  let usid = ''
  let ipAddr = ''
  if (FSCommUtil.gfn_isNull(t_basInfo) == true) {
    console.log('로그인정보없음')
    // return false
  } else {
    usid = t_basInfo.usid
    ipAddr = t_basInfo.ipAddr
  }

  // AML(CDD/EDD)실제소유자확인
  let t_aml_cu170000_data = await FSAmlUtil.gfn_aml_cu170000_custinfo(context, {
    cust_no: cust_no,
    acct_no: cust_no,

    process_gb: process_gb,
    aml_busi_gb: aml_busi_gb,

    trt_amt_1: reObjRtn.trd_amt,
    corp_gb: reObjRtn.corp_gb,
    real_owner_gb: reObjRtn.real_owner_gb,
    real_owner_nm: reObjRtn.real_owner_nm,
    real_owner_rname_no: reObjRtn.rname_no,
    real_owner_birth_dt: reObjRtn.birth_dt,
    real_owner_natn_cd: reObjRtn.ctzn_natn_cd,
    real_owner_ques_info_cd: reObjRtn.ques_info_cd,

    insr_indcl_cd: FSCommUtil.gfn_trim(t_aml_cu116500_data.bfcer_indcl_cd), // CDD고객기본의 수익증권업종코드
    lis_yn: FSCommUtil.gfn_trim(t_aml_cu116500_data.lstck_yn), // CDD고객기본의 상장여부
    parent_tr_cd: parent_tr_cd,

    emp_id: usid,
    term_ip: ipAddr
  })

  console.log('========= AML(CDD/EDD)실제소유자확인 데이터 =========')
  console.log(t_aml_cu170000_data)

  let rt_cd = t_aml_cu170000_data.rt_cd

  if (rt_cd == 'W') {
    await FSAmlUtil.gfn_aml_real_owner(context, 'Y', cust_no, alertFunc, popupFunc, acct_no)
    return false
  } else if (rt_cd == 'X') {
    let msg_txt = t_aml_cu170000_data.msg_txt
    // ASR240701139 / AML 및 고객확인 체크보완 (2024.09.24)
    let t_messageData = {
      type: '11',
      content: 'AML : ' + msg_txt
    }

    /* if (FSCommUtil.gfn_trim(msg_txt) == '보험직업코드를 확인하세요.' || FSCommUtil.gfn_trim(msg_txt) == '보험업종코드를 확인하세요.') {
      t_messageData = {
        content: 'AML : ' + msg_txt,
        cancel: false,
        confirm: true
      }
    } */

    FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
    return false
  }

  // ASR240701139 / AML 및 고객확인 체크보완 (2024.09.24)
  let result = true
  if (t_aml_cu170000_data.bfcer_aml_check_sc_cd > '1') {
    let tMsg = t_aml_cu170000_data.bfcer_aml_check_msg_txt.split('\n')
    let msg_txt = []
    for (let i in tMsg) {
      msg_txt.push(tMsg[i])
    }
    let t_messageData = {
      type: '11',
      cancel: false,
      confirm: true,
      confirmFunc: function() {
        if (t_aml_cu170000_data.bfcer_aml_check_sc_cd === '4') {
          if (t_aml_cu170000_data.pep_yn === 'Y') {
            FSAmlUtil.fn_pop_aml_request(context, 'PEP', acct_no, aml_busi_gb, parent_tr_cd, reObjRtn.trd_amt, t_aml_cu170000_data.edd_sanc_obj_sc_cd, popupFunc)
          } else {
            FSAmlUtil.fn_pop_aml_request(context, 'EDD', acct_no, aml_busi_gb, parent_tr_cd, reObjRtn.trd_amt, t_aml_cu170000_data.edd_sanc_obj_sc_cd, popupFunc)
          }
        }
      },
      content: msg_txt
    }
    result = t_aml_cu170000_data.bfcer_aml_check_sc_cd === '2' ? true : false
    FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
  }
  return result
}

/************************************************************************************************
 * Function명    : fn_aml_cu116500
 * 설명          : CDD 고객 정보 조회
 * pData         : 계좌번호/고객번호
 * alertFunc     : ALERT 함수
 * CDD 등록여부 true / false
 ************************************************************************************************/
FSAmlUtil.fn_aml_cu116500 = async function(pContext, pData) {
  console.log('fn_aml_cu116500...')

  if (pData) {
    const context = !pContext ? this : pContext
    let t_eaiCommObj = FSCommUtil.gfn_eaiCommObj()

    t_eaiCommObj.lv_vm = context
    t_eaiCommObj.auth = 'S'
    t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570117_S'
    t_eaiCommObj.params = {
      bfcer_accn_no: pData
    }

    let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

    if (response && response.data) {
      return response.data
    }
  }
}

/************************************************************************************************
 * Function명    : gfn_aml_cu170000_custinfo
 * 설명          : AML(CDD/EDD)실제소유자확인 (진행시 당일진행여부 확인 pass)
 * pData         : 실소유자 조회 정보
 ************************************************************************************************/
FSAmlUtil.gfn_aml_cu170000_custinfo = async function(pContext, pData) {
  console.log('gfn_aml_cu170000_custinfo...')
  console.log(pData)

  if (pData) {
    const context = !pContext ? this : pContext
    let t_eaiCommObj = FSCommUtil.gfn_eaiCommObj()

    t_eaiCommObj.lv_vm = context
    t_eaiCommObj.auth = 'S'
    t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570270_S'
    t_eaiCommObj.params = {
      bfcer_cust_no: pData.cust_no,
      bfcer_accn_no: pData.acct_no,

      process_gb: pData.process_gb,
      aml_busi_gb: pData.aml_busi_gb,

      trt_amt_1: pData.trt_amt_1,
      corp_gb: pData.corp_gb,
      real_owner_gb: pData.real_owner_gb,
      real_owner_nm: pData.real_owner_nm,
      real_owner_rname_no: pData.real_owner_rname_no,
      real_owner_birth_dt: pData.real_owner_birth_dt,
      real_owner_natn_cd: pData.real_owner_natn_cd,
      real_owner_ques_info_cd: pData.real_owner_ques_info_cd,

      insr_indcl_cd: pData.insr_indcl_cd,
      lis_yn: pData.lis_yn,
      parent_tr_cd: pData.parent_tr_cd,

      bfcer_staf_no: pData.emp_id,
      term_ip: pData.term_ip
    }

    let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

    if (response && response.data) {
      let t_data = response.data.eaf10670028_I_VO

      if (FSCommUtil.gfn_length(t_data) > 0) {
        return t_data[0]
      }
    }
  }
}

/************************************************************************************************
 * Function명    : fn_pop_aml_request
 * 설명          : 고위험승인요청 팝업 띄우기
 * call_tp       : 호출타입 (EDD, PEP)
 * acct_no       : AML 업무 구분
 * aml_busi_gb   : 계좌번호
 * parent_tr_cd  : 화면 ClsId
 * trt_amt       : 거래금액
 * aml_refr_dtm  : AML참조일시(추가)
 ************************************************************************************************/
FSAmlUtil.fn_pop_aml_request = async function(pContext, call_tp, acct_no, aml_busi_gb, parent_tr_cd, trt_amt, edd_sanc_obj_sc_cd, popupFunc) {
  const context = !pContext ? this : pContext

  let t_data = {
    call_tp: call_tp,
    acct_no: acct_no,
    aml_busi_gb: aml_busi_gb,
    parent_tr_cd: parent_tr_cd,
    trt_amt: FSCommUtil.gfn_isNull(trt_amt) ? 0 : trt_amt,
    edd_sanc_obj_sc_cd: edd_sanc_obj_sc_cd
  }
  popupFunc.call(context, 'MSPFS305P', t_data)
}

/************************************************************************************************
 * Function명    : gfn_aml_obj_tr_info
 * 설명          : AML 대상 화면 조회
 * parent_tr_cd  : 화면 ClsId
 ************************************************************************************************/
FSAmlUtil.gfn_aml_obj_tr_info = async function(pContext, parent_tr_cd) {
  console.log('gfn_aml_obj_tr_info...')

  const context = !pContext ? this : pContext
  let t_eaiCommObj = FSCommUtil.gfn_eaiCommObj()

  t_eaiCommObj.lv_vm = context
  t_eaiCommObj.auth = 'S'
  t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570288_S'
  t_eaiCommObj.params = {
    parent_tr_cd: FSCommUtil.gfn_substr(parent_tr_cd, 2, 4)
  }

  let t_result = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

  return t_result.data.comm_busn_dtl_sc_cd01
}

/************************************************************************************************
 * Function명    : gfn_aml_real_owner
 * 설명          : AML 및 고객확인 체크 요건 보완 (2023.06.20)
 ************************************************************************************************/
FSAmlUtil.gfn_aml_real_owner = async function(pContext, reCall_Yn, cust_no, alertFunc, popupFunc, acct_no) {
  console.log('gfn_aml_real_owner...')

  const context = !pContext ? this : pContext

  // CDD정보 체크
  let gds_common_aml_cust = await FSAmlUtil.fn_aml_cu116500(context, cust_no)
  if (FSCommUtil.gfn_trim(gds_common_aml_cust.cust_cnf_syst_cust_cnf_yn) != 'Y') {
    let t_messageData = {
      content: 'CDD정보(#1165)를 추가 입력한 후 작업하세요.'
    }
    FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
    return false
  }

  // reCall_yn 값에 따라 팝업 띄움
  if (reCall_Yn == 'N') {
    // 고객번호 추가
    let oArg = {
      cust_no: cust_no
    }
    popupFunc.call(context, 'MSPFS301P', oArg)
    return false
  } else if (reCall_Yn == 'Y') {
    // 고객번호/AML 재이행 옵션값 추가
    let oArg = {
      cust_no: cust_no,
      acct_no: acct_no,
      re_aml_flag: 'Y'
    }
    popupFunc.call(context, 'MSPFS315P', oArg)
    return false
  }
  return true
}

export default FSAmlUtil
// eslint-disable-next-line
