/**
* 업무구분: 영업
* 화 면 명: MSPFS300M
* 화면설명: 그룹신규1010
* 작 성 일: 2023.05.08
* 작 성 자: 이태흥
* 수정일 : 2024-06-18 강현철 사유 : ASR240600395 고위험승인내역 화면 개선 요청
* 수정일 : 2024-08-20 강현철 사유 : ASR240800130 태블릿서식 변경 1종(연금저축핵심설명서 ) 및 업무 로직 수정
*/
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->
    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top align-end">
          <div class="left column">
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label> 고객번호 </label>
                <mo-text-field
                  ref="ed_cust_no"
                  type="number"
                  class="w150 flex-unset"
                  :class="lv_ed_cust_no.error ? 'error' : ''"
                  v-model="lv_ed_cust_no.value"
                  maxlength="7"
                  @keyup="fn_SearchValid($event, 0, true)"
                />
                <mo-button @click="fn_OpenPopup(0)"> 고객정보 </mo-button>
                <mo-text-field class="w130 flex-unset" v-model="lv_ed_cust_nm.value" disabled />
              </div>
              <div class="wrap-input row flex-unset">
                <label> 투자성향 </label>
                <mo-dropdown :items="lv_commCode.code269" v-model="lv_ivt_prpns_grd_cd.value" placeholder=" " class="w150" disabled />
              </div>
            </div>
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label> 업무구분 </label>
                <mo-radio-wrapper :items="itemsClassify" v-model="lv_rad_proc_gb.value" class="row" />
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 대리인여부 </label>
                <mo-radio-wrapper :class="lv_rdo_agnt_chk_yn.error ? 'error' : ''" :items="itemsAgent" v-model="lv_rdo_agnt_chk_yn.value" class="row" />
              </div>
              <div class="wrap-input row">
                <label> 동의기간 </label>
                <p class="input-txt">{{ lv_expd_ymd_text }}</p>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button class="clear" @click="fn_Init()"> 초기화 </mo-button>
              <!-- <mo-button class="clear" @click="fn_pass_check()"> 비교 </mo-button> -->
              <mo-button primary @click="fn_SearchValid({ key: 'v' }, 0, true)"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">계좌기본사항</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 비밀번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <m-trans-key-input
                      v-if="isMobile && isMtrans"
                      ref="ed_secure_password"
                      class="w80"
                      :class="lv_ed_password.error ? 'error' : ''"
                      v-model="lv_ed_password.value"
                      type="numberMax4"
                      dialog="Y"
                      start="0"
                      end="-1"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt"
                    />
                    <mo-text-field
                      v-else
                      ref="ed_password"
                      class="input-long"
                      :class="lv_ed_password.error ? 'error' : ''"
                      type="password"
                      v-model="lv_ed_password.value"
                      :disabled="lv_ed_password.disabled"
                      maxlength="4"
                    />
                    <span class="colorR wsN" v-if="lv_static_br">※ 브릿지 계좌 비밀번호</span>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 비밀번호 확인 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <m-trans-key-input
                      v-if="isMobile && isMtrans"
                      ref="ed_secure_password"
                      class="w80"
                      :class="lv_ed_password2.error ? 'error' : ''"
                      v-model="lv_ed_password2.value"
                      type="numberMax4"
                      dialog="Y"
                      start="0"
                      end="-1"
                      :isClear="lv_isClear2"
                      @masked-txt="fn_SetMaskedTxt2"
                    />
                    <mo-text-field
                      v-else
                      ref="ed_password2"
                      class="input-long"
                      :class="lv_ed_password2.error ? 'error' : ''"
                      type="password"
                      v-model="lv_ed_password2.value"
                      :disabled="lv_ed_password2.disabled"
                      maxlength="4"
                      @blur="fn_pass_check()"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 재투자여부 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="itemsReinvest" v-model="lv_reinv_yn.value" class="row" :disabled="lv_reinv_yn.disabled" />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 펀드코드 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="bfcer_fnd_cd"
                      type="number"
                      class="w80"
                      :class="lv_bfcer_fnd_cd.error ? 'error' : ''"
                      v-model="lv_bfcer_fnd_cd.value"
                      maxlength="6"
                      @keyup="fn_SearchValid($event, 1)"
                      :disabled="lv_bfcer_fnd_cd.disabled"
                    />
                    <mo-button :disabled="lv_search_popup_btn" @click="fn_OpenPopup(1)"> 펀드코드 </mo-button>
                    <mo-text-field class="input-long" :class="lv_bfcer_fnd_cd.error ? 'error' : ''" v-model="lv_bfcer_fnd_nm.value" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 상품구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :class="lv_cob_tax_tp_gb.error ? 'error' : ''" :items="itemsProduct" v-model="lv_cob_tax_tp_gb.value" placeholder=" " :disabled="lv_cob_tax_tp_gb.disabled" />
                  </div>
                </td>
                <th>
                  <span> 펀드등급 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="lv_commCode.code120" v-model="lv_fnd_grd.value" placeholder=" " disabled />
                    <mo-badge v-if="lv_st_fund_grade != ''" :text="lv_st_fund_grade" class="badge-type" :class="lv_st_fund_grade == '비적합상품' ? 'impossible' : 'possible'" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 연금개설방법 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-radio-wrapper
                      class="row"
                      :class="lv_rad_pension_trans_yn.error ? 'error' : ''"
                      :items="itemsPensionMethod"
                      v-model="lv_rad_pension_trans_yn.value"
                      :disabled="lv_rad_pension_trans_yn.disabled"
                    />
                  </div>
                </td>
                <th>
                  <span> 세제한도조회 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      numeric
                      ref="me_join_limit_amt"
                      class="w80"
                      :class="lv_me_join_limit_amt.error ? 'error' : ''"
                      v-model="lv_me_join_limit_amt.value"
                      :disabled="lv_me_join_limit_amt.disabled"
                      mask="number"
                    />
                    <mo-button :disabled="lv_search_popup_btn" @click="fn_btn_limit()"> 한도조회 </mo-button>
                    <mo-text-field ref="me_limit" class="input-long" :class="lv_me_limit.error ? 'error' : ''" v-model="lv_me_limit.value" :disabled="lv_me_limit.disabled" mask="number" />
                  </div>
                  <p class="colorR mt-2" v-if="lv_st_fin_income_tax">금융소득종합과세</p>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 저축종류 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_cob_save_tp.error ? 'error' : ''"
                      :items="lv_commCode.code231"
                      v-model="lv_cob_save_tp.value"
                      placeholder="선택하세요"
                      :disabled="lv_cob_save_tp.disabled"
                    />
                  </div>
                </td>
                <th class="bl-ddd">
                  <span> 월(목표)저축금 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      numeric
                      ref="me_accum_amt"
                      class="w80"
                      :class="lv_me_accum_amt.error ? 'error' : ''"
                      v-model="lv_me_accum_amt.value"
                      :disabled="lv_me_accum_amt.disabled"
                      mask="number"
                      maxlength="19"
                    />
                    <span class="wsN ml-3"> 저축기간 </span>
                    <mo-text-field
                      ref="ed_save_term"
                      class="w80"
                      :class="lv_ed_save_term.error ? 'error' : ''"
                      v-model="lv_ed_save_term.value"
                      :disabled="lv_ed_save_term.disabled"
                      @blur="fn_ed_save_term()"
                      type="number"
                      maxlength="4"
                    />
                    <span> 월 </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 납입방법 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="lv_commCode.code236" v-model="lv_cob_paidin_gb.value" placeholder="선택하세요" :disabled="lv_cob_paidin_gb.disabled" />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 권유자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w80" :class="lv_ed_inv_empno.error ? 'error' : ''" v-model="lv_ed_inv_empno.value" :disabled="lv_ed_inv_empno.disabled" />
                    <mo-button :disabled="lv_search_popup_btn" @click="fn_OpenPopup('MSPFS321P', '', 'SAVEL')"> 사원찾기 </mo-button>
                    <mo-text-field class="input-long" :class="lv_ed_inv_empno.error ? 'error' : ''" v-model="lv_ed_inv_empnm.value" :disabled="lv_ed_inv_empnm.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 연금지급개시일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker
                      class="input-long"
                      :class="lv_cal_pension_paym_start_dt.error ? 'error' : ''"
                      v-model="lv_cal_pension_paym_start_dt.value"
                      :bottom="false"
                      :disabled="lv_cal_pension_paym_start_dt.disabled"
                      placeholder=" "
                    />
                    <span v-if="lv_st_paym_start_dt">{{ lv_st_paym_start_dt }}</span>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 판매직원 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w80" :class="lv_ed_inv_seler.error ? 'error' : ''" v-model="lv_ed_inv_seler.value" :disabled="lv_ed_inv_seler.disabled" />
                    <mo-button :disabled="lv_search_popup_btn" @click="fn_OpenPopup('MSPFS321P', '', 'SELER')"> 사원찾기 </mo-button>
                    <mo-text-field class="input-long" :class="lv_ed_inv_seler.error ? 'error' : ''" v-model="lv_ed_inv_selernm.value" :disabled="lv_ed_inv_selernm.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 연금지급기간 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="w80"
                      :class="lv_ed_pension_paym_term.error ? 'error' : ''"
                      v-model="lv_ed_pension_paym_term.value"
                      :disabled="lv_ed_pension_paym_term.disabled"
                      type="number"
                    />
                    <span> 월 </span>
                  </div>
                </td>
                <th>
                  <span> 상담텔러 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w80" v-model="lv_ed_inv_counsel.value" :disabled="lv_ed_inv_counsel.disabled" />
                    <mo-button :disabled="lv_search_popup_btn" @click="fn_OpenPopup(3)"> 사원찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="lv_ed_inv_counselnm.value" :disabled="lv_ed_inv_counselnm.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 연금지급주기 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_cob_pension_paym_cycle.error ? 'error' : ''"
                      :items="lv_commCode.code350"
                      v-model="lv_cob_pension_paym_cycle.value"
                      placeholder=" "
                      :disabled="lv_cob_pension_paym_cycle.disabled"
                    />
                  </div>
                </td>
                <th>
                  <span> 지역단FP </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w80" v-model="lv_ed_inv_pm.value" :disabled="lv_ed_inv_pm.disabled" />
                    <mo-button :disabled="lv_search_popup_btn" @click="fn_OpenPopup('MSPFS322P')"> FP찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="lv_ed_inv_pmnm.value" :disabled="lv_ed_inv_pmnm.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 최초가입일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker
                      class="input-long"
                      :class="lv_cal_oring_open_dt.error ? 'error' : ''"
                      v-model="lv_cal_oring_open_dt.value"
                      :bottom="false"
                      :disabled="lv_cal_oring_open_dt.disabled"
                      placeholder=" "
                    />
                  </div>
                </td>
                <th>
                  <span> FP센터 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w80" v-model="lv_ed_inv_fp.value" :disabled="lv_ed_inv_fp.disabled" />
                    <mo-button :disabled="lv_search_popup_btn" @click="fn_OpenPopup('MSPFS323P', '', 'FP')"> FP찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="lv_ed_inv_fpnm.value" :disabled="lv_ed_inv_fpnm.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th></th>
                <td></td>
                <th>
                  <span> FO </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w80" v-model="lv_ed_inv_fo.value" :disabled="lv_ed_inv_fo.disabled" />
                    <mo-button :disabled="lv_search_popup_btn" @click="fn_OpenPopup('MSPFS323P', '', 'FO')"> FO찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="lv_ed_inv_fonm.value" :disabled="lv_ed_inv_fonm.disabled" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">매수사항</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 매입신청액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      numeric
                      ref="me_buy_prof_amt"
                      class="input-long"
                      :class="lv_me_buy_prof_amt.error ? 'error' : ''"
                      v-model="lv_me_buy_prof_amt.value"
                      mask="number"
                      :disabled="lv_me_buy_prof_amt.disabled"
                      @blur="fn_me_buy_prof_amt()"
                    />
                  </div>
                </td>
                <th>
                  <span> 매입가능금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_me_buy_able_amt.value" mask="number" :disabled="lv_me_buy_able_amt.disabled" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>
      <!-- "정기잔고증명 신청사항" 영역 빠지고 "판매자료첨부" 로 대체됨 2023-04-05 jy -->
      <!-- 지급형 계좌 추가사항 영역 삭제... 개발 진행까지는 남겨놓음 2023-06-02 -->
      <ur-box-container v-if="1 == 2" direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">지급형 계좌 추가사항</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span> 정기지급유형 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="itemsPayType" v-model="lv_pay_type.value" class="row" :disabled="lv_pay_type.disabled" @input="fn_pay_type()" />
                    <span v-if="lv_static72" class="exp-txt fs12">표준지급률</span>
                    <span v-if="lv_static73" class="exp-txt fs12">{{ lv_static73_text }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 지급률/지급기간 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field v-if="lv_pay_rate.visibled" class="w74" v-model="lv_pay_rate.value" mask="number" :disabled="lv_pay_rate.disabled" />
                    <span v-if="lv_pay_rate.visibled" class="wsN">% = &gt;</span>
                    <span v-if="lv_month_pay.visibled" class="wsN ml-5">회차지급금액</span>
                    <mo-text-field v-if="lv_month_pay.visibled" v-model="lv_month_pay.value" mask="number" :disabled="lv_month_pay.disabled" /> <span v-if="lv_month_pay.visibled">원</span>
                    <mo-radio-wrapper v-if="lv_pay_term.visibled" :items="itemsPayCycle" v-model="lv_pay_term.value" class="row" :disabled="lv_pay_term.disabled" />
                    <mo-dropdown v-if="lv_pay_term_etc.visibled" :items="period1" v-model="lv_pay_term_etc.value" class="w74 ml18" :disabled="lv_pay_term_etc.disabled" placeholder=" " />
                    <span v-if="lv_pay_term_etc.visibled">년</span>
                    <span v-if="lv_static64.visibled && lv_static64.value" class="ml-5 colorR">지급기간 : ({{ lv_static64.value }} 개월)</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 거치기간 </span>
                </th>
                <td>
                  <div class="wrap-input row gap18">
                    <div class="row gap6">
                      <mo-dropdown v-if="lv_pay_late_yy.visibled" class="w74" :items="itemsDeferPeriod1" v-model="lv_pay_late_yy.value" :disabled="lv_pay_late_yy.disabled" placeholder=" " />
                      <span v-if="lv_pay_late_yy.visibled" class="wsN">년</span>
                    </div>
                    <div class="row gap6">
                      <mo-dropdown v-if="lv_pay_late_mm.visibled" class="w74" :items="itemsDeferPeriod2" v-model="lv_pay_late_mm.value" :disabled="lv_pay_late_mm.disabled" placeholder=" " />
                      <span v-if="lv_pay_late_mm.visibled" class="wsN">개월</span>
                    </div>
                  </div>
                </td>
                <th>
                  <span> 지급개시일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_paym_start_day.value" :disabled="lv_paym_start_day.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 지급일자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown v-if="lv_pay_day.visibled" :items="itemsPayDate" v-model="lv_pay_day.value" class="w74" :disabled="lv_pay_day.disabled" placeholder=" " />
                    <span v-if="lv_pay_day.visibled">일</span>
                  </div>
                </td>
                <th>
                  <span> 환매신청예정일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-if="lv_next_req_dt.visibled" v-model="lv_next_req_dt.value" class="w116" :disabled="lv_next_req_dt.disabled" />
                    <span v-if="lv_next_req_dt.visibled" class="wsN fs12">지급개시일로부터 자동환매</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 지급주기 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="itemsPayCycle" v-model="lv_pay_cycle.value" class="row" :disabled="lv_pay_cycle.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> SMS 발송서비스 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-checkbox-wrapper :items="itemsSmsSend" v-model="lv_sms_send.value" class="row" :disabled="lv_sms_send.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 지급계좌 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="itemsPayAccount1" v-model="lv_pay_acct.value" class="row" :disabled="lv_pay_acct.disabled" />
                    <mo-dropdown class="w200" :items="itemsPayAccount2" v-model="lv_btrans_acct_no.value" :disabled="lv_btrans_acct_no.disabled" placeholder=" " />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>
      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" :disabled="lv_confirm_btn" @click="fn_Confirm()">확인</mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pAlertPopupObj"></fs-alert-popup>
    <!-- popup301 -->
    <msp-fs-301p ref="popup301" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup301Obj"></msp-fs-301p>
    <!-- popup301 -->
    <msp-fs-303p ref="popup303" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup303Obj"></msp-fs-303p>
    <!-- popup305 -->
    <msp-fs-305p ref="popup305" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup305Obj"></msp-fs-305p>
    <!-- popup308 -->
    <msp-fs-308p ref="popup308" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup308Obj"></msp-fs-308p>
    <!-- popup309 -->
    <msp-fs-309p ref="popup309" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup309Obj"></msp-fs-309p>
    <!-- popup310 -->
    <msp-fs-310p ref="popup310" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup310Obj"></msp-fs-310p>
    <!-- popup312 -->
    <msp-fs-312p ref="popup312" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup312Obj"></msp-fs-312p>

    <!-- popup313 -->
    <msp-fs-313p ref="popup313" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup313Obj"></msp-fs-313p>
    <!-- popup315 -->
    <msp-fs-315p ref="popup315" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup315Obj"></msp-fs-315p>
    <!-- popup321 -->
    <msp-fs-321p ref="popup321" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup321Obj"></msp-fs-321p>
    <!-- popup322 -->
    <msp-fs-322p ref="popup322" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup322Obj"></msp-fs-322p>
    <!-- popup323 -->
    <msp-fs-323p ref="popup323" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup323Obj"></msp-fs-323p>
    <msp-fs-316p ref="popup316" @fs-popup-callback="fn_SearchValid({ key: 'v' }, 0)" :pCust316Obj="pCust316Obj"></msp-fs-316p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
import FSBizUtil from '~/src/ui/fs/comm/FSBizUtil'
import FSAmlUtil from '~/src/ui/fs/comm/FSAmlUtil'
import FSFatcaUtil from '~/src/ui/fs/comm/FSFatcaUtil'
import FSFormUtil from '~/src/ui/fs/comm/FSFormUtil'
import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import MSPFS301P from '~/src/ui/fs/MSPFS301P' // AML_실제소유자여부확인 팝업
import MSPFS303P from '~/src/ui/fs/MSPFS303P' // FATCA개인 팝업
import MSPFS305P from '~/src/ui/fs/MSPFS305P' // 고위험승인요청 팝업
import MSPFS308P from '~/src/ui/fs/MSPFS308P' // 고객정보 팝업
import MSPFS309P from '~/src/ui/fs/MSPFS309P' // 새제상품전금융기관등록조회
import MSPFS310P from '~/src/ui/fs/MSPFS310P' // 펀드정보 팝업
import MSPFS312P from '~/src/ui/fs/MSPFS312P' // 상담텔러 팝업
import MSPFS313P from '~/src/ui/fs/MSPFS313P' // 업무대리인 조회 팝업
import MSPFS315P from '~/src/ui/fs/MSPFS315P' // 자금세탁(AML) 고객확인 재이행 팝업
import MSPFS321P from '~/src/ui/fs/MSPFS321P' // 직원검색 팝업
import MSPFS322P from '~/src/ui/fs/MSPFS322P' // 지역단FP 팝업
import MSPFS323P from '~/src/ui/fs/MSPFS323P' // FO/FP직원 팝업
import MSPFS316P from '~/src/ui/fs/MSPFS316P' // 조력자조회 팝업
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS300M',
  screenId: 'MSPFS300M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-301p': MSPFS301P,
    'msp-fs-303p': MSPFS303P,
    'msp-fs-305p': MSPFS305P,
    'msp-fs-308p': MSPFS308P,
    'msp-fs-309p': MSPFS309P,
    'msp-fs-310p': MSPFS310P,
    'msp-fs-312p': MSPFS312P,
    'msp-fs-313p': MSPFS313P,
    'msp-fs-315p': MSPFS315P,
    'msp-fs-321p': MSPFS321P,
    'msp-fs-322p': MSPFS322P,
    'msp-fs-323p': MSPFS323P,
    'msp-fs-316p': MSPFS316P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
    this.lv_serverType = process.env.NODE_ENV
  },
  mounted() {
    this.fn_Init()
    this.$bizUtil.insSrnLog('MSPFS300M')
    // 프로세스 타고 그룹신규 진입한 경우
    // console.log(this.$route.params)
    if (!FSCommUtil.gfn_isNull(this.$route.params.ed_cust_no)) {
      setTimeout(() => {
        this.lv_ed_cust_no.value = this.$route.params.ed_cust_no
        this.coprtPesonAsgnPopChkYn = this.$route.params.ed_insert
        // 고객번호 매핑 후 조회
        this.fn_SearchValid({ key: 'v' }, 0)
      }, 100)
    }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
      lv_isClear: false, // 보안키패드 초기화1
      lv_isClear2: false, // 보안키패드 초기화2
      lv_masked_val: '', // 마스크 변수 체크1
      lv_masked_val2: '', // 마스크 변수 체크2
      lv_ed_pop_rname_nm: '',
      lv_pop_cnf_no_encr: '',
      // 로그인정보
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      lv_userInfo: this.getStore('userInfo').getters.getUserInfo,
      lv_serverType: '',
      // 공통 객체
      pHeaderObj: {
        title: '그룹신규',
        step: '7'
      },
      pAlertPopupObj: {},
      lv_commCode: {},
      selectCnt: 0,
      // 팝업 객체
      pPopup301Obj: {},
      pPopup303Obj: {},
      pPopup305Obj: {},
      pPopup308Obj: {},
      pPopup309Obj: {},
      pPopup310Obj: {},
      pPopup312Obj: {},
      pPopup313Obj: {},
      pPopup315Obj: {},
      pPopup321Obj: {},
      pPopup322Obj: {},
      pPopup323Obj: {},
      pCust316Obj: {}, // 조력자조회
      // 검색조건
      lv_ed_cust_no: {}, // 고객번호
      lv_ed_cust_nm: {}, // 고객명
      lv_ivt_prpns_grd_cd: {}, // 투자성향
      lv_sy1005_data: {}, // 투자성향 데이터
      lv_ed_order_type: 'deposit', // late 트레이딩 설정
      lv_rad_proc_gb: {}, // 업무구분
      lv_rdo_agnt_chk_yn: {}, // 대리인여부
      lv_expd_ymd_text: '', // 동의기간
      lv_reObjRtn: {}, // AML실제 소유주여부 팝업 결과
      lv_reCall_yn: '', // 자금세탁(AML) 고객확인 재이행 여부

      lv_base_info: {}, // 고객정보조회 결과
      // 계좌기본사항
      lv_ed_password: {}, // 비밀번호
      lv_ed_password2: {}, // 비밀번호확인
      lv_static_br: '', // 브릿지계좌비밀번호TEXT
      lv_reinv_yn: {}, // 재투자여부
      lv_bfcer_fnd_cd: {}, // 펀드코드
      lv_bfcer_fnd_nm: {}, // 펀드명
      lv_select_fund_data: {}, // 펀드정보 팝업에서 선택한 펀드데이터
      lv_search_fund_info: {}, // 펀드코드로 조회 결과
      lv_cob_tax_tp_gb: {}, // 상품구분
      lv_fnd_grd: {}, // 펀드등급
      lv_st_fund_grade: '', // 펀드등급TEXT
      lv_rad_pension_trans_yn: {}, // 연금개설방법
      lv_me_join_limit_amt: {}, // 세제한도
      lv_me_limit: {}, // 한도조회조회금액
      lv_tax_prime: {}, // 한도조회결과데이터
      lv_st_fin_income_tax: false, // 금용소득종합과세 여부
      lv_cob_save_tp: {}, // 저축종류
      lv_me_accum_amt: {}, // 월(목표)저축금
      lv_ed_save_term: {}, // 저축기간
      lv_cob_paidin_gb: {}, // 납입방법
      lv_ed_inv_empno: {}, // 권유자사원번호
      lv_ed_inv_empnm: {}, // 권유자명
      lv_cal_pension_paym_start_dt: {}, // 연금지급개시일
      lv_st_paym_start_dt: '', // 연금지급개시일TEXT
      lv_paym_start_dt: '', // 연금지급개시일계산용
      lv_ed_inv_seler: {}, // 판매직원사원번호
      lv_ed_inv_selernm: {}, // 판매직원명
      lv_ed_pension_paym_term: {}, // 연금지급기간
      lv_ed_inv_counsel: {}, // 상담텔러사원번호
      lv_ed_inv_counselnm: {}, // 상담텔러명
      lv_cob_pension_paym_cycle: {}, // 연금지급주기
      lv_ed_inv_pm: {}, // 지역단FP사원번호
      lv_ed_inv_pmnm: {}, // 지역단FP명
      lv_cal_oring_open_dt: {}, // 최초가입일
      lv_ed_inv_fp: {}, // FP센터사원번호
      lv_ed_inv_fpnm: {}, // FP센터명
      lv_ed_inv_fo: {}, // FO사원번호
      lv_ed_inv_fonm: {}, // FO명
      // 매수사항
      lv_me_buy_prof_amt: {}, // 매입신청액
      lv_me_buy_able_amt: {}, // 매입가능금액

      // 정기잔고증명 신청사항(삭제)
      lv_reg_remn_pdoc_yn: 'N', // 정기잔고증명 신청사항(메뉴삭제)
      lv_ed_reg_remn_pdoc_dd: '', // 발급기준일
      lv_ed_issue_qty: '', // 발급매수
      lv_ed_issue_use: '', // 발급용도
      lv_cob_estm_base: '', // 평가기준
      lv_rad_no_remn_qty_yn: '', // 무잔고포함여부

      // 지급형 계좌 추가사항
      lv_static72: true, // 표준지급율
      lv_static73: false, // 최저지급율
      lv_static73_test: '', // 최저지급율TEXT
      lv_static53: {},
      lv_static55: {},
      lv_static56: {},
      lv_pay_type: {}, // 정기지급유형
      lv_pay_rate: {}, // 지급률
      lv_month_pay: {}, // 회차지급금액
      lv_pay_term: {}, // 지급기간hidden
      lv_pay_term_etc: {}, // 지급기간
      lv_static64: {}, // 지급기간TEXT
      lv_pay_late_yy: {}, // 거치기간(년)
      lv_pay_late_mm: {}, // 거치기간(월)
      // lv_pay_late_month: {},        // 거치기간
      lv_paym_start_day: {}, // 지급개시일
      lv_pay_day: {}, // 지급일자
      lv_next_req_dt: {}, // 환매신청예정일
      lv_pay_cycle: {}, // 지급주기
      lv_sms_send: {}, // SMS 발송서비스
      lv_pay_acct: {}, // 지급계좌
      lv_btrans_acct_no: {}, // 타은행계좌
      // AS-IS 전역변수 수정
      lv_gorp_gb: 'N', // 개인/법인여부
      lv_for_fund_yn: 'N', // 해외펀드여부
      lv_inter_useyn: 'Y', // 연합회사용여부
      lv_br_tr_from_tm: '', // 업무개시시간
      lv_br_tr_to_tm: '', // 업무종료시간
      lv_tax_gb: '11', // 과세구분(11:개인)
      lv_old_yn: '', // 고령자여부
      lv_stdOldAge: 65, // 고령자나이 세팅
      lv_corp_yn: 'N', // 법인여부(법인제외)
      // button
      lv_search_btn: true, // 조회
      lv_confirm_btn: true, // 확인
      lv_search_popup_btn: false, // 조회팝업버튼
      // DATASET
      lv_tst_rctst_data: {}, // 신탁 투자성향 DATASET
      lv_submit_data: {}, // 그룹신규 등록 DATASET
      lv_ed_acct_no: '', // 생성 계좌번호
      lv_password_check: false, // 비밀번호 확인
      /***********************************************************************************
       * F10570166 : 신탁수탁 시스템 투자성향 설문 데이터로 설문등록
       * F10570186 : 신탁수탁 시스템 투자성향 설문 데이터 조회
       * F10570187 : 고객투자 성향 등급 조회
       * F10570188 : 통합계좌여부와 매입가능 금액 조회
       * F10570189 : 한도조회
       * F10570193 : 그룹신규등록
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      // 고령자 조력자 팝업 확인 클릭 여부
      coprtPesonAsgnPopChkYn: ''
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // 업무구분
    itemsClassify() {
      let rtn = []
      rtn.push({ value: '11', text: '신규' })
      rtn.push({ value: '22', text: '브릿지계좌신규' })
      return rtn
    },

    // 업무대리인
    itemsAgent() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },
    // 재투자여부
    itemsReinvest() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },
    // 상품구분
    itemsProduct() {
      let rtn = []
      rtn.push({ value: '0', text: '일반' })
      // rtn.push({value: '1', text: '일반(정기지급형)'})
      rtn.push({ value: '4', text: '비과세종합저축' })
      rtn.push({ value: '7', text: '(구)연금저축' })
      // rtn.push({value: '14', text: '비과세(정기지급형)'})
      return rtn
    },
    // 연금개설방법
    itemsPensionMethod() {
      let rtn = []
      rtn.push({ value: '11', text: '일반' })
      rtn.push({ value: '21', text: '이전신규' })
      return rtn
    },
    // 정기지급유형
    itemsPayType() {
      let rtn = []
      rtn.push({ value: 'Y', text: '지급률 지정' })
      rtn.push({ value: 'N', text: '지급기간 지정' })
      return rtn
    },
    // 거치기간(년)
    itemsDeferPeriod1() {
      let rtn = []
      rtn.push({ value: '0', text: '' })
      rtn.push({ value: '12', text: '1' })
      rtn.push({ value: '24', text: '2' })
      rtn.push({ value: '24', text: '2' })
      rtn.push({ value: '36', text: '3' })
      rtn.push({ value: '48', text: '4' })
      rtn.push({ value: '60', text: '5' })
      rtn.push({ value: '72', text: '6' })
      rtn.push({ value: '84', text: '7' })
      rtn.push({ value: '96', text: '8' })
      rtn.push({ value: '108', text: '9' })
      rtn.push({ value: '120', text: '10' })
      return rtn
    },
    // 거치기간(월)
    itemsDeferPeriod2() {
      let rtn = []
      rtn.push({ value: '0', text: '' })
      rtn.push({ value: '1', text: '1' })
      rtn.push({ value: '2', text: '2' })
      rtn.push({ value: '3', text: '3' })
      rtn.push({ value: '4', text: '4' })
      rtn.push({ value: '5', text: '5' })
      rtn.push({ value: '6', text: '6' })
      rtn.push({ value: '7', text: '7' })
      rtn.push({ value: '8', text: '8' })
      rtn.push({ value: '9', text: '9' })
      rtn.push({ value: '10', text: '10' })
      rtn.push({ value: '11', text: '11' })
      return rtn
    },
    // 지급일자
    itemsPayDate() {
      let rtn = []
      rtn.push({ value: '05', text: '05' })
      rtn.push({ value: '10', text: '10' })
      rtn.push({ value: '15', text: '15' })
      rtn.push({ value: '20', text: '20' })
      rtn.push({ value: '25', text: '25' })
      return rtn
    },
    // 지급주기
    itemsPayCycle() {
      let rtn = []
      rtn.push({ value: '11', text: '월' })
      rtn.push({ value: '12', text: '분기' })
      rtn.push({ value: '13', text: '반기' })
      rtn.push({ value: '14', text: '년' })
      return rtn
    },
    // SMS 발송서비스
    itemsSmsSend() {
      let rtn = []
      rtn.push({ value: '1', text: '매월잔고통보' })
      rtn.push({ value: '2', text: '지급결과통보' })
      return rtn
    },
    // 지급계좌
    itemsPayAccount1() {
      let rtn = []
      rtn.push({ value: 'N', text: '브릿지(예수금)' })
      rtn.push({ value: 'Y', text: '타은행' })
      return rtn
    },
    // 타은행계좌
    itemsPayAccount2() {
      let rtn = []
      rtn.push({ value: '1', text: '은행1' })
      rtn.push({ value: '2', text: '은행2' })
      return rtn
    },
    // 지급기간
    period1() {
      let rtn = []
      for (let i = 1; i < 51; i++) {
        rtn.push({ value: 12 * i, text: i })
      }
      return rtn
    },
    period2() {
      let rtn = []
      rtn.push({ value: '120', text: '10년' })
      rtn.push({ value: '240', text: '20년' })
      rtn.push({ value: '360', text: '30년' })
      rtn.push({ value: '999', text: '기타' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 업무구분
    'lv_rad_proc_gb.value': {
      handler(after, before) {
        let t_popupObj = {}
        if (after == '22') {
          // 브릿지계좌신규
          if (this.lv_base_info.unty_apamt_accn_yn == '' || this.lv_base_info.unty_apamt_accn_yn == 'O') {
            this.lv_bfcer_fnd_cd.disabled = true // 펀드코드
            this.lv_reinv_yn.disabled = true // 재투자여부
            this.lv_cob_tax_tp_gb.disabled = true // 상품구분
            this.lv_cob_save_tp.disabled = true // 저축종류
            this.lv_me_buy_prof_amt.disabled = true // 매입신청액
            if (!this.isMobile) {
              this.lv_ed_password.value = '' // 비밀번호
              this.lv_ed_password2.value = '' // 비밀번호확인
            } else {
              this.lv_isClear = !this.lv_isClear ? true : false
              this.lv_isClear2 = !this.lv_isClear2 ? true : false
            }
            this.lv_static_br = false // 브릿지계좌비밀번호TEXT

            this.lv_search_popup_btn = true // 조회팝업버튼
            t_popupObj = { content: '브릿지계좌신규는 예수금계좌만 생성합니다.' }
            this.fn_AlertPopup(0, t_popupObj)
          } else {
            t_popupObj = { content: '브릿지계좌신규는 신규고객만 가능합니다.' }
            this.fn_AlertPopup(0, t_popupObj)
            this.lv_rad_proc_gb.value = '11'
          }
        } else {
          // 신규
          this.lv_bfcer_fnd_cd.disabled = false // 펀드코드
          this.lv_reinv_yn.disabled = false // 재투자여부
          this.lv_cob_tax_tp_gb.disabled = false // 상품구분
          this.lv_cob_save_tp.disabled = false // 저축종류
          this.lv_me_buy_prof_amt.disabled = false // 매입신청액
          this.lv_search_popup_btn = false // 조회팝업버튼
          // 브릿지 계좌 있는 고객 체크
          if (this.lv_base_info.unty_apamt_accn_yn == 'Y') {
            if (!this.isMobile) {
              this.lv_ed_password.value = '' // 비밀번호
              this.lv_ed_password2.value = '' // 비밀번호확인
            } else {
              this.lv_isClear = !this.lv_isClear ? true : false
              this.lv_isClear2 = !this.lv_isClear2 ? true : false
            }
            this.lv_static_br = true // 브릿지계좌비밀번호TEXT
          } else {
            this.lv_static_br = false // 브릿지계좌비밀번호TEXT
          }
        }
      },
      deep: true
    },
    // 대리인여부
    'lv_rdo_agnt_chk_yn.value': {
      handler(after, before) {
        if (after == 'Y') {
          // 대리인 만기체크
          this.fn_AgentCheck()
        }
      },
      deep: true
    },
    // 상품구분
    'lv_cob_tax_tp_gb.value': {
      handler(after, before) {
        // 세금 한도조회 내역 삭제
        this.lv_me_join_limit_amt.value = ''
        this.lv_me_limit.value = ''
        // 연금지급개시일_계산 초기화
        this.lv_cal_pension_paym_start_dt.value = ''

        // 판매종료 상품 삭제
        if (after == '7' || after == '13') {
          // 연금계좌 적용
          this.lv_cob_save_tp.value = '14'
        }
        // 재투자여부 '예' 변경
        this.lv_reinv_yn.value = 'Y'

        if (!FSCommUtil.gfn_isNull(this.lv_ed_cust_nm.value)) {
          if (after == '7') {
            // 최초가입일 활성화
            this.lv_cal_oring_open_dt.disabled = false

            let lv_vm = this
            let t_popupObj = {
              content: '세제상품전금융기관등록조회 화면에서 최초가입일 정보를 확인하세요.',
              cancel: false,
              confirm: true,
              confirmFunc: function() {
                lv_vm.pPopup309Obj.rlnm_cnf_no = lv_vm.$route.params.ed_rname_no
                lv_vm.fn_OpenPopup('MSPFS309P')
              }
            }
            this.fn_AlertPopup(0, t_popupObj)
          } else {
            // 최초가입일 비활성화
            this.lv_cal_oring_open_dt.value = ''
            this.lv_cal_oring_open_dt.disabled = true
          }
        }
        this.fn_enable(after, this.lv_cob_save_tp.value)
      },
      deep: true
    },
    // 연금개설방법
    'lv_rad_pension_trans_yn.value': {
      handler(after, before) {
        //연금개설방법이 일반
        if (after == '11') {
          // 연금개설방법:일반인 경우 구연금저축 개설 불가
          if (this.lv_cob_tax_tp_gb.value == '7') {
            FSCommUtil.gfn_validate(this, '신연금계좌로만 개설 가능합니다.')
            this.lv_cob_tax_tp_gb.value = '13'
          }
          this.fn_enable(this.lv_cob_tax_tp_gb.value, this.lv_cob_save_tp.value)
        } else {
          // 연금개설방법:이전신규인 경우 구연금저축만 가능
          if (this.lv_cob_tax_tp_gb.value == '13') {
            FSCommUtil.gfn_validate(this, '구연금저축으로만 개설 가능합니다.')
            this.lv_cob_tax_tp_gb.value = '7'
          }

          //연금개설방법
          this.lv_rad_pension_trans_yn.value = '21'
          this.lv_rad_pension_trans_yn.disabled = false
          //연급지급기간
          this.lv_ed_pension_paym_term.value = ''
          this.lv_rad_pension_trans_yn.disabled = true
          //연금지급개시일
          this.lv_cal_pension_paym_start_dt.value = ''
          this.lv_rad_pension_trans_yn.disabled = true
          //연금지급주기
          this.lv_cob_pension_paym_cycle.value = '10'
          this.lv_rad_pension_trans_yn.disabled = false
          //저축기간
          this.lv_ed_save_term.value = ''
          this.lv_rad_pension_trans_yn.disabled = true

          //연금지급개시일TEXT
          this.lv_st_paym_start_dt = ''
        }
      },
      deep: true
    },

    // 저축종류
    'lv_cob_save_tp.value': {
      handler(after, before) {
        // 해외펀드는 임의식과 정액적립식만 가능
        if (this.lv_for_fund_yn == 'Y') {
          if (after == '11' || after == '13') {
          } else {
            FSCommUtil.gfn_validate(this, '해외 펀드는 임의식과 정액적립식만 가능합니다.')
            this.lv_cob_save_tp.value = ''
            return false
          }
        }
        this.fn_enable(this.lv_cob_tax_tp_gb.value, after)
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
    },
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt2
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    async fn_SetMaskedTxt2(val) {
      this.lv_masked_val2 = val
      this.lv_password_check = false
      if (this.lv_masked_val.length === 4 && this.lv_masked_val2.length === 4) {
        this.lv_ed_password2.error = false
        this.lv_password_check = await this.fn_pass_check()
        if (!this.lv_password_check) {
          this.lv_isClear2 = !this.lv_isClear2 ? true : false
          this.lv_ed_password2.value = ''
          this.lv_ed_password2.error = true
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')
      let params = [
        { cId: '303', dayChkYn: 'N' },
        { cId: '322', dayChkYn: 'N' },
        { cId: '989', dayChkYn: 'N' }, //은행코드
        { cId: '231', dayChkYn: 'N' }, //저축종류
        { cId: '221', dayChkYn: 'N' }, //우편물수령구분
        { cId: '235', dayChkYn: 'N' }, //운용보고서통보지
        { cId: '236', dayChkYn: 'N' }, //납입방법
        { cId: '239', dayChkYn: 'N' }, //정기잔고평가기준
        { cId: '350', dayChkYn: 'N' }, //연금지급주기
        { cId: '269', dayChkYn: 'N' }, //고객투자성향
        { cId: '120', dayChkYn: 'N' }, //펀드등급
        { cId: '640', dayChkYn: 'N' } //강남PL 처리권한
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult
      // console.log(this.lv_commCode.code231)
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS300M] fn_Init...')
      // 검색조건
      this.lv_ed_cust_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_no') // 고객번호
      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1) // 고객명
      this.lv_ivt_prpns_grd_cd = FSCommUtil.gfn_dataSet(1) // 투자성향
      this.lv_sy1005_data = {} // 투자성향 데이터
      this.lv_ed_order_type = 'deposit' // late 트레이딩 설정
      this.lv_rad_proc_gb = FSCommUtil.gfn_dataSet(0, '11') // 업무구분
      this.lv_rdo_agnt_chk_yn = FSCommUtil.gfn_dataSet(1) // 대리인여부
      this.lv_expd_ymd_text = '' // 동의기간
      this.lv_old_yn = '' // 고령자여부
      this.lv_stdOldAge = 65 // 고령자나이 세팅

      this.lv_reObjRtn = {} // AML실제 소유주여부 팝업 결과
      this.lv_reCall_yn = '' // 자금세탁(AML) 고객확인 재이행 여부
      this.lv_corp_yn = 'N' // 법인여부(법인제외)
      this.lv_reg_remn_pdoc_yn = 'N' // 정기잔고증명 신청사항(메뉴삭제)
      this.lv_base_info = {} // 고객정보조회 결과

      // 계좌기본사항
      this.lv_ed_password = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_password') // 비밀번호
      this.lv_ed_password2 = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_password2') // 비밀번호확인
      this.lv_static_br = false // 브릿지계좌비밀번호TEXT
      this.lv_reinv_yn = FSCommUtil.gfn_dataSet(0, 'Y') // 재투자여부
      this.lv_bfcer_fnd_cd = FSCommUtil.gfn_dataSet(1, '', false, '', 'bfcer_fnd_cd') // 펀드코드
      this.lv_bfcer_fnd_nm = FSCommUtil.gfn_dataSet(0) // 펀드명
      this.lv_select_fund_data = {} // 펀드정보 팝업에서 선택한 펀드데이터
      this.lv_search_fund_info = {} // 펀드코드로 조회 결과
      this.lv_cob_tax_tp_gb = FSCommUtil.gfn_dataSet(0, '0') // 상품구분
      this.lv_fnd_grd = FSCommUtil.gfn_dataSet(0) // 펀드등급
      this.lv_st_fund_grade = '' // 펀드등급TEXT
      this.lv_rad_pension_trans_yn = FSCommUtil.gfn_dataSet(0, '11', true) // 연금개설방법
      this.lv_me_join_limit_amt = FSCommUtil.gfn_dataSet(1, '', true) // 세제한도
      this.lv_me_limit = FSCommUtil.gfn_dataSet(1, '', true) // 한도조회조회금액
      this.lv_tax_prime = {} // 한도조회결과데이터
      this.lv_st_fin_income_tax = false // 금융소득종합과세 여부
      this.lv_cob_save_tp = FSCommUtil.gfn_dataSet(1) // 저축종류
      this.lv_me_accum_amt = FSCommUtil.gfn_dataSet(1, '', true) // 월(목표)저축금
      this.lv_ed_save_term = FSCommUtil.gfn_dataSet(1, '', true) // 저축기간
      this.lv_cob_paidin_gb = FSCommUtil.gfn_dataSet(0, '0', true) // 납입방법
      this.lv_ed_inv_empno = FSCommUtil.gfn_dataSet(1, '', true) // 권유자사원번호
      this.lv_ed_inv_empnm = FSCommUtil.gfn_dataSet(1, '', true) // 권유자명
      this.lv_cal_pension_paym_start_dt = FSCommUtil.gfn_dataSet(1, '', true) // 연금지급개시일
      this.lv_st_paym_start_dt = '' // 연금지급개시일TEXT
      this.lv_paym_start_dt = '' // 연금지급개시일계산용
      this.lv_ed_inv_seler = FSCommUtil.gfn_dataSet(1, '', true) // 판매직원사원번호
      this.lv_ed_inv_selernm = FSCommUtil.gfn_dataSet(1, '', true) // 판매직원명
      this.lv_ed_pension_paym_term = FSCommUtil.gfn_dataSet(1, '', true) // 연금지급기간
      this.lv_ed_inv_counsel = FSCommUtil.gfn_dataSet(1, '', true) // 상담텔러사원번호
      this.lv_ed_inv_counselnm = FSCommUtil.gfn_dataSet(1, '', true) // 상담텔러명
      this.lv_cob_pension_paym_cycle = FSCommUtil.gfn_dataSet(1, '', true) // 연금지급주기
      this.lv_ed_inv_pm = FSCommUtil.gfn_dataSet(1, '', true) // 지역단FP사원번호
      this.lv_ed_inv_pmnm = FSCommUtil.gfn_dataSet(1, '', true) // 지역단FP명

      this.lv_cal_oring_open_dt = FSCommUtil.gfn_dataSet(1, '', true) // 최초가입일
      this.lv_ed_inv_fp = FSCommUtil.gfn_dataSet(1, '', true) // FP센터사원번호
      this.lv_ed_inv_fpnm = FSCommUtil.gfn_dataSet(1, '', true) // FP센터명
      this.lv_ed_inv_fo = FSCommUtil.gfn_dataSet(1, '', true) // FO사원번호
      this.lv_ed_inv_fonm = FSCommUtil.gfn_dataSet(1, '', true) // FO명
      // 매수사항
      this.lv_me_buy_prof_amt = FSCommUtil.gfn_dataSet(2, '', false) // 매입신청액
      this.lv_me_buy_able_amt = FSCommUtil.gfn_dataSet(2, '', true) // 매입가능금액

      // 지급형 계좌 추가사항
      this.lv_static72 = true // 표준지급율
      this.lv_static73 = false // 최저지급율
      this.lv_static73_text = '' // 최저지급율TEXT
      this.lv_static53 = FSCommUtil.gfn_dataSet(0, '', true, '', '', false)
      this.lv_static55 = FSCommUtil.gfn_dataSet(0, '', true, '', '', false)
      this.lv_static56 = FSCommUtil.gfn_dataSet(0, '', true, '', '', false)
      this.lv_pay_type = FSCommUtil.gfn_dataSet(0, '', true) // 정기지급유형
      this.lv_pay_rate = FSCommUtil.gfn_dataSet(0, '', true) // 지급률
      this.lv_month_pay = FSCommUtil.gfn_dataSet(0, '', true) // 회차지급금액
      this.lv_pay_term = FSCommUtil.gfn_dataSet(0, '', true, '', '', false) // 지급기간hidden
      this.lv_pay_term_etc = FSCommUtil.gfn_dataSet(0, '', true) // 지급기간
      this.lv_static64 = FSCommUtil.gfn_dataSet(0, '', true, '', '', false) // 지급기간TEXT
      this.lv_pay_late_yy = FSCommUtil.gfn_dataSet(0, '', true) // 거치기간(년)
      this.lv_pay_late_mm = FSCommUtil.gfn_dataSet(0, '', true) // 거치기간(월)
      this.lv_pay_late_month = FSCommUtil.gfn_dataSet(0, '', true) // 거치기간
      this.lv_paym_start_day = FSCommUtil.gfn_dataSet(0, '', true) // 지급개시일
      this.lv_pay_day = FSCommUtil.gfn_dataSet(0, '', true) // 지급일자
      this.lv_next_req_dt = FSCommUtil.gfn_dataSet(0, '', true) // 환매신청예정일
      this.lv_pay_cycle = FSCommUtil.gfn_dataSet(0, '11', true) // 지급주기
      this.lv_sms_send = FSCommUtil.gfn_dataSet(0, '', true) // SMS 발송서비스
      this.lv_pay_acct = FSCommUtil.gfn_dataSet(0, 'N', true) // 지급계좌
      this.lv_btrans_acct_no = FSCommUtil.gfn_dataSet(0, '', true) // 타은행계좌
      // button
      this.lv_search_btn = true // 조회
      this.lv_confirm_btn = true // 확인
      this.lv_search_popup_btn = false // 조회팝업버튼
      // 신탁 투자성향 DATASET
      this.fn_ClearTstRctstData()
      // 그룹신규 등록 DATASET
      this.lv_submit_data = {
        cust_no: '', //고객번호
        cust_nm: '', //고객명
        secure_passv: '', //고객비밀번호
        secure_new_passv: '', //고객비밀번호 확인용
        save_tp: '', //저축종류
        fund_cd: '', //펀드코드
        accum_amt: '', //월저축금(적립금액)
        save_term: '', //저축기간
        mon_paidin_dd: '', //월납입일
        paidin_gb: '', //납입방법
        lifetax_yn: '', //세금우대생계형여부
        join_limit_amt: '', //세금우대한도금액
        reinv_yn: '', //재투자여부
        mgr_report_recv_gb: '', //운용보고서통보
        inv_empno: '', //권유자
        inv_pm: '', //pm
        inv_fp: '', //fp
        inv_counsel: '', //상담텔러
        user_group_cd: '', //권유자 구분
        recp_cash_amt: '', //현금입금액
        check_recp_amt: '', //수표입금액
        check_qty: '', //수표매수
        win_alt_recp_amt: '', //대체입금액
        recp_resv_req_amt: '', //본사입금액
        buy_prof_amt: '', //매입신청금액
        order_type: '', //매수구분
        passbook_issue_yn: '', //통장발급여부
        addrmrk_nm: '', //부기명
        mail_recv_gb: '', //우편물수령지구분
        p_addr_basic: '', //통장기본주소
        p_addr_zipcode1: '', //통장주소우편번호1
        p_addr_zipcode2: '', //통장주소우편번호2
        p_zip_addr: '', //통장우편번호주소
        p_addr_oth_addr: '', //통장잔여주소
        p_address: '', //통장주소화면출력용
        telno_area: '', //전화지역번호
        telno_station: '', //전화국번호
        telno_seq: '', //전화일련번호
        telno_ext_no: '', //전화내선번호
        email_addr: '', //EMAIL주소
        domain_name: '', //DOMAIN명
        mgt_br: '', //관리기관(예수금계좌관리기관, 필수항목)
        cp_telno_comp: '', //이동전화 사업자번호
        cp_telno_station: '', //이동전화 국번호
        cp_telno_seq: '', //이동전화 일련번호
        faxno_area: '', //팩스 지역번호
        faxno_station: '', //팩스 국번호
        faxno_seq: '', //팩스 일련번호
        reg_remn_pdoc_yn: '', //정기잔고증명발급여부
        reg_remn_pdoc_dd: '', //정기잔고증명발급일
        reg_remn_no_remn_qty_yn: '', //정기잔고증명 무잔고 포함여부
        issue_qty: '', //발급매수
        issue_use: '', //발급용도
        estm_base: '', //평가기준
        pers_corp_gb: '', //개인법인구분
        tax_gb: '', //과세구분(개인/법인)
        link_acct_yn: '', //가상계좌 등록여부
        link_acct_no: '', //가상계좌번호
        link_bank_cd: '', //가상계좌은행코드
        acct_item_gb: '', //은행계좌 등록시계좌구분(은행이체,당좌,기타예금)
        trd_bank_cd: '', //등록은행 코드
        bank_acct_no: '', //등록은행 계좌번호
        bank_acct_nm: '', //등록은행 계좌명
        for_fund_yn: '', //해외펀드여부
        currency_cd: '', //통화코드
        open_gb: '', //신규구분(11:일반,21:이전신규)
        pension_paym_term: '', //연금지급기간
        pension_paym_open_dt: '', //연금지급개시일
        pension_paym_cycle: '', //연금지급주기
        tax_tp_gb: '', //세제유형구분
        pension_trans_fix_yn: '', //연금이전확정여부
        grade_seq: '', //투자성향설문 일련번호
        fund_prop_grade: '', //펀드등급
        ob_yn: '', //OutBound 여부
        new_proc_gb: '', //신규구분
        tel_use_yn: '', //유선개설여부
        pay_type: '', //정기지급유형
        pay_rate: '', //지급율
        month_pay: '', //회차지급액(지급율 지정시)
        pay_term: '', //지급기간(지급기간지정시)
        pay_cycle: '', //지급주기
        pay_day: '', //지급일자
        pay_acct: '', //지급계좌(예수금/타행)
        btrans_acct_seq: '', //타행이체 계좌번호
        pay_late_month: '', //거치기간(기간이후 자동환매시작)
        paym_start_day: '', //지급개시일
        sms_remn: '', //매월잔고통보
        sms_redem: '', //지급결과통보
        next_req_dt: '', //차기환매신청예정일
        inv_fo: '', //fo
        exhibit: '', //징구서류종휴 (1: 근로소득원천징수 영수증 , 2: 근로소득지급조서, 3: 종합소득납부신고서)
        inc_amt: '', //소득액
        term_dept: '', //사용자 부서(asis CommBean 에 있는 변수임)
        tr_code: '', //tr_code
        depo_fund_cd: '', //예수금 펀드코드
        sup_id: '', //승인자사번
        pbk_no: '', //그룹번호
        message: '', //메세지
        media: '', //매체구분
        inv_seler: '', //판매자사번
        aprv_sno: '',
        secure_encsymm: '',
        oring_open_dt: '' //최초가입일
      }
      // AS-IS 전역변수 수정
      this.lv_gorp_gb = 'N' // 개인/법인여부
      this.lv_for_fund_yn = 'N' // 해외펀드여부
      this.lv_inter_useyn = 'Y' // 연합회사용여부
      this.lv_br_tr_from_tm = '' // 업무개시시간
      this.lv_br_tr_to_tm = '' // 업무종료시간
      this.lv_tax_gb = '11' // 과세구분(11:개인)
      // 보안키패드 초기화
      this.lv_isClear = !this.lv_isClear ? true : false
      this.lv_isClear2 = !this.lv_isClear2 ? true : false
      this.fn_enable('0', '11')
    },
    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type)
          0: 고객정보
          1: 펀드정보
          3: 상담텔러조회
          MSPFS301P: AML_실제소유자여부확인
          MSPFS303P: FATCA개인
          MSPFS305P: 고위험승인요청
          MSPFS309P: 세제상품전금융기관등록조회
          MSPFS313P: 업무대리인 조회
          MSPFS315P: 자금세탁(AML) 고객확인 재이행
          MSPFS321P: 직원검색 팝업
          MSPFS322P: 지역단FP 팝업
          MSPFS323P: FO/FP직원 팝업
       ******************************************************************************/
    fn_OpenPopup(type, pData, subId) {
      let t_popupObj = {}
      switch (type) {
        case 0:
          // this.pPopup308Obj.isAccn = true
          this.$refs.popup308.fn_Open()
          break
        case 1:
          // 초기화
          this.lv_ed_cust_no.error = false
          if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value)) {
            t_popupObj = { content: '고객번호를 입력하세요.' }
            this.fn_AlertPopup(0, t_popupObj)
            this.lv_ed_cust_no.error = true
            this.$refs['ed_cust_no'].focus()
            return
          }
          this.$refs.popup310.fn_Open()
          break
        case 3:
          // 상담텔러 조회 팝업
          this.pPopup312Obj = pData
          this.$refs.popup312.fn_Open()
          break
        case 'MSPFS301P':
          // AML_실제소유자여부확인
          this.pPopup301Obj = pData
          this.$refs.popup301.fn_Open()
          break
        case 'MSPFS303P':
          // FATCA개인
          this.pPopup303Obj = pData
          this.pPopup303Obj.pNm = this.lv_ed_pop_rname_nm
          this.pPopup303Obj.pRno = this.lv_pop_cnf_no_encr
          this.$refs.popup303.fn_Open()
          break
        case 'MSPFS305P':
          // 고위험승인요청
          this.pPopup305Obj = pData
          this.$refs.popup305.fn_Open()
          break
        case 'MSPFS309P':
          // 세제상품전금융기관등록조회
          this.$refs.popup309.fn_openModal()
          break

        case 'MSPFS313P':
          // 업무대리인 조회 팝업
          this.pPopup313Obj = pData
          this.$refs.popup313.fn_Open()
          break
        case 'MSPFS315P':
          // 자금세탁(AML) 고객확인 재이행
          this.pPopup315Obj = pData
          this.$refs.popup315.fn_Open()
          break
        case 'MSPFS321P':
          // 직원검색 팝업
          this.pPopup321Obj = {
            subId: subId,
            pFund_type: this.lv_search_fund_info.bfcer_qlfd_typ_cd,
            pBfcer_fnd_cd: this.lv_bfcer_fnd_cd.value
          }
          this.$refs.popup321.fn_Open()
          break
        case 'MSPFS322P':
          // 지역단FP 팝업
          this.pPopup322Obj = {
            pFund_type: this.lv_search_fund_info.bfcer_qlfd_typ_cd,
            pBfcer_fnd_cd: this.lv_bfcer_fnd_cd.value
          }
          this.$refs.popup322.fn_Open()
          break
        case 'MSPFS323P':
          // FO직원 팝업
          this.pPopup323Obj = {
            subId: subId,
            pDsdomname: subId == 'FO' ? '168' : '',
            pFund_type: this.lv_search_fund_info.bfcer_qlfd_typ_cd,
            pBfcer_fnd_cd: this.lv_bfcer_fnd_cd.value
          }
          this.$refs.popup323.fn_Open()
          break
      }
    },
    /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type)
          0: 고객정보
          1: 펀드정보
          3: 상담텔러조회
          MSPFS301P: AML_실제소유자여부확인
          MSPFS303P: FATCA개인
          MSPFS305P: 고위험승인요청
          MSPFS309P: 세제상품전금융기관등록조회
          MSPFS313P: 업무대리인 조회
          MSPFS315P: 자금세탁(AML) 고객확인 재이행
          MSPFS321P: 직원검색 팝업
          MSPFS322P: 지역단FP 팝업
          MSPFS323P: FO/FP직원 팝업
       ******************************************************************************/
    fn_Popup_CallBack(type, pData, pSubId) {
      switch (type) {
        case 0:
          console.log('고객정보 callback...')
          // 고객번호 매핑 후 조회
          this.lv_ed_cust_no.value = pData
          this.fn_SearchValid({ key: 'v' }, 0)
          break
        case 1:
          console.log('펀드정보 callback...')
          // 펀드 데이터 매핑 후 펀드코드로 조회
          this.lv_select_fund_data = pData
          this.lv_bfcer_fnd_cd.value = pData.bfcer_fnd_cd
          this.fn_SearchValid({ key: 'v' }, 1)
          break
        case 3:
          console.log('상담텔러조회 callback...')
          // console.log(pData)
          this.lv_ed_inv_counsel.value = pData.staf_eno
          this.lv_ed_inv_counselnm.value = pData.bfcer_eply_nm
          break
        case 'MSPFS301P':
          console.log('AML실제소유자여부 callback...')
          // 고객번호 매핑 후 조회
          this.lv_reObjRtn = pData
          console.log(this.lv_reObjRtn)
          this.lv_ed_cust_no.value = pData.cust_no
          this.fn_SearchValid({ key: 'v' }, 0)
          break
        case 'MSPFS303P':
          console.log('FATCA개인 callback...')
          // this.$refs.alertPopup.fn_Close ();
          // 고객번호 매핑 후 조회
          this.lv_ed_cust_no.value = pData.cust_no
          this.fn_SearchValid({ key: 'v' }, 0)
          break
        case 'MSPFS305P':
          console.log('고위험승인요청 callback...')
          // 고객번호 매핑 후 조회
          // ASR240600395 고위험승인내역 화면 개선 요청
          this.lv_ed_cust_no.value = pData.acct_no
          this.fn_SearchValid({ key: 'v' }, 0)
          break
        case 'MSPFS315P':
          console.log('자금세탁(AML) 고객확인 재이행 callback...')

          // 고객번호 매핑 후 조회
          this.lv_reObjRtn = pData
          console.log(this.lv_reObjRtn)
          this.lv_ed_cust_no.value = pData.cust_no
          this.lv_reCall_yn = 'Y'
          this.fn_SearchValid({ key: 'v' }, 0)
          break
        case 'MSPFS321P':
          console.log('직원검색 팝업 callback...')
          console.log(pData)
          if (pSubId == 'SAVEL') {
            this.lv_ed_inv_empno.value = pData.staf_eno
            this.lv_ed_inv_empnm.value = pData.bfcer_eply_nm
            this.lv_submit_data.user_group_cd = pData.user_auth_grp_cd
            console.log(this.lv_submit_data)
          } else {
            let vUser_group_cd = pData.user_auth_grp_cd
            // 판매직원 사용자 그룹: FA(82), 영업점 텔러(19), FO(83)
            if (vUser_group_cd != '' && vUser_group_cd != null && vUser_group_cd != '82' && vUser_group_cd != '19' && vUser_group_cd != '83') {
              FSCommUtil.gfn_validate(this, '해당 직원은 판매직원 사용자 그룹이 아닙니다. \n다시 선택해주세요.')
              this.lv_ed_inv_seler.value = ''
              this.lv_ed_inv_selernm.value = ''
              // this.Div02.div_detail.Btn_seler_search.setFocus()
              return
            }
            this.lv_ed_inv_seler.value = pData.staf_eno
            this.lv_ed_inv_selernm.value = pData.bfcer_eply_nm
          }
          break
        case 'MSPFS322P':
          console.log('지역단FP 팝업 callback...')
          console.log(pData)
          this.lv_ed_inv_pm.value = pData.staf_eno
          this.lv_ed_inv_pmnm.value = pData.bfcer_eply_nm
          break

        case 'MSPFS323P':
          console.log('FO/FP직원 팝업 callback...')
          console.log(pData)
          if (pSubId == 'FO') {
            this.lv_ed_inv_fo.value = pData.staf_eno
            this.lv_ed_inv_fonm.value = pData.bfcer_eply_nm
          } else {
            this.lv_ed_inv_fp.value = pData.staf_eno
            this.lv_ed_inv_fpnm.value = pData.bfcer_eply_nm
          }
          break
        case 61:
          this.$refs.fsHeader.fn_GoHome()
          break
      }
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/
    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
     * Function명 : fn_CustInfo, fn_CustInfoResult
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log('fn_CustInfo.....')
      let t_data = { bfcer_cust_no: this.lv_ed_cust_no.value }
      FSInfoUtil.commInfo(this, 1, t_data, this.fn_CustInfoResult)
    },
    fn_CustInfoResult(pResultData) {
      console.log('fn_CustInfoResult.....')
      let t_data = pResultData.data.sy100500_O_00VO
      console.log(t_data)
      if (t_data.length > 0) {
        // 고객용 정보조회
        this.$bizUtil.fsUtils.selCustInfo(this, { bfcer_cust_no: this.lv_ed_cust_no.value })
        // 고객명
        this.lv_ed_cust_nm.value = t_data[0].fnd_cust_nm
        // 접근제한 고객일 경우 STOP?
        if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_ed_cust_nm.value))) {
          let t_popupObj = { content: '해당 고객은 접근제한 고객입니다.' }
          this.fn_AlertPopup(0, t_popupObj)
          return
        }
        // 조회/확인 버튼 활성화
        this.lv_search_btn = false
        this.lv_confirm_btn = false
        // 통합계좌여부와 매입가능 금액 조회
        this.fn_BaseInfoCallBack()
      } else {
        // 조회결과 없는 경우
        this.lv_ed_cust_no.value = ''
        this.lv_ed_cust_nm.value = ''
      }
    },
    /******************************************************************************
     * Function명 : fn_FundInfo, fn_FundInfoResult
     * 설명       : 펀드정보조회
     ******************************************************************************/
    fn_FundInfo() {
      console.log('fn_FundInfo.....')
      FSInfoUtil.gfn_fund_cd_search(this, this.lv_bfcer_fnd_cd.value, this.fn_FundInfoResult, '1')
    },
    async fn_FundInfoResult(pResultData) {
      console.log('fn_FundInfoResult.....')
      let t_data = pResultData.data.sy100100_O_00VO
      this.lv_search_fund_info = pResultData.data.sy100100_O_00VO[0]
      console.log(t_data)
      if (t_data.length > 0) {
        this.lv_bfcer_fnd_nm.value = t_data[0].bfcer_fnd_nm // 펀드명
        this.lv_fnd_grd.value = t_data[0].fnd_grd // 펀드등급
        // let vFund_prop_grade = t_data[0].fnd_grd                 // 펀드등급
        // let vColl_gb         = this.lv_select_fund_data.fnd_clct_sc_cd         // 모집구분(11: 공모펀드, 12: 사모펀드) COLL_GB
        // let vCdsc_gb         = this.lv_select_fund_data.cdsc_class_sc_cd       // CDSC클래스구분코드
        // let vFund_tp6        = this.lv_select_fund_data.bfcer_qlfd_typ_cd      // 수익증권자격유형코드 FUND_TP6 = '3'
        // let vFund_tp3        = this.lv_select_fund_data.fnd_ivt_obj_typ_cd     // 수익증권펀드투자대상유형코드 FUND_TP3= '6'
        let vColl_gb = t_data[0].fnd_clct_sc_cd // 모집구분(11: 공모펀드, 12: 사모펀드) COLL_GB
        let vCdsc_gb = t_data[0].cdsc_class_sc_cd // CDSC클래스구분코드
        let vFund_tp6 = t_data[0].bfcer_qlfd_typ_cd // 수익증권자격유형코드 FUND_TP6 = '3'
        let vFund_tp3 = t_data[0].fnd_ivt_obj_typ_cd // 수익증권펀드투자대상유형코드 FUND_TP3= '6'
        // 펀드코드 조회시 Clear
        this.fn_inv_emp_chk('KEY', '')
        // CDSC체크
        if (vCdsc_gb != '1' && FSCommUtil.gfn_isNull(vCdsc_gb) == false) {
          FSCommUtil.gfn_validate(this, '신규가입은 C1 클래스만 가능합니다.')
          this.lv_bfcer_fnd_cd.value = '' // 펀드코드
          this.lv_bfcer_fnd_nm.value = '' // 펀드명
          this.lv_fnd_grd.value = '' // 펀드등급
          this.lv_st_fund_grade = '' // 펀드등급TEXT
          return
        }

        // 모집구분(11: 공모펀드, 12: 사모펀드) COLL_GB
        if (vColl_gb == '11') {
          if (FSCommUtil.gfn_isNull(this.lv_fnd_grd.value) == true) {
            FSCommUtil.gfn_validate(this, '펀드등급이 등록되지 않아 가입 불가한 펀드입니다.')
            this.lv_bfcer_fnd_cd.value = '' // 펀드코드
            this.lv_bfcer_fnd_nm.value = '' // 펀드명
            this.lv_st_fund_grade = '' // 펀드등급TEXT
            return
          } else {
            this.lv_st_fund_grade = '' // 펀드등급TEXT
          }
        }
        // 펀드적합성 - 펀드등급 과 투자성향등급 으로 적합, 비적합 체크
        let vCust_grade = this.lv_sy1005_data.ivt_prpns_anly_grd_cd // 투자성향
        let avail_std_dt = this.lv_sy1005_data.avail_std_dt // 설문등록일(유효기간시작일)
        let vPro_investor_yn = this.lv_sy1005_data.tlgm_ivt_peson_yn // 전문투자자여부
        if (this.lv_fnd_grd.value != '') {
          // 전문투자는 펀드적합성 체크 안함
          if (vPro_investor_yn == 'Y') {
            this.lv_st_fund_grade = '' // 펀드등급TEXT
          } else {
            if (
              vCust_grade == 'A' || // 전체 적합
              (vCust_grade == 'B' && Number(this.lv_fnd_grd.value) >= 2) || // 1 등급 펀드만 비적합
              (vCust_grade == 'C' && Number(this.lv_fnd_grd.value) > 3)
            ) {
              // 1,2,3 등급 펀드 비적합
              this.lv_st_fund_grade = '적합상품' // 펀드등급TEXT
            } else if (
              vCust_grade == '1' || // 전체 적합
              (vCust_grade == '2' && Number(this.lv_fnd_grd.value) >= 2) || // vCust_grade < vFund_prop_grade 비적합
              (vCust_grade == '3' && Number(this.lv_fnd_grd.value) >= 3) ||
              (vCust_grade == '4' && Number(this.lv_fnd_grd.value) >= 4) ||
              (vCust_grade == '5' && Number(this.lv_fnd_grd.value) >= 5)
            ) {
              this.lv_st_fund_grade = '적합상품' // 펀드등급TEXT
            } else {
              // this.Div02.div_detail.St_fund_grade.style.set_color("#980000") //red
              this.lv_st_fund_grade = '비적합상품' // 펀드등급TEXT
              FSCommUtil.gfn_validate(this, '부적합투자자는 금융취약자로 분류되어 투자권유가 불가합니다.')
              // 확인 버튼 비활성화
              this.lv_confirm_btn = true
              return
            }
          }
        }

        if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) == false && FSCommUtil.gfn_isNull(this.lv_bfcer_fnd_cd.value) == false) {
          // if( vOld_yn == 'Y' ) {
          //   let vChkSell_yn =  this.getCustElsInfo_SY0037(this.Div02.div_detail.Ed_fund_cd.value)
          //   if(!vChkSell_yn) {
          //       this.Div00.div_btnList.btn_confirm.set_enable(false)
          //       this.gfn_getMessage("WARN","청약기간이 아닙니다")
          //       return
          //   }
          // }
          // let vChkEls_yn =  this.getCustElsInfo_SY0036(this.div_search.Me_cust_no.value
          //                                           , this.Div02.div_detail.Ed_fund_cd.value
          //                                           ,this.Div02.div_detail.St_fund_grade.text
          //                                           ,vOld_yn,rname_no_chk,rname_no_gb_chk
          //                                           ,"CU1010")
          // if(!vChkEls_yn) {
          //     this.Div00.div_btnList.btn_confirm.set_enable(false)
          //     this.gfn_getMessage("WARN","펀드적합성 팝업이 정상적으로 종료 되지 않았습니다.')
          //     return
          // }
        }
        // 정기지급형 펀드정보
        let vPay_yn = t_data[0].fprd_pym_btype_yn // 정기지급형여부
        // console.log(t_data[0].stnrd_pym_rat)
        // 세제유형/저축종류에 따른 필드 활성화
        this.fn_enable(this.lv_cob_tax_tp_gb.value, this.lv_cob_save_tp.value)
        if (vPay_yn == 'Y' && this.lv_cob_tax_tp_gb.value == '1') {
          // this.lv_static72 = true  // 표준지급율
          this.lv_static73 = true // 최저지급율
          this.lv_static73_text = t_data[0].stnrd_pym_rat // 최저지급율TEXT
        } else {
          // this.lv_static72 = false   // 표준지급율
          this.lv_static73 = false // 최저지급율
          if (vPay_yn == 'N') {
            if (this.lv_cob_tax_tp_gb.value == '1' || this.lv_cob_tax_tp_gb.value == '14') {
              FSCommUtil.gfn_validate(this, '정기지급형으로 가입이 불가능한 펀드 입니다.')
              this.lv_cob_tax_tp_gb.value = ''
              this.lv_cob_save_tp.value = ''

              //정기지급형 정보 enable 설정
              this.fn_CU1010_NonPayInitialize()
            }
          }
        }
        // late trading 익일 매수 여부
        let late_nbuy_yn = this.fn_isLateTrdYn(t_data[0].fndby_cncln_dds, t_data[0].today_setup_yn, t_data[0].bfcer_clms_dl_aplc_sc_cd, t_data[0].late_yn, t_data[0].coll_pass_yn)
        //통화코드
        this.lv_submit_data.currency_cd = t_data[0].phcl_cd
        //수익증권투자유형
        if (t_data[0].bfcer_fnd_ivt_typ_cd == '3' || t_data[0].bfcer_fnd_ivt_typ_cd == '4') {
          // 해외펀드여부
          this.lv_for_fund_yn = 'Y'
          //재투자여부
          this.lv_reinv_yn.value = 'N'
          this.lv_reinv_yn.disabled = true
        } else {
          // 해외펀드여부
          this.lv_for_fund_yn = 'N'
          //재투자여부
          this.lv_reinv_yn.value = 'Y'
          this.lv_reinv_yn.disabled = false
        }

        // 고령자 && 파생상품 && 모집방식이 공모 이면 고령자 유의상품 처리
        // vFund_tp6 = '3'
        // vColl_gb = '11'
        if (this.lv_old_yn == 'Y' && (vFund_tp6 == '3' || vFund_tp3 == '6') && vColl_gb == '11') {
          let vFnd_cd = this.lv_bfcer_fnd_cd.value
          let vChkYn = await FSBizUtil.gfn_agedPesonPrdtChk(this, this.lv_ed_cust_no.value, vFnd_cd, 'S', 'CU101000')

          if (vChkYn == 'N') {
            FSCommUtil.gfn_validate(this, '만 ' + this.lv_stdOldAge + '세 이상 고객대상 투자권유 유의상품 가입건으로 체크리스트 징구 대상자 입니다.')
            this.lv_confirm_btn = true
            vChkYn = await FSBizUtil.gfn_agedPesonPrdtChk(this, this.lv_ed_cust_no.value, vFnd_cd, 'I', 'CU101000')
          }

          // 에러 또는 센터장부재
          if (vChkYn == '' || vChkYn == '3') {
            this.lv_bfcer_fnd_cd.value = '' // 펀드코드
            this.lv_bfcer_fnd_nm.value = '' // 펀드명
            this.lv_fnd_grd.value = '' // 펀드등급
            this.lv_st_fund_grade = '' // 펀드등급TEXT
            return
          }
        }

        /* ASR201100735_그룹신규(#1010) 브릿지계좌신규 등 업무보완 (2020.12.22 이승하)
         * ㄴ펀드 정합성 체크 완료 후 고객번호, 고객명 둘 다 존재할 경우엔 확인버튼 활성화
         *   (부적합 펀드 -> 적합 펀드코드 재입력 시 확인 버튼 비활성 상태) */
        if (!FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) && !FSCommUtil.gfn_isNull(this.lv_ed_cust_nm.value)) {
          this.lv_confirm_btn = false
        }
      } else {
        // 펀드코드 clear
        this.lv_bfcer_fnd_cd.value = ''
      }
    },
    /**********************************************************************************
     * Function명 : inv_emp_chk
     * 설명       : 펀드코드 조회시 Clear
     **********************************************************************************/
    fn_inv_emp_chk(call_tp, param) {
      console.log('inv_emp_chk...')
      //권유자
      this.lv_ed_inv_empno.value = ''
      this.lv_ed_inv_empnm.value = ''
      //판매직원
      this.lv_ed_inv_seler.value = ''
      this.lv_ed_inv_selernm.value = ''
      //지역단FP
      this.lv_ed_inv_fp.value = ''
      this.lv_ed_inv_fpnm.value = ''
      //FP센터
      this.lv_ed_inv_pm.value = ''
      this.lv_ed_inv_pmnm.value = ''
      //FO
      this.lv_ed_inv_fo.value = ''
      this.lv_ed_inv_fonm.value = ''
    },
    /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       * 타입
          0: 고객번호
          1: 펀드코드
       ******************************************************************************/
    async fn_SearchValid(event, type, asgnPopChkYn = false) {
      console.log('fn_SearchValid.....')
      if (asgnPopChkYn) {
        this.coprtPesonAsgnPopChkYn = false
      }
      // 고객번호 입력
      if (type == 0) {
        if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7) {
          this.lv_ed_cust_no.error = false

          if (!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value)) {
            FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
            this.lv_ed_cust_no.error = true
            this.$refs['ed_cust_no'].focus()
            return
          } else {
            if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile) {
              let t_data = { bfcer_cust_no: this.lv_ed_cust_no.value }
              FSInfoUtil.commInfo(this, 1, t_data, this.fn_CustInfoListSearchResult)
              // 고령자정보조회
              if (FSCommUtil.gfn_isNull(this.coprtPesonAsgnPopChkYn) || !this.coprtPesonAsgnPopChkYn) {
                if ((await this.fn_after_check(1)) == false) {
                  return
                }
              }
              // 불공정영업행위 금지
              if ((await this.fn_after_check(0)) == false) {
                // 조회/확인 버튼 비활성화
                return
              }

              // 자금세탁방지 KYC RULE Chec
              if ((await this.fn_after_check(2)) == false) {
                return
              }
              // FATCA(해외금융계좌신고제도) Check
              if ((await this.fn_after_check(3)) == false) {
                return
              }
              // 신탁수탁 시스템 투자성향 설문 데이터 조회
              this.fn_InvestUserveyInfo()
              /**
               * 고령자 조력자 팝업은 조회버튼 을 눌렀을 경우 다시 처음처럼 떠야함
               * 위의 모든 로직이 종료되었으니 초기화 한다.
               */
              //this.coprtPesonAsgnPopChkYn = ''
            }
          }
        }
      }
      // 펀드코드 입력
      if (type == 1) {
        // 초기화
        this.lv_ed_cust_no.error = false
        this.lv_bfcer_fnd_cd.error = false
        this.lv_bfcer_fnd_nm.value = ''
        this.lv_fnd_grd.value = ''
        this.lv_st_fund_grade = ''
        this.lv_search_fund_info = {}
        this.fn_inv_emp_chk()
        if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value)) {
          FSCommUtil.gfn_validate(this, '고객번호를 입력해 주십시오.')
          this.lv_ed_cust_no.error = true
          // this.$refs['ed_cust_no'].focus()
          this.lv_bfcer_fnd_cd.value = ''
        }
        if (FSCommUtil.gfn_length(this.lv_bfcer_fnd_cd.value) == 6) {
          if (!FSCommUtil.gfn_isNum(this.lv_bfcer_fnd_cd.value)) {
            FSCommUtil.gfn_validate(this, '펀드코드 형식에 맞지 않습니다.')
            this.lv_bfcer_fnd_cd.error = true
            this.$refs['bfcer_fnd_cd'].focus()
            return
          } else {
            if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile) {
              // 펀드정보조회
              this.fn_FundInfo()
            }
          }
        }
      }
    },
    fn_CustInfoListSearchResult(pResultData) {
      console.log('fn_CustInfoListSearchResult.....')
      let t_data = pResultData.data.sy100500_O_00VO
      console.log(t_data)
      if (t_data.length > 0) {
        // 고객명
        this.lv_ed_pop_rname_nm = t_data[0].fnd_cust_nm
        // 실명번호정보 매핑
        this.lv_pop_cnf_no_encr = FSCommUtil.gfn_substr(t_data[0].rlnm_cnf_no_encr, 0, 6) + '-' + FSCommUtil.gfn_substr(t_data[0].rlnm_cnf_no_encr, 6, 1) + '******' //기존코드 사용 시, 고객번호로 조회하면 line1648 에서 실명번호가 빈값으로 되어 빈값 매핑됨
      }
    },
    /******************************************************************************
     * Function명 : fn_CoprtPesonAsgnCallBack
     * 설명       : 고령자 팝업에서 확인 후 호출할 콜백함수
     *              FSBizUtil.gfn_chkCoprtPesonAsgn_Yn 에서 호출
     ******************************************************************************/
    async fn_CoprtPesonAsgnCallBack() {
      // 자금세탁방지 KYC RULE Chec
      if ((await this.fn_after_check(2)) == false) {
        return
      }
      // FATCA(해외금융계좌신고제도) Check
      if ((await this.fn_after_check(3)) == false) {
        return
      }
      // 신탁수탁 시스템 투자성향 설문 데이터 조회
      this.fn_InvestUserveyInfo()
    },
    /******************************************************************************
       * Function명 : fn_after_check
       * type
          0: 불공정영업행위 금지
          1: 초고령자 체크 (만 80세 이상 초고령자 가입 불가)
          2: 자금세탁방지 (AML) KYC RULE Check
          3: FATCA(해외금융계좌신고제도) Check
       ******************************************************************************/
    async fn_after_check(type) {
      console.log('fn_after_check....')
      let t_popupObj = {}
      // 불공정영업행위 금지
      if (type == 0) {
        // 대출유무체크
        let lv_vLn_yn = await FSBizUtil.gfn_chkLnCont_Yn(this, this.lv_ed_cust_no.value, this.fn_AlertPopup)
        if (lv_vLn_yn != 'N') {
          return false
        }
      }
      // 초고령자 체크 (만 80세 이상 초고령자 가입 불가)
      if (type == 1) {
        let t_sy000500_data = await FSBizUtil.gfn_cust_no_sy000500(this, { bfcer_cust_no: this.lv_ed_cust_no.value }, this.fn_AlertPopup)
        // 고령자정보조회
        if (t_sy000500_data.isFlag == true) {
          console.log(t_sy000500_data)
          let t_rlnm_cnf_no_encr = FSCommUtil.gfn_trim(t_sy000500_data.rlnm_cnf_no_encr) // 실명번호
          let t_rlnm_no_knd_cd = FSCommUtil.gfn_trim(t_sy000500_data.rlnm_no_knd_cd) // 실명번호구분
          let t_fnd_cust_nm = FSCommUtil.gfn_trim(t_sy000500_data.fnd_cust_nm) // 고객명
          let t_coprt_peson_asgn_yn = FSCommUtil.gfn_trim(t_sy000500_data.coprt_peson_asgn_yn) // 조력자등록여부

          /* ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하)
              →만 80세 이상 초고령자 펀드 가입 불가 */
          this.lv_old_yn = await FSBizUtil.gfn_chkOldAge_Yn(this, t_rlnm_cnf_no_encr, t_rlnm_no_knd_cd, this.fn_AlertPopup, 'N')
          console.log('vOld_yn :: ' + this.lv_old_yn)
          if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_fnd_cust_nm))) {
            t_popupObj = {
              content: '해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다.'
            }
            this.fn_AlertPopup(0, t_popupObj)
            return false
          }
          // 고령자 조력자 체크
          let coprtPesonAsgnData = {
            cust_no: this.lv_ed_cust_no.value,
            cust_nm: t_fnd_cust_nm,
            old_yn: this.lv_old_yn,
            coprt_peson_asgn_yn: t_coprt_peson_asgn_yn,
            menu_id: 'MSPFS300M',
            coprt_peson_asgn_pop_chk_yn: this.coprtPesonAsgnPopChkYn
          }
          if (FSBizUtil.gfn_chkCoprtPesonAsgn_Yn(this, coprtPesonAsgnData, this.fn_AlertPopup) != true) {
            return false
          }
        } else {
          this.lv_ed_cust_no.value = ''
          return false
        }
      }
      // 자금세탁방지 (AML) KYC RULE Check
      if (type == 2) {
        // 실제소유자여부 당일진행여부 확인
        // vue 에서 팝업 호출 문제로 AML팝업 확인시 reObjRtn 값에 따라 처리되도록 추가
        if (FSCommUtil.gfn_isNull(this.lv_reObjRtn.cust_no) || this.lv_ed_cust_no.value != this.lv_reObjRtn.cust_no) {
          let t_real_owner_check = await FSAmlUtil.gfn_aml_real_owner_check(this, this.lv_ed_cust_no.value, this.fn_AlertPopup, this.fn_OpenPopup)
          if (t_real_owner_check != true) {
            return false
          }
        }
        // 자금세탁방지 및 테러 자금방저정책 ( AML ) 체크
        let aml_check_data = await FSAmlUtil.gfn_aml_check(this, this.lv_ed_cust_no.value, 'CU1010', '', this.lv_reCall_yn, this.lv_reObjRtn, this.fn_AlertPopup, this.fn_OpenPopup)
        if (aml_check_data != true) {
          return false
        }
      }
      // FATCA(해외금융계좌신고제도) Check
      if (type == 3) {
        let t_fatca_check_data = await FSFatcaUtil.fatca_check(this, this.lv_ed_cust_no.value, 'CU1010', this.fn_OpenPopup, this.fn_AlertPopup)
        if (t_fatca_check_data != true) {
          // if( FSCommUtil.gfn_isNull(this.lv_fatca_check_data.isFlag) ) {
          //   t_popupObj = {
          //     content: 'FATCA(해외금융계좌신고제도) 체크가 정상적으로 종료되지 않았습니다.'
          //   }
          //   this.fn_AlertPopup(0, t_popupObj)
          // }
          return false
        }
      }
      return true
    },
    /******************************************************************************
       * Function명 : fn_InvestUserveyInfo, fn_InvestUserveyInfoResult,
                      fn_InvestUserveyInfoCancel, fn_InvestUserveyInfoNetError
       * AS-IS      : fn_get_invest_uservey_info_slts()
       * 설명       : 신탁수탁 시스템 투자성향 설문 데이터 조회
       ******************************************************************************/
    fn_InvestUserveyInfo() {
      console.log('fn_InvestUserveyInfo...')
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570186_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.lv_ed_cust_no.value
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_InvestUserveyInfoResult)
    },
    fn_InvestUserveyInfoResult(pResultData) {
      console.log('fn_InvestUserveyInfoResult...')
      let t_data = pResultData.data
      console.log(t_data)
      // 신탁 투자성향 결과 매핑
      Object.assign(this.lv_tst_rctst_data, t_data)
      if (t_data.error_msg == '809900') {
        if (t_data.qstn_reg_systm_knd_sc_cd == '2' && !FSCommUtil.gfn_isNull(t_data.ivt_prpns_lst_chg_dtm) && !FSCommUtil.gfn_isNull(t_data.ivt_prpns_lst_chg_dtm)) {
          let t_popupObj = {
            cancel: true,
            closeFunc: this.fn_Sy1005CallBack,
            confirm: true,
            confirmFunc: this.fn_InvestUserveyInfoConfirm,
            content: "해당 고객이 [일반투자자]인 경우 '확인'을, \n\n[전문투자자]인 경우에는 '취소'를 누르세요."
          }
          this.fn_AlertPopup(0, t_popupObj)
        } else {
          // 고객투자 성향 등급 조회
          this.fn_Sy1005CallBack()
        }
      } else {
        this.fn_InvestUserveyInfoNetError()
      }
    },
    fn_InvestUserveyInfoConfirm() {
      console.log('fn_InvestUserveyInfoConfirm...')
      let t_popupObj = {
        cancel: true,
        closeFunc: this.fn_InvestUserveyInfoCancel,
        confirm: true,
        confirmFunc: this.fn_InsertInvestUserveyInfo,
        content: '신탁상품 가입시 등록한 설문이 있습니다. 동일한 설문으로 등록하시겠습니까?'
      }
      this.fn_AlertPopup(0, t_popupObj)
    },
    fn_InvestUserveyInfoCancel() {
      console.log('fn_InvestUserveyInfoCancel...')
      let t_popupObj = {
        content: '취소되었습니다. 기존 등록된 내용으로 조회합니다.'
      }
      this.fn_AlertPopup(0, t_popupObj)
      // 고객투자 성향 등급 조회
      this.fn_Sy1005CallBack()
    },
    fn_InvestUserveyInfoNetError() {
      console.log('fn_InvestUserveyInfoNetError...')
      let t_popupObj = {
        content: '타시스템(신탁수탁)과 통신 중 이상이 발생하였습니다.\n기존 수익증권시스템 등록 자료로 조회합니다.'
      }
      this.fn_AlertPopup(0, t_popupObj)
      // 고객투자 성향 등급 조회
      this.fn_Sy1005CallBack()
    },
    /******************************************************************************
     * Function명 : fn_Sy1005CallBack, fn_Sy1005CallBackResult
     * AS-IS      : fn_SY1005_call_back()
     * 설명       : 고객투자 성향 등급 조회
     ******************************************************************************/
    fn_Sy1005CallBack() {
      console.log('fn_Sy1005CallBack...')
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570187_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.lv_ed_cust_no.value
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_Sy1005CallBackResult)
    },
    async fn_Sy1005CallBackResult(pResultData) {
      console.log('fn_Sy1005CallBackResult...')
      let t_data = pResultData.data
      this.lv_sy1005_data = t_data.sy000700_O_00VO[0]
      console.log(t_data)
      if (t_data.error_msg == '809900') {
        let t_sy000700_data = t_data.sy000700_O_00VO[0]
        // 투자성향정보 확인
        // 1) 투자성향정보 없는 경우 설문등록 화면(MSPFS110M) 이동
        // 2) 투자성향정보 존재 하지만 당일정보가 아닌경우 설문등록 화면(MSPFS110M) 이동
        let cust_grade = t_sy000700_data.ivt_prpns_anly_grd_cd // 투자성향
        let avail_std_dt = t_sy000700_data.avail_std_dt // 설문등록일(유효기간시작일)
        let invest_inv_yn = t_sy000700_data.ivt_cnvs_hop_yn // 정보미제공/투자권유불원
        let pro_investor_yn = t_sy000700_data.tlgm_ivt_peson_yn // 전문투자자여부
        cust_grade = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(cust_grade)) ? '' : cust_grade
        avail_std_dt = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(avail_std_dt)) ? '' : avail_std_dt
        invest_inv_yn = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(invest_inv_yn)) ? '' : invest_inv_yn
        // 당일의 투자성향이 존해 하는지 체크 (없으면 설문등록 화면(MSPFS110M) 이동)
        if ((await FSFormUtil.gfn_chkInvest_servey(this, cust_grade, avail_std_dt, '', '', '', this.lv_ed_cust_no.value, this.fn_AlertPopup)) == true) {
          // 투자성향 매핑
          this.lv_ivt_prpns_grd_cd.value = cust_grade
          // 정보미제공/투자권유불원의 경우 확인서 징구 확인
          if (invest_inv_yn == 'N' && pro_investor_yn != 'Y') {
            let t_popupObj = { content: '부적합투자자는 금융취약자로 분류되어 투자권유가 불가합니다.' }
            this.fn_AlertPopup(0, t_popupObj)
            return
          }
          // 유효기간 표시
          if (FSCommUtil.gfn_length(t_sy000700_data.avail_std_dt) != 0) {
            let a_std_dt = t_sy000700_data.avail_std_dt
            let a_end_dt = t_sy000700_data.avail_end_dt
            this.lv_expd_ymd_text = FSCommUtil.gfn_dateReplace(2, a_std_dt) + ' ~ ' + FSCommUtil.gfn_dateReplace(2, a_end_dt)
          }
          // 고객정보조회
          this.fn_CustInfo()
        }
      } else {
        FSFormUtil.gfn_chkInvest_servey(this, '', '', '', '', '', this.lv_ed_cust_no.value, this.fn_AlertPopup)
      }
    },
    /******************************************************************************
     * Function명 : fn_InsertInvestUserveyInfo, fn_InsertInvestUserveyInfoResult
     * AS-IS      : fn_create_invest_uservey_info_by_slts()
     * 설명       : 신탁수탁 시스템 투자성향 설문 데이터로 설문등록
     ******************************************************************************/
    fn_InsertInvestUserveyInfo() {
      console.log('fn_InsertInvestUserveyInfo...')
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570166_S'
      this.eaiCommObj.params = this.lv_tst_rctst_data
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_InsertInvestUserveyInfoResult)
    },
    fn_InsertInvestUserveyInfoResult(pResultData) {
      console.log('fn_InsertInvestUserveyInfoResult...')
      let t_data = pResultData.data
      console.log(t_data)
      if (FSCommUtil.gfn_isNull(t_data.error_msg)) {
        let t_popupObj = {
          cancel: false,
          confirm: true,
          confirmFunc: this.fn_Sy1005CallBack,
          content: '정상적으로 등록되었습니다.\n등록된 내용으로 조회합니다.'
        }
        this.fn_AlertPopup(0, t_popupObj)
      }
    },
    fn_ClearTstRctstData() {
      console.log('fn_ClearTstRctstData....')
      // 신탁 투자성향 DATASET
      this.lv_tst_rctst_data = {
        rlnm_no_knd_cd: '',
        rlnm_cnf_no_encr: '',
        ivt_prpns_reg_sno: '',
        bfcer_cust_no: '',
        fnd_cust_nm: '',
        inqry_resp_rslt_val_01: '',
        inqry_resp_rslt_val_02: '',
        inqry_resp_rslt_val_03: '',
        inqry_resp_rslt_val_04: '',
        inqry_resp_rslt_val_05: '',
        inqry_resp_rslt_val_06: '',
        inqry_resp_rslt_val_07: '',
        inqry_resp_rslt_val_08: '',
        inqry_resp_rslt_val_09: '',
        inqry_resp_rslt_val_10: '',
        inqry_resp_rslt_val_11: '',
        inqry_resp_rslt_val_12: '',
        inqry_resp_rslt_val_13: '',
        inqry_resp_rslt_val_14: '',
        inqry_rslt_score: '',
        ivt_prpns_anly_grd_cd: '',
        drvpr_ivt_expr_mms: '',
        ivt_cnvs_hop_yn: '',
        qstn_expd_yys: '',
        expd_stnd_ymd: '',
        expd_exp_ymd: '',
        qstn_reg_systm_knd_sc_cd: '',
        tlgm_ivt_peson_yn: '',
        tlgm_ivt_peson_str_ymd: '',
        tlgm_ivt_peson_end_ymd: '',
        ivt_prpns_lst_chg_dtm: '',
        report_rlnm_cnf_no_encr: '',
        tday_dgns_cnt: '',
        sup_id: '',
        aprv_sno: '',
        thday_reqstn_rsn_cd: ''
      }
    },

    /******************************************************************************
     * Function명 : fn_BaseInfoCallBack, fn_BaseInfoCallBackResult
     * AS-IS      : fn_baseInfo_call_back()
     * 설명       : 통합계좌여부와 매입가능 금액 조회
     ******************************************************************************/
    fn_BaseInfoCallBack() {
      console.log('fn_BaseInfoCallBack...')
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570188_S'
      this.eaiCommObj.params = {
        cust_no: this.lv_ed_cust_no.value
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_BaseInfoCallBackResult)
    },
    fn_BaseInfoCallBackResult(pResultData) {
      console.log('fn_BaseInfoCallBackResult...')
      // ASR201100735_그룹신규(#1010) 브릿지계좌신규 등 업무보완 : 비밀번호 초기화 (2020.12.22 이승하) START
      if (!this.isMobile) {
        this.lv_ed_password.value = ''
        this.lv_ed_password2.value = ''
      } else {
        this.lv_isClear = !this.lv_isClear ? true : false
        this.lv_isClear2 = !this.lv_isClear2 ? true : false
      }
      let t_data = pResultData.data
      this.lv_base_info = t_data
      console.log(t_data)
      if (t_data.error_msg == '809900') {
        // 출금가능금액(매입가능금액)
        this.lv_me_buy_able_amt.value = Number(t_data.depo_paym_poss_amt)
        // 비밀번호 활성화
        this.lv_ed_password.disabled = false
        this.lv_ed_password2.disabled = false
        if (FSCommUtil.gfn_trim(t_data.unty_apamt_accn_yn) == 'Y') {
          // 브릿지계좌비밀번호TEXT
          this.lv_static_br = true
        } else {
          // 브릿지계좌비밀번호TEXT
          this.lv_static_br = false
        }
      } else {
        // 출금가능금액(매입가능금액)
        this.lv_me_buy_able_amt.value = 0
      }
    },
    /******************************************************************************
     * Function명 : fn_AgentCheck
     * 설명       : 대리인 만기체크
     ******************************************************************************/
    fn_AgentCheck() {
      console.log('fn_AgentCheck...')
      // 초기화
      this.lv_ed_cust_no.error = false
      if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value)) {
        FSCommUtil.gfn_validate(this, '고객번호가 입력되지 않았습니다.')
        this.lv_ed_cust_no.error = true
        this.$refs['ed_cust_no'].focus()
        this.lv_rdo_agnt_chk_yn.value = ''
        return
      } else if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7) {
        FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
        this.lv_ed_cust_no.error = true
        this.$refs['ed_cust_no'].focus()
        this.lv_rdo_agnt_chk_yn.value = ''
        return
      }
      let t_params = {
        // PO 호출 조회조건
        bfcer_cust_no: this.lv_ed_cust_no.value,
        tr_code: 'CU101000',
        // 추가정보
        menu_id: 'MSPFS300M',
        btn_id: 'lv_rdo_agnt_chk_yn'
      }
      FSInfoUtil.agentCheck(this, t_params, this.fn_AlertPopup)
    },
    /***************************************************************************************
     * Function명 : fn_pass_check
     * 설명       : 비밀번호값 비밀번호확인값 체크
     *****************************************************************************************/
    async fn_pass_check() {
      let rtn = await this.$commonUtil.comparePassword(this, this.lv_ed_password.value, this.lv_ed_password2.value)
      if (rtn !== 'Y') {
        return false
      }
      return true
    },
    /***************************************************************************************
     * Function명 : fn_enable
     * 설명       : 세제유형/저축종류에 따른 필드 활성화
     *****************************************************************************************/
    fn_enable(tax_tp_gb, save_tp) {
      console.log('fn_enable...')
      //연금개설방법
      this.lv_rad_pension_trans_yn.value = '11'
      this.lv_rad_pension_trans_yn.disabled = true
      //연급지급기간
      this.lv_ed_pension_paym_term.value = ''
      this.lv_ed_pension_paym_term.disabled = true
      //연금지급개시일
      this.lv_cal_pension_paym_start_dt.value = ''
      this.lv_cal_pension_paym_start_dt.disabled = true
      //연금지급주기
      this.lv_cob_pension_paym_cycle.value = ''
      this.lv_cob_pension_paym_cycle.disabled = true
      //월저축금
      this.lv_me_accum_amt.value = ''
      this.lv_me_accum_amt.disabled = true
      //저축기간
      this.lv_ed_save_term.value = ''
      this.lv_ed_save_term.disabled = true
      //납입방법
      this.lv_cob_paidin_gb.value = '01'
      this.lv_cob_paidin_gb.disabled = true
      //연금지급개시일_계산
      this.lv_st_paym_start_dt = ''
      //정기지급형여부
      let vPay_yn = this.lv_search_fund_info.fprd_pym_btype_yn
      //일반
      switch (tax_tp_gb) {
        case '0':
          //정기지급형 정보 enable 설정
          this.fn_CU1010_NonPayInitialize()
          if (this.lv_cob_save_tp.value == '12') {
            //저축종류
            FSCommUtil.gfn_validate(this, '일반형은 거치식이 불가능합니다.')
            this.lv_cob_save_tp.value = ''
          } else if (save_tp == '13') {
            //월저축금
            this.lv_me_accum_amt.value = ''
            this.lv_me_accum_amt.disabled = false
            //저축기간
            this.lv_ed_save_term.value = ''
            this.lv_ed_save_term.disabled = false
            //납입방법
            this.lv_cob_paidin_gb.value = ''
            this.lv_cob_paidin_gb.disabled = false
          } else if (save_tp == '14') {
            //저축기간
            this.lv_ed_save_term.value = ''
            this.lv_ed_save_term.disabled = false
          }
          break

        //일반(정기지급형)
        case '1':
          if (vPay_yn == 'N') {
            FSCommUtil.gfn_validate(this, '정기지급형으로 가입이 불가능한 펀드 입니다.')
            this.lv_cob_tax_tp_gb.value = ''
            this.lv_cob_save_tp.value = ''
            this.fn_CU1010_NonPayInitialize()
          }

          if (this.lv_cob_save_tp.value == '12' || this.lv_cob_save_tp.value == '11') {
            this.fn_CU1010_PayInitilize()
            //지급률
            this.lv_pay_rate.disabled = false
            //지급기간hidden
            this.lv_pay_term.value = '120'
            this.lv_pay_term.disabled = true
            this.lv_pay_term.visibled = false
            this.lv_pay_term_etc.value = ''
            this.lv_pay_term_etc.visibled = false
          } else if (this.lv_cob_save_tp.value == '13') {
            FSCommUtil.gfn_validate(this, '일반(정기지급형)의 적립식은 자유적립식만 가능합니다.')
            this.lv_cob_save_tp.value = ''

            this.fn_CU1010_NonPayInitialize()
          } else if (this.lv_cob_save_tp.value == '14') {
            this.fn_CU1010_PayInitilize()

            //적립식 일 경우 재설정
            //정기지급유형
            this.lv_pay_type.value = 'N'
            this.lv_pay_type.disabled = true
            //지급률
            this.lv_pay_rate.value = ''
            this.lv_pay_rate.disabled = true
            this.lv_pay_rate.visibled = false

            //회차지급금액
            this.lv_month_pay.value = ''
            this.lv_month_pay.visibled = false

            //지급기간hidden
            this.lv_pay_term.disabled = false
            this.lv_pay_term.visibled = true

            // 지급기간TEXT
            this.lv_static64.value = this.lv_pay_term.value
            this.lv_static64.visibled = true

            //지급기간
            this.lv_pay_term_etc.visibled = false

            if (save_tp == '14') {
              //저축종류
              this.lv_ed_save_term.value = ''
              this.lv_ed_save_term.disabled = false
            }
          }
          break
        //비과세종합저축
        case '4':
          this.fn_CU1010_NonPayInitialize()
          if (save_tp == '13') {
            //월저축금
            this.lv_me_accum_amt.value = ''
            this.lv_me_accum_amt.disabled = false
            //저축기간
            this.lv_ed_save_term.value = ''
            this.lv_ed_save_term.disabled = false
            //납입방법
            this.lv_cob_paidin_gb.value = '01'
            this.lv_cob_paidin_gb.disabled = false
          } else if (save_tp == '14') {
            //저축기간
            this.lv_ed_save_term.value = ''
            this.lv_ed_save_term.disabled = false
          }
          break
        //연금저축
        case '7':
          this.fn_CU1010_NonPayInitialize()
          // 연금저축의 경우 재투자만 가능
          if (this.lv_reinv_yn.value == 'N') {
            FSCommUtil.gfn_validate(this, '연금저축은 재투자만 가능합니다.')
            // 재투자여부
            this.lv_reinv_yn.value = 'Y'
          }
          //연금개설방법 '연금이전'만 가능
          this.lv_rad_pension_trans_yn.value = '21'
          this.lv_rad_pension_trans_yn.disabled = true
          //저축기간
          this.lv_ed_save_term.value = ''
          this.lv_ed_save_term.disabled = false
          //연급지급기간
          this.lv_ed_pension_paym_term.value = ''
          this.lv_ed_pension_paym_term.disabled = false
          //연금지급개시일
          this.lv_cal_pension_paym_start_dt.value = ''
          this.lv_cal_pension_paym_start_dt.disabled = false
          //연금지급주기
          this.lv_cob_pension_paym_cycle.value = '10'
          this.lv_cob_pension_paym_cycle.disabled = false
          if (save_tp == '11' || save_tp == '12' || save_tp == '13') {
            FSCommUtil.gfn_validate(this, '연금저축은 자유적립식만 가능합니다.')
            this.lv_cob_save_tp.value = '14'
          }
          break
        //비과세(정기지급형)
        case '14':
          if (vPay_yn == 'N') {
            FSCommUtil.gfn_validate(this, '정기지급형으로 가입이 불가능한 펀드 입니다.')
            this.lv_cob_tax_tp_gb.value = ''
            this.lv_cob_save_tp.value = ''
            this.fn_CU1010_NonPayInitialize()
          }
          if (this.lv_cob_save_tp.value == '12' || this.lv_cob_save_tp.value == '11') {
            this.fn_CU1010_PayInitilize()
            //지급률
            this.lv_pay_rate.disabled = false
            //지급기간hidden
            this.lv_pay_term.value = '120'
            this.lv_pay_term.disabled = false
            //지급기간
            this.pay_term_etc.value = ''
            this.pay_term_etc.visibled = false
          } else if (this.lv_cob_save_tp.value == '13' || this.lv_cob_save_tp.value == '14') {
            FSCommUtil.gfn_validate(this, '비과세(정기지급형)는 적립식이 불가능합니다.')
            this.lv_cob_save_tp.value = ''
            this.fn_CU1010_NonPayInitialize()
          }
          break
      }

      this.fn_limit_check()
    },
    /***************************************************************************************
     * Function명 : fn_CU1010_NonPayInitialize, fn_CU1010_PayInitilize
     * 설명       : 정기지급형 정보 enable / able 설정
     *****************************************************************************************/
    fn_CU1010_NonPayInitialize() {
      console.log('fn_CU1010_NonPayInitialize...')
      //정기지급유형
      this.lv_pay_type.value = 'Y'
      this.lv_pay_type.disabled = true
      this.lv_pay_type.visibled = true
      //지급률
      this.lv_pay_rate.value = ''
      this.lv_pay_rate.disabled = true
      this.lv_pay_rate.visibled = true

      //회차지급금액
      this.lv_month_pay.value = ''
      this.lv_month_pay.visibled = true
      //지급주기
      this.lv_pay_cycle.disabled = true
      this.lv_pay_cycle.visibled = true

      //표준지급율 표시설정
      this.lv_static72 = true
      this.lv_static73 = false
      this.lv_static73_text = ''
      //지급일자
      this.lv_pay_day.value = ''
      this.lv_pay_day.disabled = true
      this.lv_pay_day.visibled = true
      //지급계좌
      this.lv_pay_acct.disabled = true
      //타은행계좌
      this.lv_btrans_acct_no.value = ''
      this.lv_btrans_acct_no.disabled = true
      //거치기간
      this.lv_pay_late_month.value = ''
      this.lv_pay_late_month.disabled = true
      //지급기간
      this.lv_pay_term.value = '120'
      this.lv_pay_term.disabled = true
      this.lv_pay_term.visibled = false
      //지급기간hidden
      this.lv_pay_term_etc.value = ''
      this.lv_pay_term_etc.visibled = true

      //지급기간TEXT
      this.lv_static64.value = ''
      //SMS 발송서비스
      this.lv_sms_send.vale = ''
      this.lv_sms_send.disabled = true
      //거치기간(년)
      this.lv_pay_late_yy.value = ''
      this.lv_pay_late_yy.disabled = true
      this.lv_pay_late_yy.visibled = true
      //거치기간(월)
      this.lv_pay_late_mm.value = ''
      this.lv_pay_late_mm.disabled = true
      this.lv_pay_late_mm.visibled = true
      // ?
      this.lv_static53.value = ''
      this.lv_static53.visibled = false
      // ?
      this.lv_static55.value = ''
      this.lv_static55.visibled = false
      // ?
      this.lv_static56.value = ''
      this.lv_static56.visibled = false
      //지급개시일
      this.lv_paym_start_day.value = ''
      this.lv_paym_start_day.disabled = true
      this.lv_paym_start_day.visibled = true
      // this.lv_paym_start_day.set_text("")
      // this.lv_paym_start_day.set_style("color:#00D8FF") //darkslateblue
      //환매신청예정일
      this.lv_next_req_dt.value = ''
      this.lv_next_req_dt.visibled = true
      // this.Div02.div_detail01.next_req_dt.set_text("")
      // this.Div02.div_detail01.next_req_dt.set_style("color:#993800") //chocolate
    },
    fn_CU1010_PayInitilize() {
      console.log('fn_CU1010_PayInitilize...')
      //정기지급유형
      this.lv_pay_type.value = 'Y'
      this.lv_pay_type.disabled = false
      this.lv_pay_type.visibled = true
      //지급률
      this.lv_pay_rate.disabled = false
      this.lv_pay_rate.visibled = true
      //지급주기
      this.lv_pay_cycle.disabled = false
      this.lv_pay_cycle.visibled = true
      //지급일자
      this.lv_pay_day.disabled = false
      this.lv_pay_day.visibled = true
      //지급계좌
      this.lv_pay_acct.disabled = false
      //거치기간
      this.lv_pay_late_month.disabled = false
      //지급기간
      this.lv_pay_term.disabled = true
      this.lv_pay_term.visibled = true
      //표준지급율 표시설정
      let vPay_yn = this.lv_search_fund_info.fprd_pym_btype_yn //정기지급형여부
      if (vPay_yn == 'Y') {
        //표준지급율 표시설정
        this.lv_static72 = true
        this.lv_static73 = true
        this.lv_static73_text = this.lv_search_fund_info.lwst_pym_rat //최저지급율
      } else {
        this.lv_static72 = false
        this.lv_static73 = false
        this.lv_static73_text = ''
      }
      //SMS 발송서비스
      this.lv_sms_send.disabled = false
      //거치기간(년)
      this.lv_pay_late_yy.disabled = false
      this.lv_pay_late_yy.visibled = true
      //거치기간(월)
      this.lv_pay_late_mm.disabled = false
      this.lv_pay_late_mm.visibled = true
      //지급개시일
      this.lv_paym_start_day.visibled = true
      //환매신청예정일
      this.lv_next_req_dt.visibled = true
    },
    /***************************************************************************************
     * Function명 : fn_limit_check
     * 설명       : 세금우대 관련 한도조회 로직 체크
     *****************************************************************************************/
    fn_limit_check() {
      console.log('fn_limit_check...')
      let str_text = ''

      if (this.lv_gorp_gb == 'Y' || this.lv_for_fund_yn == 'Y') {
        if (this.lv_gorp_gb == 'Y') {
          str_text = '법인'
        } else if (this.lv_for_fund_yn == 'Y') {
          str_text = '해외펀드'
        }
        if (this.lv_cob_tax_tp_gb.value != '0' && this.lv_cob_tax_tp_gb.value != '16') {
          FSCommUtil.gfn_validate(this, str_text + '는 ' + this.lv_cob_tax_tp_gb.text + ' 가입이 불가 합니다.')
          //상품구분 초기화
          this.lv_cob_tax_tp_gb.value = ''
        }
        return false
      } else {
        //세제한도조회
        this.lv_me_join_limit_amt.value = ''
        this.lv_me_join_limit_amt.disabled = true
        this.lv_me_limit.value = ''
        if (this.lv_cob_tax_tp_gb.value != '0' && this.lv_cob_tax_tp_gb.value != '1') {
          this.lv_me_join_limit_amt.disabled = false
        }
      }
    },
    /**********************************************************************************
     * Function명 : fn_btn_limit, fn_call_back_prime
     * 설명       : 한도조회
     **********************************************************************************/
    fn_btn_limit() {
      console.log('fn_btn_limit...')
      // 초기화
      this.lv_ed_cust_no.error = false
      if (this.lv_inter_useyn != 'Y') {
        //세제한도조회
        this.lv_me_join_limit_amt.disabled = false
        this.$refs['me_join_limit_amt'].focus()
        return
      }

      if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) < 7) {
        FSCommUtil.gfn_validate(this, '고객번호가 입력되지 않았습니다.')
        this.lv_ed_cust_no.error = true
        this.$refs['ed_cust_no'].focus()
        return false
      }
      if (this.lv_cob_tax_tp_gb.value == '0' || this.lv_cob_tax_tp_gb.value == '5') {
        FSCommUtil.gfn_validate(this, '일반형, 분리과세는 세금한도 조회를 하실 수 없습니다')
        return false
      }

      //연금저축
      if ((this.lv_cob_tax_tp_gb.value == '7' || this.lv_cob_tax_tp_gb.value == '13') && this.lv_rad_pension_trans_yn.value == '21') {
        let lv_vm = this
        let t_popupObj = {
          content: '세제상품전금융기관등록조회 화면에서 최초가입일 정보를 확인하세요.',
          cancel: true,
          closeFunc: function() {
            FSCommUtil.gfn_validate(lv_vm, '세제상품전금융기관등록조회에서 조회 후 입력하세요.')
          },
          confirm: true,
          confirmFunc: function() {
            //프로세스에서 받아온 파라미터에 주민등록 번호가 있을 경우
            // if( !FSCommUtil.gfn_isNull(this.$route.params.ed_rname_no)){
            //   this.pPopup309Obj = {
            //     rlnm_cnf_no : this.,
            //   }
            // }
            lv_vm.pPopup309Obj.rlnm_cnf_no = lv_vm.$route.params.ed_rname_no
            lv_vm.fn_OpenPopup('MSPFS309P')
          }
        }
        this.fn_AlertPopup(0, t_popupObj)
        return false
      }
      // console.log('================== lv_basInfo')
      // console.log(this.lv_basInfo)
      // console.log(this.lv_userInfo)
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570189_S'
      this.eaiCommObj.params = {
        mgt_br: this.lv_basInfo.brcd,
        ms_use_yn: 'Y',
        order_type: this.lv_ed_order_type,
        sup_id: '',
        aprv_sno: '',
        tax_tp_gb: this.lv_cob_tax_tp_gb.value,
        cust_no: this.lv_ed_cust_no.value
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_prime)
    },
    fn_call_back_prime(pResultData) {
      console.log('fn_call_back_prime...')
      let t_data = pResultData.data
      console.log(t_data)
      if ((t_data.error_msg = '809900')) {
        this.lv_me_limit.value = this.$bizUtil.numberWithCommas(Number(t_data.eaf1FPC0051VO[0].nuse_lmt_amt))
        // ASR210500425_금융소득종합과세 대상자 조세특례 제한 관련 개발 요청
        // 금융소득종합과세 대상자 표시  2021.05.31.  NSO
        if (t_data.eaf1FPC0051VO[0].lowIncmrScNo == '1') {
          // 금융소득종합과세 여부
          this.lv_st_fin_income_tax = true

          // 비과세종합저축 가입 불가
          if (this.lv_cob_tax_tp_gb.value == '4') {
            FSCommUtil.gfn_validate(this, '직전 과세기간 금융소득종합과세 대상자로 가입 불가합니다.')
            // 확인버튼 비활성화
            this.lv_confirm_btn = true
            return
          }
        }
        this.lv_tax_prime = t_data.eaf1FPC0051VO[0]
      }
    },
    /**********************************************************************************
     * Function명 : fn_isLateTrdYn
     * 설명       : LATE 트레이딩 여부를 검증
     **********************************************************************************/
    fn_isLateTrdYn(vbuy_match_ndays, vToday_setup_yn, vLate_gb, vLate_yn, vColl_poss_yn) {
      console.log('fn_isLateTrdYn...')
      //해외펀드는 무조건 매입으로
      if (this.lv_for_fund_yn == 'Y') {
        this.lv_ed_order_type = 'buyorder'
        return this.lv_ed_order_type
      }
      if (vbuy_match_ndays == '1') {
        if (vToday_setup_yn == 'Y') {
          if (vLate_yn == 'Y') {
            if (vLate_gb == '2') {
              this.lv_ed_order_type = 'buyorder'
            } else {
              this.lv_ed_order_type = 'deposit'
            }
          } else {
            this.lv_ed_order_type = 'deposit'
          }
        } else {
          if (vLate_gb == '2') {
            if (vLate_yn == 'Y') {
              this.lv_ed_order_type = 'buyorder'
            } else {
              this.lv_ed_order_type = 'deposit'
            }
          } else {
            this.lv_ed_order_type = 'deposit'
          }
        }
      } else {
        this.lv_ed_order_type = 'buyorder'
      }
      //모집식은 항상 매입신청
      if (vColl_poss_yn == 'Y') {
        this.lv_ed_order_type = 'buyorder'
      }
      return this.lv_ed_order_type
    },
    /***************************************************************************************
     * Function명 : fn_ed_save_term
     * 설명       : 저축기간 입력
     *****************************************************************************************/
    fn_ed_save_term() {
      console.log('fn_ed_save_term...')
      if (FSCommUtil.gfn_isNull(this.lv_ed_save_term.value)) {
        this.lv_st_paym_start_dt = ''
      } else {
        if ((this.lv_cob_tax_tp_gb.value == '7' && this.lv_rad_pension_trans_yn.value == '21') || (this.lv_cob_tax_tp_gb.value == '13' && this.lv_rad_pension_trans_yn.value == '11')) {
          this.fn_calc_paym_start_dt()
        }
      }
    },
    /***************************************************************************************
     * Function명 : fn_calc_paym_start_dt, fn_call_back_paym_start
     * 설명       : 연금지급개시일 계산
     *****************************************************************************************/
    fn_calc_paym_start_dt() {
      console.log('fn_calc_paym_start_dt...')
      //초기화
      this.lv_ed_cust_no.error = false
      this.lv_bfcer_fnd_cd.error = false
      if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) < 7) {
        FSCommUtil.gfn_validate(this, '고객번호가 입력되지 않았습니다.')
        this.lv_ed_cust_no.error = true
        this.$refs['ed_cust_no'].focus()
        return false
      }
      let t_fnd_length = FSCommUtil.gfn_isNull(this.lv_basInfo.fndCdLen) ? 6 : this.lv_basInfo.fndCdLen
      if (FSCommUtil.gfn_length(this.lv_bfcer_fnd_cd.value) < t_fnd_length) {
        FSCommUtil.gfn_validate(this, '펀드코드가 입력되지 않았습니다.')
        this.lv_bfcer_fnd_cd.error = true
        this.$refs['bfcer_fnd_cd'].focus()
        return false
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570252_S'
      this.eaiCommObj.params = {
        cust_no: this.lv_ed_cust_no.value,
        fund_cd: this.lv_bfcer_fnd_cd.value,
        tax_tp_gb: this.lv_cob_tax_tp_gb.value,
        open_gb: this.lv_rad_pension_trans_yn.value,
        save_term: this.lv_ed_save_term.value
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_paym_start)
    },
    fn_call_back_paym_start(pResultData) {
      console.log('fn_call_back_paym_start...')
      let t_data = pResultData.data
      console.log(t_data)
      // 초기화
      this.lv_paym_start_dt = ''
      this.lv_st_paym_start_dt = ''
      if (t_data.error_msg == '809900') {
        this.lv_paym_start_dt = t_data.paym_start_dt
        this.lv_st_paym_start_dt = FSCommUtil.gfn_dateReplace(2, t_data.paym_start_dt)
      }
    },
    /***************************************************************************************
     * Function명 : fn_pay_type
     * 설명       : 정기지급유형 선택
     *****************************************************************************************/
    fn_pay_type() {
      console.log('fn_pay_type...')
      //지급률 선택시
      if (this.lv_pay_type.value == 'Y') {
        //지급률
        this.lv_pay_rate.value = ''
        this.lv_pay_rate.disabled = false
        this.lv_pay_rate.visibled = true
        //회차지급금액
        this.lv_month_pay.value = ''
        this.lv_month_pay.visibled = true
        //지급기간
        this.lv_pay_term_etc.value = ''
        this.lv_pay_term_etc.visibled = false
        //지급기간hidden
        this.lv_pay_term.value = '120'
        this.lv_pay_term.disabled = true
        this.lv_pay_term.visibled = false
        //지급기간TEXT
        this.lv_static64.value = ''
        this.lv_static64.visibled = false

        //표준지급율
        this.lv_static72 = true
        //최저지급율
        this.lv_static73 = true
      } else {
        //지급기간 선택시
        //지급률
        this.lv_pay_rate.value = ''
        this.lv_pay_rate.disabled = true
        this.lv_pay_rate.visibled = false
        //회차지급금액
        this.lv_month_pay.value = ''
        this.lv_month_pay.visibled = false
        //지급기간
        this.lv_pay_term_etc.value = ''
        this.lv_pay_term_etc.visibled = false

        //지급기간hidden
        this.lv_pay_term.value = '120'
        this.lv_pay_term.disabled = false
        this.lv_pay_term.visibled = true

        //지급기간TEXT
        this.lv_static64.value = this.pay_term.value
        this.lv_static64.visibled = true

        //표준지급율
        this.lv_static72 = false
        //최저지급율
        this.lv_static73 = false
      }
    },
    /***************************************************************************************
     * Function명 : fn_me_buy_prof_amt
     * 설명       : 매입신청액 입력
     *****************************************************************************************/
    fn_me_buy_prof_amt() {
      console.log('fn_me_buy_prof_amt...')
      //정기지급형여부
      let vPay_yn = this.lv_search_fund_info.fprd_pym_btype_yn
      let t_fnd_length = FSCommUtil.gfn_isNull(this.lv_basInfo.fndCdLen) ? 6 : this.lv_basInfo.fndCdLen
      if (vPay_yn == 'Y') {
        if (FSCommUtil.gfn_length(this.lv_bfcer_fnd_cd.value) != t_fnd_length) {
          FSCommUtil.gfn_validate(this, '펀드코드가 입력되지 않았습니다.')
          this.lv_pay_rate.value = ''
          this.$refs['bfcer_fnd_cd'].focus()
          return false
        }
      }
    },
    /***************************************************************************************
     * Function명 : fn_taskTime_chek
     * 설명       : 통장신규, 현금입금 업무시간 체크
     *****************************************************************************************/
    async fn_taskTime_chek() {
      console.log('fn_taskTime_chek...')
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570192_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.lv_ed_cust_no.value
      }
      let response = await FSServiceUtil.invokeInDirect(this.eaiCommObj)
      if (response && response.data) {
        let t_data = response.data
        console.log(t_data)
        if ((t_data.error_msg = '809900')) {
          this.lv_br_tr_from_tm = t_data.wnd_dl_str_tm
          this.lv_br_tr_to_tm = t_data.wnd_dl_end_tm
        }
      }
    },
    /***************************************************************************************
     * Function명 : fn_amt_check
     * 설명       : 총입금액대비 매입금액체크, 매입금액은 총입금액을 넘을 수 없다.
     *****************************************************************************************/
    fn_amt_check() {
      console.log('fn_amt_check...')

      let vTot_amt = this.lv_me_buy_able_amt.value // 매입가능금액
      let vBuy_prof_amt = FSCommUtil.gfn_isNull(this.lv_me_buy_prof_amt.value) ? 0 : Number(this.lv_me_buy_prof_amt.value) // 매입신청금액

      this.lv_me_buy_prof_amt.error = false
      if (FSCommUtil.gfn_isNull(this.lv_me_buy_prof_amt.value)) {
        FSCommUtil.gfn_validate(this, '매입신청금액을 입력하세요.')
        this.lv_me_buy_prof_amt.error = true
        this.$refs['me_buy_prof_amt'].focus()
        return false
      }

      if (vBuy_prof_amt > 0 && vTot_amt < vBuy_prof_amt) {
        FSCommUtil.gfn_validate(this, '매입신청금액이 매입가능금액 한도를 초과하였습니다.')
        this.lv_me_buy_prof_amt.error = true
        this.$refs['me_buy_prof_amt'].focus()
        return false
      } else {
        return true
      }
    },
    /***************************************************************************************
     * Function명 : fn_Confirm
     * 설명       : CONFIRM(확인)
     *****************************************************************************************/
    async fn_Confirm() {
      console.log('fn_Confirm...')
      // 필수값 체크
      if (this.fn_SubmitVaild() != true) {
        return
      }
      // 매입신청금액이 0보다 클시 RP2000 업무시간 체크
      let t_me_buy_prof_amt = FSCommUtil.gfn_isNull(this.lv_me_buy_prof_amt.value) ? 0 : Number(this.lv_me_buy_prof_amt.value) // 매입신청금액
      if (t_me_buy_prof_amt > 0) {
        // 현재 시간/분 변수
        let hhmm = FSCommUtil.gfn_substr(FSCommUtil.gfn_strTodayTime(), 8, 4)
        // 통장신규, 현금입금 업무시간 체크
        await this.fn_taskTime_chek()

        // console.log('통장신규, 현금입금 업무시간 체크...')
        // console.log(hhmm)
        // console.log(this.lv_br_tr_from_tm)
        // console.log(this.lv_br_tr_to_tm)
        if (hhmm < this.lv_br_tr_from_tm || hhmm > this.lv_br_tr_to_tm) {
          FSCommUtil.gfn_validate(this, '현금입금(RP2000) 업무시간이 아닙니다.')

          // 확인버튼 비활성화
          this.lv_confirm_btn = true
          return
        }
      }

      // 상품구분 - (구)연금저축
      if (this.lv_cob_tax_tp_gb.value == '7') {
        if (this.lv_base_info.unty_apamt_accn_yn === 'Y') {
          FSCommUtil.gfn_validate(this, '브릿지계좌가 있을 경우, (구)연금저축 상품은 그룹 개설이 안됩니다.\n계좌신규 화면에서 진행하세요.')
          this.lv_cob_tax_tp_gb.error = true
          return
        }
        //초기화
        this.lv_cal_oring_open_dt.error = false
        if (FSCommUtil.gfn_isNull(this.lv_cal_oring_open_dt.value.replace(/-/g, ''))) {
          FSCommUtil.gfn_validate(this, '최초가입일을 입력하십시오.')
          this.lv_cal_oring_open_dt.error = true
          return
        }

        // ASR210600032_세제상품 관련 정정 요청  2021.06.22.
        // 신연금 구분 기준일 변경 (20130401 → 20130301)
        if (Number(this.lv_cal_oring_open_dt.value.replace(/-/g, '')) - 20130301 >= 0) {
          FSCommUtil.gfn_validate(this, '최초가입일이 2013.03.01 이후 이면 연금계좌신규개설(#1011)에서만 처리가 가능합니다.')
          this.lv_cal_oring_open_dt.error = true
          return
        }
      }
      // 비적합상품 가입시 확인
      if (this.lv_st_fund_grade == '비적합상품') {
        FSCommUtil.gfn_validate(this, '투자자확인서 작성여부를 확인하기 바랍니다.')
      }
      // 커버발행 삭제 2023.11.22 공통커버발행삭제 로직 수정
      if (await FSInfoUtil.preCvrNoDel(this)) {
        if (this.lv_rad_proc_gb.value === '11') {
          if (!this.fn_CU1010_valueCheck()) {
            return
          } else {
            let t_popupObj = {
              confirm: true,
              confirmFunc: this.fn_Submit,
              content: [
                '매입가능금액 : ' + this.$bizUtil.numberWithCommas(Number(FSCommUtil.gfn_isNull_replace(this.lv_me_buy_able_amt.value, 0))) + ' 원',
                '매입신청금액 : ' + this.$bizUtil.numberWithCommas(Number(FSCommUtil.gfn_isNull_replace(this.lv_me_buy_prof_amt.value, 0))) + ' 원'
              ]
            }
            this.fn_AlertPopup(6, t_popupObj)
          }
        } else {
          this.fn_Submit()
        }
      }
    },
    /***************************************************************************************
     * Function명 : fn_CU1010_valueCheck
     * 설명       : validation check
     *****************************************************************************************/
    fn_CU1010_valueCheck() {
      console.log('fn_CU1010_valueCheck...')
      // 정기지급형 체크
      let vPay_yn = this.lv_search_fund_info.fprd_pym_btype_yn
      if (this.lv_cob_tax_tp_gb.value == '1' || this.lv_cob_tax_tp_gb.value == '14') {
        if (vPay_yn == 'N') {
          FSCommUtil.gfn_validate(this, '정기지급형 펀드가 아닌 상품은 거치식(정기지급형)에 가입 할 수 없습니다.')
          return false
        }
      }

      if (this.lv_cob_tax_tp_gb.value == '0') {
        if (this.lv_cob_save_tp.value == '12') {
          FSCommUtil.gfn_validate(this, '일반형은 거치식이 불가능합니다.')
          return false
        }
      } else if (this.lv_cob_tax_tp_gb.value == '7' || this.lv_cob_tax_tp_gb.value == '13') {
        // 연금저축은 재투자만 가능
        if (this.lv_reinv_yn.value == 'N') {
          FSCommUtil.gfn_validate(this, '연금저축은 재투자만 가능합니다.')
          this.lv_reinv_yn.value = 'Y'
          this.lv_submit_data.reinv_yn = this.lv_reinv_yn.value
        }

        if (this.lv_cob_save_tp.value != '14') {
          FSCommUtil.gfn_validate(this, '연금저축은 자유적립식만 가입가능합니다.')
          return false
        }
      }
      // 저축종류 체크
      if (this.lv_cob_save_tp.value == '13' || this.lv_cob_save_tp.value == '14') {
        if (this.lv_cob_save_tp.value == '13') {
          // 월(목표)저축금
          this.lv_me_accum_amt.error = false
          if (FSCommUtil.gfn_isNull(this.lv_me_accum_amt.value)) {
            FSCommUtil.gfn_validate(this, '목표 저축금을 입력하여 주십시오.')
            this.lv_me_accum_amt.error = true
            this.$refs['me_accum_amt'].focus()
            return false
          }
        }
        // 연금저축
        if ((this.lv_cob_tax_tp_gb.value == '7' || this.lv_cob_tax_tp_gb.value == '13') && this.lv_rad_pension_trans_yn.value == '21') {
          //연금저축이고 연금개설방법이 이전신규이면 저축기간 입력하지 않는다.
        } else {
          // 저축기간
          this.lv_ed_save_term.error = false
          if (FSCommUtil.gfn_isNull(this.lv_ed_save_term.value)) {
            FSCommUtil.gfn_validate(this, '저축기간을 입력하여 주십시오.')
            this.lv_ed_save_term.error = true
            this.$refs['ed_save_term'].focus()
            return false
          }
        }
      }
      // 비과세 삭제
      if (this.lv_for_fund_yn == 'Y') {
        if (this.lv_cob_save_tp.value == '11' || this.lv_cob_save_tp.value == '13') {
        } else {
          this.lv_commCode.code231.forEach((item) => {
            if (item.value == this.lv_cob_save_tp.value) {
              FSCommUtil.gfn_validate(this, '해외펀드는 ' + item.text + '으로 가입하실수 없습니다.')
            }
          })
          return false
        }
      }
      //일반형이 아닌 경우만 체크
      let strtext = ''
      if (this.lv_cob_tax_tp_gb.value === '2') {
        strtext = '세금우대'
      } else if (this.lv_cob_tax_tp_gb.value === '3') {
        strtext = '비과세'
      } else if (this.lv_cob_tax_tp_gb.value === '4') {
        strtext = '비과세종합저축'
      } else if (this.lv_cob_tax_tp_gb.value === '5') {
        strtext = '분리과세'
      } else if (this.lv_cob_tax_tp_gb.value === '6') {
        strtext = '장기주택마련저축'
      } else if (this.lv_cob_tax_tp_gb.value === '7') {
        strtext = '(구)연금저축'
      } else if (this.lv_cob_tax_tp_gb.value === '8') {
        strtext = '장기주식형펀드'
      } else if (this.lv_cob_tax_tp_gb.value === '9') {
        strtext = '장기회사채형펀드'
      } else if (this.lv_cob_tax_tp_gb.value === '11') {
        strtext = '재형저축(비과세)'
      } else if (this.lv_cob_tax_tp_gb.value === '13') {
        strtext = '연금저축'
      } else if (this.lv_cob_tax_tp_gb.value === '14') {
        strtext = '비과세(정기지급형)'
      } else if (this.lv_cob_tax_tp_gb.value === '15') {
        strtext = '소장펀드(소득공제 장기펀드)'
      }
      // 세금우대 관련 체크 일반형이 아닐경우만 (세금우대 비과세를 선택하였을 경우)
      if (this.lv_cob_tax_tp_gb.value !== '0' && this.lv_cob_tax_tp_gb.value !== '16') {
        if (this.lv_corp_yn == 'Y') {
          FSCommUtil.gfn_validate(this, '법인은 ' + strtext + '으로 가입하실수 없습니다.')
          return false
        }
        //해외 펀드인 경우는 세금 우대 불가임의식 정액 적립식
        if (this.lv_for_fund_yn == 'Y') {
          FSCommUtil.gfn_validate(this, '해외펀드는 ' + strtext + '으로 가입하실수 없습니다.')
          return false
        }
        //장마저축 삭제
        if (this.lv_cob_tax_tp_gb.value == '13') {
          if (this.lv_rad_pension_trans_yn.value == '11') {
            // 1-1.적립기간은 체크(5년이상 99년이하)
            this.lv_ed_save_term.error = false
            if (Number(FSCommUtil.gfn_isNull_replace(this.lv_ed_save_term.value, 0)) < 60) {
              FSCommUtil.gfn_validate(this, strtext + ' 의 적립기간은 5년(60월) 이상으로 입력하여 주십시오.')
              this.lv_ed_save_term.error = true
              return false
            } else if (Number(FSCommUtil.gfn_isNull_replace(this.lv_ed_save_term.value, 0)) > 1188) {
              FSCommUtil.gfn_validate(this, strtext + ' 의 적립기간은 99년(1188월)이하로 입력하여 주십시오.')
              this.lv_ed_save_term.error = true
              return false
            }

            // 1-2.적립기간은 체크(연단위 입력여부. 12의 배수 입력여부)
            let rest1 = Number(FSCommUtil.gfn_isNull_replace(this.lv_ed_save_term.value, 0)) % 12
            if (rest1 != 0) {
              FSCommUtil.gfn_validate(this, strtext + ' 의 적립기간은 연단위로 입력하여 주십시오.(12의배수)')
              this.lv_ed_save_term.error = true
              return false
            }
            // 2-1.연금지급기간 체크
            this.lv_ed_pension_paym_term.error = false
            if (Number(FSCommUtil.gfn_isNull_replace(this.lv_ed_pension_paym_term.value, 0)) < 120) {
              FSCommUtil.gfn_validate(this, '연금지급기간은 10년(120월) 이하로 처리할 수 없습니다.')
              this.lv_ed_pension_paym_term.error = true
              return false
              // 연금지급기간을 50년에서 99년으로 연장
            } else if (Number(FSCommUtil.gfn_isNull_replace(this.lv_ed_pension_paym_term.value, 0)) > 1188) {
              FSCommUtil.gfn_validate(this, '연금지급기간은 99년(1188월)이상으로 처리할 수 없습니다.')
              this.lv_ed_pension_paym_term.error = true
              return false
            }
            // 2-2.연금지급기간은 체크(연단위 입력여부. 12의 배수 입력여부)
            var rest2 = Number(FSCommUtil.gfn_isNull_replace(this.lv_ed_pension_paym_term.value, 0)) % 12
            if (rest2 != 0) {
              FSCommUtil.gfn_validate(this, '연금지급기간은 연단위로 입력하여 주십시오.(12의배수)')
              this.lv_ed_pension_paym_term.error = true
              return false
            }
            // 3.연금지급개시일 체크
            this.lv_cal_pension_paym_start_dt.error = false
            if (FSCommUtil.gfn_isNull(this.lv_cal_pension_paym_start_dt.value.replace(/-/g, ''))) {
              FSCommUtil.gfn_validate(this, '연금지급개시일을 입력하여 주십시오.')
              this.lv_cal_pension_paym_start_dt.error = true
              return false
            }

            if (Number(FSCommUtil.gfn_isNull_replace(this.lv_cal_pension_paym_start_dt.value.replace(/-/g, ''), 0)) - Number(FSCommUtil.gfn_strToday()) <= 0) {
              FSCommUtil.gfn_validate(this, '연금지급개시일이 현재일보다 이전 일 수 없습니다.')
              this.lv_cal_pension_paym_start_dt.error = true
              return false
            }

            if (Number(FSCommUtil.gfn_isNull_replace(this.lv_cal_pension_paym_start_dt.value.replace(/-/g, ''), 0)) - Number(this.lv_paym_start_dt) < 0) {
              FSCommUtil.gfn_validate(this, '연금지급개시일이 계산된 연금지급개시일보다 이전 일 수 없습니다.')
              this.lv_cal_pension_paym_start_dt.error = true
              return false
            }
          }

          // 4.연금지급주기
          this.lv_cob_pension_paym_cycle.error = false
          if (FSCommUtil.gfn_isNull(this.lv_cob_pension_paym_cycle.value)) {
            FSCommUtil.gfn_validate(this, '연금지급주기를 선택하세요.')
            this.lv_cob_pension_paym_cycle.error = true
            return false
          }
        }

        // 연금저축이고 연금개설방법이 이전신규
        if ((this.lv_cob_tax_tp_gb.value == '7' || this.lv_cob_tax_tp_gb.value == '13') && this.lv_rad_pension_trans_yn.value == '21') {
          // 세제한도조회
          this.lv_me_join_limit_amt.error = false
          if (Number(FSCommUtil.gfn_isNull_replace(this.lv_me_join_limit_amt.value, 0)) == 0) {
            FSCommUtil.gfn_validate(this, strtext + ' 가입한도금액을 입력하여 주십시요')
            this.lv_me_join_limit_amt.error = true
            this.$refs['me_join_limit_amt'].focus()
            return false
          } else if (Number(FSCommUtil.gfn_isNull_replace(this.lv_me_join_limit_amt.value, 0)) > 18000000) {
            // 은행연합회 확인하지 않으므로 강제 확인
            FSCommUtil.gfn_validate(this, strtext + ' 1800만원을 초과 하였습니다. 가입한도금액을 확인하여 주시기 바랍니다.')
            this.lv_me_join_limit_amt.error = true
            this.$refs['me_join_limit_amt'].focus()
            return false
          }
        } else {
          if (this.lv_cob_tax_tp_gb.value == '1' || this.lv_cob_tax_tp_gb.value == '5' || this.lv_cob_tax_tp_gb.value == '16') {
          } else {
            // 세제한도조회
            this.lv_me_join_limit_amt.error = false
            this.lv_me_limit.error = false
            if (Number(FSCommUtil.gfn_isNull_replace(this.lv_me_join_limit_amt.value, 0)) == 0) {
              FSCommUtil.gfn_validate(this, strtext + ' 가입한도금액을 입력하여 주십시요')
              this.lv_me_join_limit_amt.error = true
              this.$refs['me_join_limit_amt'].focus()
              return false
            } else {
              // 연합회 사용여부시만 사용함
              if (this.lv_inter_useyn == 'Y') {
                if (FSCommUtil.gfn_isNull(this.lv_tax_prime.lowIncmrScNo)) {
                  FSCommUtil.gfn_validate(this, '은행연합회 한도조회가 이루어지지 않았습니다. 한도조회를 한 후 등록이 가능합니다.')
                  return false
                }
                if (this.lv_tax_prime.rsp_cd == '401') {
                } else {
                  if (Number(FSCommUtil.gfn_isNull_replace(this.lv_me_limit.value, 0)) == 0) {
                    FSCommUtil.gfn_validate(this, '세제한도가 0원입니다. 신규가 불가능합니다.')
                    this.lv_me_limit.error = true
                    this.$refs['me_limit'].focus()
                    return false
                  }
                  if (Number(FSCommUtil.gfn_isNull_replace(this.lv_me_join_limit_amt.value, 0)) > Number(FSCommUtil.gfn_isNull_replace(this.lv_me_limit.value, 0))) {
                    FSCommUtil.gfn_validate(this, '세금우대 한도금액을 초과 하였습니다. 가입한도금액을 확인하여 주시기 바랍니다.')
                    this.lv_me_join_limit_amt.error = true
                    this.$refs['me_join_limit_amt'].focus()
                    return false
                  }
                }
              }
            }
          }
        }
        // 임의식
        if (this.lv_cob_save_tp.value == '11') {
          if (this.lv_cob_tax_tp_gb.value != '1' && this.lv_cob_tax_tp_gb.value != '14') {
            let amt = Number(FSCommUtil.gfn_isNull_replace(this.lv_me_buy_prof_amt.value, 0))
            if (amt == 0) {
              // 비과세종합저축
              this.lv_me_buy_prof_amt.error = false
              if (this.lv_cob_tax_tp_gb.value != '4' && this.lv_cob_tax_tp_gb.value != '17' && this.lv_cob_tax_tp_gb.value != '19') {
                FSCommUtil.gfn_validate(this, strtext + ' 매입신청금액을 입력하여 주십시요.')
                this.lv_me_buy_prof_amt.error = true
                this.$refs['me_buy_prof_amt'].focus()
                return false
              }
            }
          }
          // 거치식인경우 입금액이 같거나 커야 한다.
        } else if (this.lv_cob_save_tp.value == '12') {
          this.lv_me_buy_prof_amt.error = false
          if (this.lv_cob_tax_tp_gb.value != '1' && this.lv_cob_tax_tp_gb.value != '16') {
            let vchkAmt = Number(FSCommUtil.gfn_isNull_replace(this.lv_me_buy_prof_amt.value, 0))
            if (vchkAmt > Number(FSCommUtil.gfn_isNull_replace(this.lv_me_join_limit_amt.value, 0))) {
              FSCommUtil.gfn_validate(this, strtext + ' 세제한도 입력금액과 매입금액이 일치하지 않습니다.')
              this.lv_me_buy_prof_amt.error = true
              this.$refs['me_buy_prof_amt'].focus()
              return false
            }
          }

          // 정액적립식인 경우
        } else if (this.lv_cob_save_tp.value == '13') {
          if (this.lv_cob_tax_tp_gb.value != '5') {
            let vchkAmt =
              Number(FSCommUtil.gfn_isNull_replace(this.lv_me_accum_amt.value, 0)) *
              (Number(FSCommUtil.gfn_isNull_replace(this.lv_ed_save_term.value, 0)) / Number(FSCommUtil.gfn_isNull_replace(this.lv_cob_paidin_gb.value, 0)))
            this.lv_me_buy_prof_amt.error = false
            if (vchkAmt > Number(FSCommUtil.gfn_isNull_replace(this.lv_me_join_limit_amt.value, 0))) {
              FSCommUtil.gfn_validate(this, strtext + ' 세제한도 입력금액과 매입금액이 일치하지 않습니다.')
              this.lv_me_buy_prof_amt.error = true
              this.$refs['me_buy_prof_amt'].focus()
              return false
            }
          }

          // 자유적립식인경우 입금액이 한도보다 같거나 적다.
        } else if (this.lv_cob_save_tp.value == '14') {
          if ((this.lv_cob_tax_tp_gb.value == '7' || this.lv_cob_tax_tp_gb.value == '13') && this.lv_rad_pension_trans_yn.value == '21') {
            //연금저축(이전신규)는 0원신규만 가능
          } else {
            if (this.lv_cob_tax_tp_gb.value != 5) {
              //정기지급형이 아닐경우 추가 20110130 PJH
              if (this.lv_cob_tax_tp_gb.value != '1') {
                var vchkAmt = Number(FSCommUtil.gfn_isNull_replace(this.lv_me_buy_prof_amt.value, 0))

                this.lv_me_buy_prof_amt.error = false
                if (vchkAmt > Number(FSCommUtil.gfn_isNull_replace(this.lv_me_join_limit_amt.value, 0))) {
                  FSCommUtil.gfn_validate(this, strtext + ' 세제한도 입력금액과 매입금액이 일치하지 않습니다.')
                  this.lv_me_buy_prof_amt.error = true
                  this.$refs['me_buy_prof_amt'].focus()
                  return false
                }
              }
            }
          }
        }
        // ASR210500425_금융소득종합과세 대상자 조세특례 제한 관련 개발 요청
        // 비과세종합저축 0원 신규도 가능하도록 보완
        if (this.lv_cob_tax_tp_gb.value == '4') {
          //생계형은 0원신규 가능 -> 비과세종합저축으로 통합
        } else {
          // 매입신청금액
          let input_amt = Number(FSCommUtil.gfn_isNull_replace(this.lv_me_buy_prof_amt.value, 0))
          this.lv_me_buy_prof_amt.error = false
          if ((this.lv_cob_tax_tp_gb.value == '7' || this.lv_cob_tax_tp_gb.value == '13') && this.lv_rad_pension_trans_yn.value == '21') {
            if (input_amt > 0) {
              FSCommUtil.gfn_validate(this, strtext + ' 0원 신규만 가능합니다.')
              this.lv_me_buy_prof_amt.error = true
              this.$refs['me_buy_prof_amt'].focus()
              return false
            }
          } else {
            if (this.lv_cob_save_tp.value == '11' && (this.lv_cob_tax_tp_gb.value == '1' || this.lv_cob_tax_tp_gb.value == '14')) {
              //to_be 정기지급형, 임의식일 경우 체크안함
            } else {
              if (input_amt == 0) {
                FSCommUtil.gfn_validate(this, strtext + ' 0원 신규가 불가능합니다.')
                this.lv_me_buy_prof_amt.error = true
                this.$refs['me_buy_prof_amt'].focus()
                return false
              }
            }
          }
        }
      }
      // 매입신청금액 체크
      if (this.fn_amt_check() == false) {
        return false
      }
      return true
    },
    /******************************************************************************
     * Function명 : fn_SubmitVaild
     * 설명       : 필수값 검증
     ******************************************************************************/
    fn_SubmitVaild() {
      console.log('fn_SubmitVaild...')
      let t_return = true
      // gfn_dataSet 으로 생성된 객체만 추가
      let t_vaildList1 = [
        this.lv_ed_cust_no, // 고객번호
        this.lv_rdo_agnt_chk_yn, // 대리인여부
        this.lv_ed_password, // 비밀번호
        this.lv_ed_password2 // 비밀번호확인
      ]
      if (FSCommUtil.gfn_isValidList(this, t_vaildList1, t_return)) {
        document.querySelector('.mo-page__contents-wrapper').scrollTop = 0
        t_return = false
      }
      // 업무구분 '신규' 일떄만 체크
      if (this.lv_rad_proc_gb.value == '11') {
        let t_vaildList2 = [
          this.lv_bfcer_fnd_cd, // 펀드코드
          this.lv_cob_save_tp, // 저축종류
          this.lv_ed_inv_empno, // 권유자
          this.lv_ed_inv_seler, // 판매직원
          this.lv_me_buy_prof_amt // 매입신청액
        ]
        if (FSCommUtil.gfn_isValidList(this, t_vaildList2, t_return)) {
          t_return = false
        }
      }
      if (t_return == true) {
        // 고객번호
        if (FSCommUtil.gfn_isLength(this, this.lv_ed_cust_no, 7, '고객번호가 형식에 맞지 않습니다.')) {
          return false
        }
        // 비밀번호
        if (this.isMobile) {
          if (this.lv_masked_val.length !== 4) {
            FSCommUtil.gfn_validate(this, '비밀번호는 4자리의 숫자로 입력하여 주십시오.')
            return false
          }
        } else {
          if (FSCommUtil.gfn_isLength(this, this.lv_ed_password, 4, '비밀번호는 4자리의 숫자로 입력하여 주십시오.')) {
            return false
          }
        }
        // 비밀번호확인
        this.lv_ed_password2.error = false

        if (!this.isMobile) {
          if (this.lv_ed_password.value != this.lv_ed_password2.value) {
            //ody
            FSCommUtil.gfn_validate(this, '비밀번호가 일치하지 않습니다.')
            this.lv_ed_password2.value = ''
            this.lv_ed_password2.error = true
            return false
          }
        } else {
          if (this.lv_password_check === false) {
            FSCommUtil.gfn_validate(this, '비밀번호가 일치하지 않습니다.')
            return false
          }
        }
        // 업무구분 '신규' 일떄만 체크
        if (this.lv_rad_proc_gb.value == '11') {
          // 펀드코드
          let t_fnd_length = FSCommUtil.gfn_isNull(this.lv_basInfo.fndCdLen) ? 6 : this.lv_basInfo.fndCdLen
          if (FSCommUtil.gfn_isLength(this, this.lv_bfcer_fnd_cd, t_fnd_length, '펀드코드의 형식에 맞지 않습니다.')) {
            return false
          }
          // 권유자
          if (FSCommUtil.gfn_isMinLength(this, this.lv_ed_inv_empno, 5, '권유자 사번의 형식에 맞지 않습니다.')) {
            return false
          }
          // 판매자
          if (FSCommUtil.gfn_isLength(this, this.lv_ed_inv_seler, 5, '판매자 사번의 형식에 맞지 않습니다.')) {
            return false
          }
        }
      }
      return t_return
    },
    /***************************************************************************************
     * Function명 : fn_Submit, fn_SubmitResult
     * AS-IS      : CU1010_Call_Put
     * 설명       : 그룹신규등록
     *****************************************************************************************/
    fn_Submit() {
      console.log('fn_Submit...')
      // return
      // 확인버튼 비활성화
      this.lv_confirm_btn = true
      // 그룹신규등록 데이터 매핑
      this.fn_SetSubmitData()
      // 통장발급여부
      this.lv_submit_data.passbook_issue_yn = 'Y'

      // 투자성향일련번호
      if (Number(this.lv_sy1005_data.ivt_prpns_reg_sno) != 0) {
        this.lv_submit_data.grade_seq = this.lv_sy1005_data.ivt_prpns_reg_sno
      }

      // 펀드등급
      if (FSCommUtil.gfn_length(this.lv_fnd_grd.value) == 1) {
        this.lv_submit_data.fund_prop_grade = this.lv_fnd_grd.value
      }
      // 신규구분(11:일반, 21:이전신규)
      this.lv_submit_data.open_gb = this.lv_rad_pension_trans_yn.value
      // 정기지급형 계좌 발급 시 지급율 정보 셋팅
      if (this.lv_cob_tax_tp_gb.value == '1' || this.lv_cob_tax_tp_gb.value == '14') {
        // 정기지급유형
        this.lv_submit_data.pay_type = this.lv_pay_type.value

        if (this.lv_pay_type.value == 'Y') {
          this.lv_submit_data.pay_term = '' //지급기간
          this.lv_submit_data.pay_rate = this.lv_pay_rate.value //지급율
          this.lv_submit_data.month_pay = Math.ceil(this.lv_month_pay.value) //회차지급액
        } else {
          if (this.lv_pay_term.value == '999') {
            this.lv_submit_data.pay_term = this.lv_pay_term_etc.value
          } else {
            this.lv_submit_data.pay_term = this.lv_pay_term.value
          }
          this.lv_submit_data.pay_rate = '0'
          this.lv_submit_data.month_pay = '0'
        }
        this.lv_submit_data.pay_cycle = this.lv_pay_cycle.value //지급주기
        this.lv_submit_data.pay_day = this.lv_pay_day.value //지급일자
        this.lv_submit_data.pay_acct = this.lv_pay_acct.value //지급계좌(예수금/타행)
        // 타행이체 계좌번호(기능 삭제로 'N' 값으로만 세팅됨)
        // if( this.lv_pay_acct.value == 'Y' ) {
        // 약정계좌등록 시 계좌정보 셋팅
        // this.lv_pay_acct.btrans_acct_seq = this.ds_1010_seq_no.getColumn(this.Div02.div_detail01.btrans_acct_no.index, "seq_no")
        // } else {
        this.lv_submit_data.btrans_acct_seq = '0'
        // }

        // 거치기간
        this.lv_submit_data.pay_late_month = this.lv_pay_late_month.value

        let pay_start = 0
        let pay_req = 0

        pay_start = FSCommUtil.gfn_substr(this.lv_paym_start_day.value, 0, 4) + FSCommUtil.gfn_substr(this.lv_paym_start_day.value, 7, 2) + FSCommUtil.gfn_substr(this.lv_paym_start_day.value, 12, 2)
        pay_req = FSCommUtil.gfn_substr(this.lv_next_req_dt.value, 0, 4) + FSCommUtil.gfn_substr(this.lv_next_req_dt.value, 7, 2) + FSCommUtil.gfn_substr(this.lv_next_req_dt.value, 12, 2)

        this.lv_submit_data.paym_start_day = pay_start //지급개시일
        this.lv_submit_data.next_req_dt = pay_req //차기환매신청예정일
        // SMS 발송서비스(1: 매월잔고통보, 2: 지급결과통보)
        if (this.lv_sms_send.value == '1') {
          this.lv_submit_data.sms_remn = 'Y'
          this.lv_submit_data.sms_redem = 'N'
        } else if (this.lv_sms_send.value == '2') {
          this.lv_submit_data.sms_remn = 'N'
          this.lv_submit_data.sms_redem = 'Y'
        }
      }
      this.lv_submit_data.link_acct_yn = this.lv_base_info.unty_apamt_accn_yn == 'Y' ? 'N' : 'Y' // 가상계좌등록여부(브릿지계좌경우)
      this.lv_submit_data.secure_passv = this.lv_ed_password.value // 비밀번호
      this.lv_submit_data.secure_new_passv = this.lv_ed_password2.value // 비밀번호확인
      this.lv_submit_data.secure_encsymm = ''
      this.lv_submit_data.buy_prof_amt = Number(FSCommUtil.gfn_isNull_replace(this.lv_me_buy_prof_amt.value, 0)) // 매입신청액
      this.lv_submit_data.term_dept = this.lv_basInfo.brcd // 관리기관
      this.lv_submit_data.mgt_br = this.lv_basInfo.brcd // 예수금관리기관
      this.lv_submit_data.sup_id = '' // 승인자사번
      this.lv_submit_data.aprv_sno = '' // 결재번호
      this.lv_submit_data.tax_gb = this.lv_tax_gb // 과세구분(개인/법인)
      this.lv_submit_data.for_fund_yn = this.lv_for_fund_yn // 해외펀드여부
      this.lv_submit_data.depo_fund_cd = '100000' // 예수금펀드코드
      this.lv_submit_data.tel_use_yn = 'N' // 유무선구분
      // EAI(PO) 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570193_S'
      this.eaiCommObj.params = this.lv_submit_data
      // console.log('================================= 그룹신규등록 데이터 검증')
      // console.log(this.eaiCommObj.params)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SubmitResult)
    },
    async fn_SubmitResult(pResultData) {
      console.log('fn_SubmitResult...')
      const lv_vm = this
      let t_popupObj = {}
      let t_data = pResultData.data
      console.log(t_data)
      if (t_data.error_msg == '809901') {
        let t_message = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.message)) ? '' : FSCommUtil.gfn_trim(t_data.message)
        this.lv_ed_acct_no = t_data.bfcer_accn_no // 생성된 계좌번호 length:13
        // 확인버튼 비활성화
        this.lv_confirm_btn = true
        if (this.lv_rad_proc_gb.value != '22') {
          FSCommUtil.gfn_validate(this, '투자설명서를 출력(#4820)하여 고객교부 바랍니다.')
        }
        // 업무구분 체크(신규: 11, 브릿지계좌신규: 22)
        if (this.lv_rad_proc_gb.value == '22') {
          // 전자서식 출력(신규거래신청서)
          t_popupObj = {
            btnType: 1,
            cancel: true,
            closeFunc: function() {
              lv_vm.$router.push({
                name: 'MSPFS109M',
                params: {
                  rmvl_srn_id: 'CU101000',
                  bfcer_cust_no: lv_vm.lv_ed_cust_no.value
                }
              })
            },
            confirm: true,
            confirmFunc: function() {
              lv_vm.$router.push({
                name: 'MSPFS303M',
                params: {
                  rmvl_srn_id: 'CU101000',
                  ed_cust_no: lv_vm.lv_ed_cust_no.value,
                  ed_rname_no: lv_vm.$route.params.ed_rname_no
                }
              })
            },
            contentTitle: '그룹등록 완료',
            content: [t_message, '', '연금계좌신규개설 화면으로 이동하시겠습니까?']
          }
        } else {
          // 전자서식 출력
          await this.fn_ReportPrint(t_data)
          // 연금개설방법 이전신규인 경우
          if (this.lv_rad_pension_trans_yn.value == '21') {
            t_popupObj = {
              cancel: false,
              confirm: true,
              confirmFunc: function() {
                lv_vm.$router.push({
                  name: 'MSPFS304M',
                  params: {
                    rmvl_srn_id: 'CU101000',
                    // cust_no: lv_vm.lv_ed_acct_no,
                    cust_no: FSCommUtil.gfn_substr(lv_vm.lv_ed_acct_no, 0, 10),
                    acct_gb: FSCommUtil.gfn_substr(lv_vm.lv_ed_acct_no, 10, 3)
                  }
                })
              },
              contentTitle: '그룹등록 완료',
              content: [t_message, '', '연금이전신청 화면으로 이동합니다.']
            }
          } else {
            t_popupObj = {
              cancel: false,
              confirm: true,
              confirmFunc: function() {
                lv_vm.$router.push({
                  name: 'MSPFS109M',
                  params: {
                    rmvl_srn_id: 'CU101000',
                    bfcer_cust_no: lv_vm.lv_ed_cust_no.value
                  }
                })
              },
              contentTitle: '그룹등록 완료',
              content: [t_message, '', '인터넷펀드거래서비스신청 화면으로 이동합니다.']
            }
          }
        }
        // 안내 팝업
        this.fn_AlertPopup(6, t_popupObj)
      } else {
        // 에러메세지 팝업
        t_popupObj.content = FSCommUtil.gfn_isNum(t_data.error_msg) ? FSMessageUtil.commMessage([])[t_data.error_msg] : t_data.error_msg
        this.fn_AlertPopup(0, t_popupObj)
        // 확인버튼 활성화
        this.lv_confirm_btn = false
      }
    },
    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     * 파라미터   : 리턴 파라미터
     ******************************************************************************/
    fn_ReportPrint(pParam, nextFunc) {
      console.log('fn_ReportPrint....', pParam)
      // this.$bizUtil.fsUtils.saveCvrPblInfo(this, pParam)
      let t_data = pParam.tfoa01602_I_VO[0]
      // 조회조건
      let searchData = {
        bfcer_busn_trt_id: t_data.bfcer_busn_trt_id,
        bfcer_busn_trt_sc_cd: t_data.bfcer_busn_trt_sc_cd,
        bfcer_cust_no: t_data.bfcer_cust_no,
        frm_issnc_ymd: t_data.frm_issnc_ymd,
        issnc_hstr_sno: t_data.issnc_hstr_sno,
        rprt_frm_no: t_data.rprt_frm_no
      }

      let formList = []
      // 전자서명 동의서
      let FS000000 = { formId: 'FS000000' }
      FS000000.mappingData = {
        fund_nm: this.lv_bfcer_fnd_nm.value
      }
      formList.push(FS000000)
      let FS000004 = { formId: 'FS000004' } // FS000004_전자금융거래약관
      formList.push(FS000004)
      // 핵심설명서 및 확인서
      if (this.lv_fnd_grd.value == 1) {
        let FS000029 = { formId: 'FS000029' }
        FS000029.mappingData = {
          tstPrdtNm: this.lv_bfcer_fnd_nm.value
        }
        formList.push(FS000029)
      }
      if (this.lv_fnd_grd.value == 2) {
        let FS000030 = { formId: 'FS000030' }
        FS000030.mappingData = {
          tstPrdtNm: this.lv_bfcer_fnd_nm.value
        }
        formList.push(FS000030)
      }
      if (this.lv_fnd_grd.value == 3) {
        let FS000031 = { formId: 'FS000031' }
        FS000031.mappingData = {
          tstPrdtNm: this.lv_bfcer_fnd_nm.value
        }
        formList.push(FS000031)
      }
      if (this.lv_fnd_grd.value == 4) {
        let FS000032 = { formId: 'FS000032' }
        FS000032.mappingData = {
          tstPrdtNm: this.lv_bfcer_fnd_nm.value
        }
        formList.push(FS000032)
      }
      if (this.lv_fnd_grd.value == 5) {
        let FS000033 = { formId: 'FS000033' }
        FS000033.mappingData = {
          tstPrdtNm: this.lv_bfcer_fnd_nm.value
        }
        formList.push(FS000033)
      }
      if (this.lv_fnd_grd.value == 6) {
        let FS000034 = { formId: 'FS000034' }
        FS000034.mappingData = {
          tstPrdtNm: this.lv_bfcer_fnd_nm.value
        }
        formList.push(FS000034)
      }
      // 투자상품판매체크리스트
      let FS000011 = { formId: 'FS000011' }
      FS000011.mappingData = {
        plzaNm: this.lv_basInfo.brNm // 플라자명
      }
      formList.push(FS000011)
      // 수익증권저축약관
      let FS000035 = { formId: 'FS000035' }
      formList.push(FS000035)
      // 연금저축
      if (this.lv_cob_tax_tp_gb.value == '7' || this.lv_cob_tax_tp_gb.value == '13') {
        if (Number(this.lv_me_buy_prof_amt.value) < 1) {
          // 매입금액 없을 경우 연금저축계좌설졍약관
          let FS000036 = { formId: 'FS000036' }
          formList.push(FS000036)
          // 연금계좌 신규거래신청서
          let FS000019 = { formId: 'FS000019' }

          FS000019.params = {
            reportParam: JSON.stringify(searchData),
            // reportUrl: 'reportCU101000_Info0057',
            reportUrl: 'reportCO070500_Info'
          }
          FS000019.mappingData = {
            inv_empno: this.lv_ed_inv_empno.value
          }
          formList.push(FS000019)
        }
        // 그 외 일반
      } else {
        // ASR240800130 / 사랑온 태블릿서식 변경 1종(연금저축핵심설명서 ) 및 업무 로직 수정
        // 신규거래신청서
        let FS000002 = { formId: 'FS000002' }
        FS000002.params = {
          reportParam: JSON.stringify(searchData),
          reportUrl: 'reportCO070500_Info'
        }
        formList.push(FS000002)

        if (Number(this.lv_me_buy_prof_amt.value) > 0) {
          // 매입금액 없을 경우 매입환매전환 신청서
          let FS000014 = { formId: 'FS000014' }
          FS000014.params = {
            reportParam: JSON.stringify(searchData),
            reportUrl: 'reportCO070500_Info'
          }
          formList.push(FS000014)
        }
      }
      FSInfoUtil.commReportInfo(this, formList, nextFunc)
    },
    /******************************************************************************
     * Function명 : fn_SetSubmitData
     * 설명       : 그룹신규등록 데이터 매핑
     ******************************************************************************/
    fn_SetSubmitData() {
      console.log('fn_SetSubmitData...')
      // 검색조건
      this.lv_submit_data.cust_no = this.lv_ed_cust_no.value //고객번호
      this.lv_submit_data.new_proc_gb = this.lv_rad_proc_gb.value //업무구분
      this.lv_submit_data.ob_yn = 'N' //O/B구분
      // 계좌기본사항
      this.lv_submit_data.reinv_yn = this.lv_reinv_yn.value //재투자여부
      this.lv_submit_data.fund_cd = this.lv_bfcer_fnd_cd.value //펀드코드
      this.lv_submit_data.tax_tp_gb = this.lv_cob_tax_tp_gb.value //상품구분
      this.lv_submit_data.open_gb = this.lv_rad_pension_trans_yn.value //연금개설방법
      this.lv_submit_data.join_limit_amt = this.lv_me_join_limit_amt.value //세제한도
      this.lv_submit_data.save_tp = this.lv_cob_save_tp.value //저축종류
      this.lv_submit_data.accum_amt = this.lv_me_accum_amt.value //월(목표)저축금
      this.lv_submit_data.save_term = this.lv_ed_save_term.value //저축기간
      this.lv_submit_data.paidin_gb = this.lv_cob_paidin_gb.value //납입방법
      this.lv_submit_data.inv_empno = this.lv_ed_inv_empno.value //권유자
      this.lv_submit_data.pension_paym_open_dt = this.lv_cal_pension_paym_start_dt.value.replace(/-/g, '') //연금지급개시일
      this.lv_submit_data.inv_seler = this.lv_ed_inv_seler.value //판매직원
      this.lv_submit_data.pension_paym_term = this.lv_ed_pension_paym_term.value //연금지급기간
      this.lv_submit_data.inv_counsel = this.lv_ed_inv_counsel.value //상담텔러
      this.lv_submit_data.pension_paym_cycle = this.lv_cob_pension_paym_cycle.value //연금지급주기
      this.lv_submit_data.inv_pm = this.lv_ed_inv_pm.value //지역단FP
      this.lv_submit_data.oring_open_dt = this.lv_cal_oring_open_dt.value.replace(/-/g, '') //최초가입일
      this.lv_submit_data.inv_fp = this.lv_ed_inv_fp.value //FP센터
      this.lv_submit_data.inv_fo = this.lv_ed_inv_fo.value //FO센터
      // 정기잔고증명 신청사항(삭제)
      this.lv_submit_data.reg_remn_pdoc_yn = this.lv_reg_remn_pdoc_yn
      this.lv_submit_data.reg_remn_pdoc_dd = this.lv_ed_reg_remn_pdoc_dd
      this.lv_submit_data.issue_qty = this.lv_ed_issue_qty
      this.lv_submit_data.issue_use = this.lv_ed_issue_use
      this.lv_submit_data.estm_base = this.lv_cob_estm_base
      this.lv_submit_data.reg_remn_no_remn_qty_yn = this.lv_rad_no_remn_qty_yn
      // 매수구분
      this.lv_submit_data.order_type = this.lv_ed_order_type
      // 우편물수령지구분
      this.lv_submit_data.mail_recv_gb = '19'
    },
    fn_Cust316p(custInfo) {
      this.pCust316Obj.cust_no = custInfo.cust_no
      this.pCust316Obj.cust_nm = custInfo.cust_nm
      this.$refs.popup316.fn_Open()
    }
  }
}
</script>
<style scoped></style>
