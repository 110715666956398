/*
 * 업무구분: 수익증권>자금세탁(AML)고객확인재이행_P
 * 화 면 명: MSPFS315P
 * 화면설명: 자금세탁(AML)고객확인재이행 POPUP
 * 작 성 일: 2023.05.12
 * 작 성 자: 이태흥
 */

<template>

    <mo-modal class="fts-modal medium" ref="modal" title="자금세탁(AML) 고객확인 재이행">
      <template>
        <div class="wrap-modalcontents">
          <span>KYC 확인작업이 필요합니다. 아래 항목을 입력하고 확인버튼을 눌러주세요.</span>
          <div class="wrap-table mt6">
            <table class="table col-type">
              <tbody>
                <tr>
                  <td colspan="2" class="pr20">
                    <div class="wrap-input row w100p justify-end">
                      <!-- 유선:비활성화/내점:고정 2023-07-31 정지윤 -->
                      <mo-radio-wrapper 
                        :items="rad_ars_gb_radio" 
                        v-model="lv_rad_ars_gb.value"
                        class="row"
                        :class="lv_rad_ars_gb.error ? 'error' : ''"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
                <tr class="h44">
                  <th class="w124">고객구분</th>
                  <td> 개인 </td>
                </tr>
              </tbody>
            </table>
          </div>

          <span>실제소유자 여부를 선택하시기 바랍니다.</span>
          <div class="wrap-table mt6">
            <table class="table col-type mb0">
              <tbody>
                <tr>
                  <th class="w124">실제소유자여부</th>
                  <td>
                    <div class="wrap-input row w100p">
                      <mo-radio-wrapper 
                        :items="real_owner_gb_radio" 
                        v-model="lv_real_owner_gb.value" 
                        class="row"
                        :class="lv_real_owner_gb.error ? 'error' : ''"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="wrap-desc mt16 bb0">
            <p>실제 소유자 확인 후 추가로 고객 정보 재확인(#1165) 부탁드립니다.</p>
            <p>법인인 경우 고객확인 재이행(일반 3년, 고위험 1년) 도래시 유선처리가 불가능합니다.<br/>내방 후 처리해주시기 바랍니다.</p>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_Confirm()">재이행</mo-button>
        </div>
      </template>

      <!-- alert modal popup -->
      <fs-alert-popup 
        ref="alertPopup"
        :popupObj="pAlertPopupObj"
      ></fs-alert-popup>
        
    </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS315P",
    screenId: "MSPFS315P",
    props: {
        popupObj: {type:Object, default:null},
    },
    components: {
      'fs-alert-popup': FSAlertPopup,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SetCommCode();
    },
    mounted() {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pAlertPopupObj: {},
        lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
        // 공통 객체
        lv_commCode: {},

        // 입력사항
        lv_rad_ars_gb: {},      // 유선/내점구분
        lv_corp_gb: {},         // 개인/법인구분
        lv_real_owner_gb: {},   // 실제소유자구분
        // 서식용
        lv_real_owner_nm: {},   // 실제소유자명
        lv_rname_no: {},        // 실제소유자실명번호(개인일떄만 필요)
        lv_rname_no2: {},       // 실제소유자실명번호(개인일떄만 필요)
        lv_ctzn_natn_cd: {},    // 실제소유자국적

        // submit data
        lv_AmlResultData: {},

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      
      modal() {
        return this.$refs.modal
      },

      rad_ars_gb_radio() {
        let rtn= []
        rtn.push({value: 'Y', text: '유선'})
        rtn.push({value: 'N', text: '내점'})
        return rtn
      },

      real_owner_gb_radio() {
        let rtn= []
        rtn.push({value: 'Y', text: '예'})
        rtn.push({value: 'N', text: '아니오'})
        return rtn
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
      // 실제소유자여부 선택
      'lv_real_owner_gb.value': {
        handler(after, before) {
          if( after == 'N' ) {
            let t_popupObj = {content: "개인인 경우 실소유자 여부를 '아니오'를 선택 하실 수 없습니다."}
            this.fn_AlertPopup(0, t_popupObj)

            this.lv_real_owner_gb.value = ''
          }
        },
        deep: true
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_Open() {
        this.fn_Init()

        // 전자서식장표 출력 추가구현 필요

        this.modal.open()
      },
      fn_Close() {
        this.modal.close()
      },

      fn_Confirm() {
        if( this.fn_ValidateForm() == false ) {
          return
        }

        // 전자서식 호출
        this.fn_ReportPrint()

        this.lv_AmlResultData = {
          cust_no: this.popupObj.cust_no, // 고객번호 추가
          acct_no: this.popupObj.acct_no, // 계좌번호 추가
          trd_amt: '0',
          corp_gb: this.lv_corp_gb.value,
          real_owner_gb: this.lv_real_owner_gb.value,
          real_owner_nm: '',
          rname_no: '',
          ctzn_natn_cd: '',
        }

        this.$emit('fs-popup-callback', 'MSPFS315P', this.lv_AmlResultData)
        this.modal.close()
      },

      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS315P] fn_Init...')

        // 입력사항
        this.lv_rad_ars_gb          = FSCommUtil.gfn_dataSet(0, 'N')   // 유선/내점구분 <!-- 유선:비활성화/내점:고정 2023-07-31 정지윤 -->
        this.lv_corp_gb             = FSCommUtil.gfn_dataSet(0, 'N')  // 개인/법인구분
        this.lv_real_owner_gb       = FSCommUtil.gfn_dataSet(0, '')   // 실제소유자구분
        this.lv_real_owner_nm       = FSCommUtil.gfn_dataSet(0, '', false, '', 'real_owner_nm') // 실제소유자명
        this.lv_rname_no            = FSCommUtil.gfn_dataSet(0, '', false, '', 'rname_no')      // 실제소유자실명번호(개인일떄만 필요)
        this.lv_rname_no2           = FSCommUtil.gfn_dataSet(0, '', false, '', 'rname_no2')     // 실제소유자실명번호(개인일떄만 필요)        
        this.lv_ctzn_natn_cd        = FSCommUtil.gfn_dataSet(0, '', false, '')                  // 실제소유자국적

        // submit data
        this.lv_AmlResultData     = {}

      },


      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },


      /******************************************************************************
       * Function명 : fn_ValidateForm
       * 설명       : 필수값 체크
       ******************************************************************************/
      fn_ValidateForm() {
        console.log('fn_ValidateForm...')

        let t_return = true

        let t_vaildList1 = [
          this.lv_rad_ars_gb,
          this.lv_real_owner_gb
        ]

        if( FSCommUtil.gfn_isValidList(this, t_vaildList1, t_return) ) {
          t_return = false
        }
        
        return t_return
      },


      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        console.log('fn_ReportPrint....')

        let formList = []
        let FS000023 = {formId: 'FS000023'}
        let nationality = ''
        let selectCd = this.lv_ctzn_natn_cd.value

        this.lv_commCode.code981.forEach(function(item) {
          if( item.value == selectCd ) {
            nationality = item.text
          }
        })

        FS000023.mappingData = {
          realOwnerGb         : this.lv_real_owner_gb.value == 'Y' ? '1' : '2',   //소유자여부 체크박스
          realOwnerNm         : this.lv_real_owner_nm.value,                      //확인사항 성명
          realOwnerRnameNo    : this.lv_rname_no.value + this.lv_rname_no2.value, //확인사항 실명번호
          nationality         : nationality,                                      //확인사항 국적
          fpNm                : this.lv_basInfo.userNm,                           //처리자명
        }
        formList.push(FS000023)

        FSInfoUtil.commReportInfo(this, formList, '', false, 'MSPFS315P')
        // FSInfoUtil.commReportInfo(this, formList)
      },

      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = [
          {'cId':'981', 'dayChkYn':'N'},
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },
      fn_SetCommCodeResult(pResult) {
          this.lv_commCode = pResult
      },


    }
  }
</script>
<style scoped>
</style>