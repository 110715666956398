/*
 * 업무구분: 고객
 * 화 면 명: MSPFS326P
 * 화면설명: 고객계좌검색
 * AS-IS  : SY002601P
 * 작 성 일: 2023.06.15
 * 작 성 자: 이태흥
 */
<template>

    <mo-modal class="fts-modal medium" ref="modal" title="고객 계좌검색">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-table h-scroll">
            <table class="table row-type">
              <thead>
                <tr>
                  <th class="w40"> 선택 </th>
                  <th> 계좌번호 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, idx) in lv_accn_list" :key="idx" :class="{'checked': lv_selectIdx == String(idx+1)}" @click="lv_selectIdx = String(idx+1)">
                  <td>
                    <mo-radio v-model="lv_selectIdx" :value="`${String(idx+1)}`"></mo-radio>
                  </td>
                  <td>
                    {{row.p_bfcer_cstgr_no}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
          <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
        </div>
      </template>
    </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS326P",
    screenId: "MSPFS326P",
    props: {
      popupObj: {type:Object, default:null},
    },    
    components: {

    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog("MSPFS326P")
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {

        // 계좌정보
        lv_accn_list: [],
        lv_selectIdx: null,

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      modal() {
        return this.$refs.modal
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_Open() {
        setTimeout(() => {
          this.fn_Init()
      
          let popupObj = this.popupObj
          console.log(popupObj)

          if( FSCommUtil.gfn_isNull(popupObj) == false && FSCommUtil.gfn_length(popupObj.accn_list) > 0 ) {
            popupObj.accn_list.forEach((item, idx) => {
              item.p_bfcer_cstgr_no = FSCommUtil.gfn_mask(item.bfcer_cstgr_no)
            })           
            this.lv_accn_list = popupObj.accn_list
          }
          this.modal.open()
        }, 10)
      },

      fn_Close() {
        this.modal.close()
      },

      fn_Confirm() {
        console.log('fn_Confirm.....')

        if( FSCommUtil.gfn_isNull(this.lv_selectIdx) ) {
          FSCommUtil.gfn_validate(this, '계좌번호를 선택하여 주십시오.')
          return
        }

        // 그룹번호(계좌번호) 값 넘긴 후 팝업 종료
        this.$emit('fs-popup-callback', 'MSPFS326P', this.lv_accn_list[this.lv_selectIdx - 1].bfcer_cstgr_no)
        this.modal.close()
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS326P] fn_Init...')

        // 계좌정보
        this.lv_search_list = {}
        this.lv_selectIdx = null
      },
      

    }    
  }
</script>
<style scoped>
</style>