/*
 * 업무구분: 영업
 * 화 면 명: MSPFS313P
 * 화면설명: 업무대리인조회_P SY010501P.xfdl
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <mo-modal class="fts-modal large" ref="modal" title="업무대리인조회">
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-table-title mt0">
          <h2>기간별내역</h2>
        </div>
        <div class="wrap-table h-scroll-130">
          <table class="table row-type mb0"> <!-- class명 "mb0" 추가 2023-04-11 jy -->
            <thead>
              <tr>
                <th> 지정일 </th>
                <th> 만기일 </th>
                <th> 등록일 </th>
                <th> 일련번호 </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in ds_list"
                :key="i"
                @click="fn_OnSelect(item)"
              >
                <!-- [수정] 선택 시 checked class추가 :class="{'checked': selectList == (i+1)}"-->
                <td>{{ $bizUtil.dateDashFormat(item.asgn_ymd) }}</td>
                <td>{{ $bizUtil.dateDashFormat(item.exp_ymd) }}</td>
                <td>{{ $bizUtil.dateDashFormat(item.reg_ymd) }}</td>
                <td>{{ item.agnt_sno }}</td>
              </tr>
            </tbody>
          </table>
        </div>      
        <div class="wrap-table mt16">
          <table class="table col-type col1634">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 성명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      ref="ed_agnt_nm"                      
                      :class="lv_ed_agnt_nm.error ? 'error' : ''"
                      v-model="lv_ed_agnt_nm.value"
                      :disabled="lv_ed_agnt_nm.disabled"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 국적 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      ref="cob_naty_cntry_cd"                      
                      :class="lv_cob_naty_cntry_cd.error ? 'error' : ''"
                      :items="lv_commCode.code981"
                      v-model="lv_cob_naty_cntry_cd.value"
                      :disabled="lv_cob_naty_cntry_cd.disabled"
                      placeholder="선택하세요"                       
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 실명번호 종류 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_cob_agnt_rname_gb.error ? 'error' : ''"
                      :items="lv_commCode.code211"
                      v-model="lv_cob_agnt_rname_gb.value"
                      :disabled="lv_cob_agnt_rname_gb.disabled"
                      ref="dropdown2"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 실명번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <!-- <mo-text-field
                      ref="ed_agnt_rname_no"
                      class="input-long"
                      :class="lv_ed_agnt_rname_no.error ? 'error' : ''"
                      v-model="lv_ed_agnt_rname_no.value"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                      maxlength="13"
                      @keyup.enter="fn_OnSearch"
                    /> -->
                    <mo-text-field
                      ref="ed_agnt_rname_no1"
                      :class="lv_ed_agnt_rname_no.error1 ? 'error' : ''"
                      v-model="lv_ed_agnt_rname_no.value1"
                      placeholder="앞 6자리"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                      maxlength="6"
                    />
                    <mo-text-field
                      ref="ed_agnt_rname_no2"
                      type="password"
                      class="input-long"
                      :class="lv_ed_agnt_rname_no.error2 ? 'error' : ''"
                      v-model="lv_ed_agnt_rname_no.value2"
                      placeholder="뒤 7자리"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                      maxlength="7"
                    />
                    <!-- [수정] 실명번호 두칸 나누기-->
                    <!-- <mo-text-field v-model="" placeholder="앞 6자리" class="input-long" maxlength="6" />
                    <mo-text-field type="password" v-model="" clearable placeholder="뒤 7자리" class="input-long" maxlength="7" /> -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 영문명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref= 'ed_agnt_enm'
                      class="input-long"
                      :class="lv_ed_agnt_enm.error ? 'error' : ''"
                      v-model="lv_ed_agnt_enm.value"
                      disabled
                    />
                    <!-- :disabled="lv_ed_agnt_enm.disabled" -->
                  </div>
                </td>
                <th>
                  <span> 여권번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref='ed_agnt_psprt_no'
                      class="input-long"
                      :class="lv_ed_agnt_psprt_no.error ? 'error' : ''"
                      v-model="lv_ed_agnt_psprt_no.value"
                      disabled
                    />
                    <!-- :disabled="lv_ed_agnt_psprt_no.disabled" -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 생년월일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker
                      class="input-long"
                      :class="lv_cal_birth_dt.error ? 'error' : ''"
                      v-model="lv_cal_birth_dt.value"
                      :bottom="false"
                      disabled
                    />
                    <!-- :disabled="lv_cal_birth_dt.disabled" -->
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 성별 </span>
                </th>
                <td>
                  <mo-radio-wrapper
                    :items="gender"
                    v-model="lv_rdo_agnt_sex.value"
                    disabled
                  />
                  <!-- :disabled="lv_rdo_agnt_sex.disabled" -->
                </td>
              </tr>
              <tr>
                <th rowspan="5">
                  <span class="emphasis"> 주소 </span>
                </th>
                <td>
                  <!-- 주소유형 -->
                  <div class="wrap-input row">
                    <mo-dropdown
                      ref="cob_main_dm"                      
                      :class="lv_cob_main_dm.error ? 'error' : ''"
                      :items="lv_commCode.code215"
                      v-model="lv_cob_main_dm.value"
                      :disabled="lv_cob_main_dm.disabled"
                      :bottom="false"
                      placeholder="선택하세요"                      
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">                    
                    <mo-text-field
                      class="w80"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value1"
                      :disabled="lv_home_no.disabled"
                    />
                    <mo-text-field
                      class="w60"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value2"
                      :disabled="lv_home_no.disabled"
                      
                    />
                    <mo-text-field
                      class="w60"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value3"
                      :disabled="lv_home_no.disabled"
                                       
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소 우편번호 -->
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      :class="lv_home_addr.error ? 'error' : ''"
                      v-model="lv_home_addr.zipNum"                      
                      :disabled="lv_home_addr.disabled"
                    />
                    <mo-button
                      class="btn-zip"
                      :disabled="lv_home_addr.disabled"
                      @click="fn_OpenPopup(1, 'HOME')"
                    >
                      우편번호
                    </mo-button>
                  </div>
                </td>
                <th class="bl-ddd">
                  <span> 휴대폰 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      class="w80"
                      :class="lv_phone_no.error ? 'error' : ''"
                      :items="lv_commCode.code370"
                      v-model="lv_phone_no.value1"                      
                      :disabled="lv_phone_no.disabled"
                      ref="dropdown4"
                    />
                    <mo-text-field
                      class="w60"
                      :class="lv_phone_no.error ? 'error' : ''"
                      v-model="lv_phone_no.value2"
                      :disabled="lv_phone_no.disabled"
                      
                    />
                    <mo-text-field
                      class="w60"
                      :class="lv_phone_no.error ? 'error' : ''"
                      v-model="lv_phone_no.value3"
                      :disabled="lv_phone_no.disabled"
                      
                    />
                    <mo-button
                      @click="fn_DeletePhoneNo"
                        disabled                   
                    >
                      삭제
                    </mo-button>
                    <!-- :disabled="lv_phone_no.delete" -->
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소1 / 2 -->
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      :class="lv_home_addr.error ? 'error' : ''"
                      v-model="lv_home_addr.value1"
                      disabled
                    />
                    <mo-text-field
                      class="w61"
                      :class="lv_home_addr.error ? 'error' : ''"
                      v-model="lv_home_addr.value2"
                      disabled
                    />
                  </div>
                </td>
                <th class="bl-ddd">
                  <span> E-mail </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="email_addr1"
                      class="input-email01 input-long"
                      :class="lv_email_addr.error ? 'error' : ''"
                      placeholder="입력하세요"
                      v-model="lv_email_addr.value1"
                      :disabled="lv_email_addr.disabled"
                      @keyup.space="fn_Keyup($event,{ objName: 'lv_email_addr', keyName: 'value1' }, 'trim')"
                      maxlength="20"
                      clearable
                    />
                    <span class="ico-between-email"> @ </span>
                    <mo-text-field
                      ref="email_addr2"
                      class="input-email02 input-long"
                      :class="lv_email_addr.error ? 'error' : ''"
                      placeholder="입력하세요"
                      v-model="lv_email_addr.value2"
                      :disabled="lv_email_addr.disabled"
                      @keyup.space="fn_Keyup($event,{ objName: 'lv_email_addr', keyName: 'value2' }, 'trim')"
                      maxlength="20"
                      clearable
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소3 -->
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="lv_home_addr.value3"
                      disabled
                    />
                  </div>
                </td>
                <th rowspan="3" class="bl-ddd">
                  <span class="emphasis"> 위임내용 </span>
                </th>
                <td rowspan="3">
                  <ul class="wrap-checklist">
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.isAll"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        @click="fn_SetChk(1)"
                        >전체</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="accnOpng"
                        >계좌개설(투자자정보작성)</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="buyRpus"
                        >매입환매</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="chgApl"
                        >제변경신청</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="trsfDfra"
                        >이체/출금</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="etc"
                        >기타</mo-checkbox
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소4 -->
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="lv_home_addr.value4"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 본인과의 관계 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="lv_commCode.code410"
                      :itemText="'cdesc1'"
                      :class="lv_agnt_rltn.error ? 'error' : ''"
                      v-model="lv_agnt_rltn.value"
                      :disabled="lv_agnt_rltn.disabled"
                      placeholder="선택하세요"
                      ref="dropdown5"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 지정일 </span>
                </th>
                <td>
                  <div class="picker row">
                    <mo-date-picker
                      :class="lv_cal_asgn_dt.error ? 'error' : ''"
                      v-model="lv_cal_asgn_dt.value"                      
                      :bottom="false"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 만기일 </span>
                </th>
                <td>
                  <div class="picker row">
                    <mo-date-picker
                      :class="lv_cal_exp_dt.error ? 'error' : ''"
                      v-model="lv_cal_exp_dt.value"
                      :disabled="lv_cal_exp_dt.disabled"
                      :bottom="false"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
      </div>
    </template>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSAlertPopup from "~/src/ui/fs/comm/FSAlertPopup"; // Alert 팝업 (공통)
import FSCommUtil from "@/ui/fs/comm/FSCommUtil";
import FSServiceUtil from "@/ui/fs/comm/FSServiceUtil";
import moment from "moment";
import FSCodeUtil from "~/src/ui/fs/comm/FSCodeUtil";

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS313P",
  screenId: "MSPFS313P",
  props: {
    popupObj: {type:Object, default:null},
  },
  components: {
    "fs-alert-popup": FSAlertPopup,        
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {    
  },
  mounted() {    
    this.$bizUtil.insSrnLog('MSPFS313P')    
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 팝업 객체
      pAlertPopupObj: {},      

      // 검색조건
      lv_ed_cust_no: {}, // 고객번호
      lv_ed_cust_nm: {}, // 고객명

      lv_cust_info: {}, // 고객정보
      lv_commCode: {}, // 공통코드

      // 대리인정보
      lv_ed_agnt_nm: {}, // 성명
      lv_cob_naty_cntry_cd: {}, // 국적
      lv_cob_agnt_rname_gb: {}, // 실명번호종류
      lv_ed_agnt_rname_no: {}, // 실명번호
      lv_ed_agnt_enm: {}, // 영문명
      lv_ed_agnt_psprt_no: {}, // 여권번호
      lv_cal_birth_dt: {}, // 생일 > 날짜
      lv_rdo_agnt_sex: {}, // 성별

      lv_cob_main_dm: {}, // 주소유형
      lv_home_addr: {}, // 자택주소
      lv_home_no: {}, // 자택전화번호
      lv_phone_no: {}, // 휴대폰번호
      lv_email_addr: {}, // email주소
      lv_agnt_trst_cntnt: {}, // 위임내용
      lv_agnt_rltn: {}, // 본인과의 관계
      lv_cal_asgn_dt: {}, // 지정일
      lv_cal_exp_dt: {}, // 만기일
      ds_list: [],       // 조회 데이터
      ds_agent: {},      // 조회 후 선택한 데이터      
      /***********************************************************************************       
       * F10570167 : 개인정보조회       
       * F10570184 : 대리인정보조회
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal() {
      return this.$refs.modal
    },
    
    gender() {
      let rtn = [];
      rtn.push({ value: "M", text: "남" });
      rtn.push({ value: "F", text: "여" });
      return rtn;    },
    
    relation() {
      let rtn = [];
      rtn.push({ value: "1", text: "배우자" });
      rtn.push({ value: "2", text: "직계비속" });
      rtn.push({ value: "3", text: "직계존속" });
      rtn.push({ value: "4", text: "법인대표" });
      rtn.push({ value: "5", text: "종업원" });
      return rtn;
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    'lv_agnt_trst_cntnt.value': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            let agntChkArrAll = ['accnOpng', 'buyRpus', 'chgApl', 'trsfDfra', 'etc']            
            if (this.lv_agnt_trst_cntnt.value.filter(d => agntChkArrAll.includes(d)).length === 5) {
              this.lv_agnt_trst_cntnt.isAll = true
            } else {
              // 하나라도 포함하지 않음
              this.lv_agnt_trst_cntnt.isAll = false
            }
          } else {
            // 모두 포함하지 않음
            this.lv_agnt_trst_cntnt.isAll = false
          }
        },
        deep: true
      },
      'lv_cob_naty_cntry_cd.value': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            if (after === 'KR') {
              this.lv_ed_agnt_enm.disabled = true
              this.lv_ed_agnt_psprt_no.disabled = true
              this.lv_cal_birth_dt.disabled = true
              this.lv_rdo_agnt_sex.disabled = true
            } else {
              this.lv_ed_agnt_enm.disabled = false
              this.lv_ed_agnt_psprt_no.disabled = false
              this.lv_cal_birth_dt.disabled = false
              this.lv_rdo_agnt_sex.disabled = false
            }
          }
        },
        deep: true
      }, 
      'lv_phone_no.value1': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            this.lv_phone_no.delete = false
          } else {
            this.lv_phone_no.delete = true
          }
        },
        deep: true
      },
      'lv_phone_no.value2': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            this.lv_phone_no.delete = false
          } else {
            this.lv_phone_no.delete = true
          }
        },
        deep: true
      },
      'lv_phone_no.value3': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            this.lv_phone_no.delete = false
          } else {
            this.lv_phone_no.delete = true
          }
        },
        deep: true
      },  
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Open() {
      this.fn_Init()
      this.fn_SetCommCode()
      this.$nextTick(() => {        
        if(this.popupObj.bfcer_cust_no) {
          this.lv_ed_cust_no.value = this.popupObj.bfcer_cust_no          
          this.fn_SearchList()
        }
      })

      this.modal.open()
    },
    fn_Close() {
      this.modal.close()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.cob_naty_cntry_cd.close()
      this.$refs.dropdown2.close()
      this.$refs.cob_main_dm.close()
      this.$refs.dropdown4.close()
      this.$refs.dropdown5.close()
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content          
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true
        this.pAlertPopupObj.type = 0

        if( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
        if( !FSCommUtil.gfn_isNull(type) && type != 0 ) {
          this.pAlertPopupObj.type = type
        }
      }
      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     * 파라미터(타입) : type (init - 초기화 시), flag (false - 활성화, true - 비활성화)      
     ******************************************************************************/
    fn_Init(type = 'init', flag = true) {
      console.log("[MSPFS302M] fn_Init...");      
      
      // 초기화 버튼 클릭 시, 초기화 시 검색 조건 초기화
      if(type === 'init'){
        this.lv_ed_cust_no = FSCommUtil.gfn_dataSet(1,"",false,"","ed_cust_no")  // 고객번호
      }

      if(type === 'init' || type === 'keyup') {
        this.lv_ed_cust_nm       = FSCommUtil.gfn_dataSet(1)                     // 고객명                
      }

      // 기간추가 가 아닐 시 초기화
      if(type !== 'add'){
        this.ds_list = []
        this.ds_agent = {}
      }

      // 업무대리인 정보
      this.lv_cob_agnt_rname_gb  = FSCommUtil.gfn_dataSet(0, '01', flag); // 실명번호 종류
      this.lv_ed_agnt_rname_no   = FSCommUtil.gfn_dataSet(1, ''  , flag); // 실명번호
      this.lv_ed_agnt_nm         = FSCommUtil.gfn_dataSet(1, ''  , flag,'','ed_agnt_nm'); // 고객명
      this.lv_ed_agnt_enm        = FSCommUtil.gfn_dataSet(1, ''  , flag); // 고객영문명
      this.lv_cob_naty_cntry_cd  = FSCommUtil.gfn_dataSet(0, "KR", flag); // 국적
      this.lv_cal_asgn_dt        = FSCommUtil.gfn_dataSet(1, ''  , flag); // 지정일
      this.lv_cal_exp_dt         = FSCommUtil.gfn_dataSet(1, ''  , flag); // 만기일
      this.lv_cal_birth_dt       = FSCommUtil.gfn_dataSet(1, ''  , flag); // 생년월일
      this.lv_cob_main_dm        = FSCommUtil.gfn_dataSet(0, '1' , flag); // 주소유형
      this.lv_ed_agnt_psprt_no   = FSCommUtil.gfn_dataSet(1, ''  , flag); // 여권번호
      this.lv_rdo_agnt_sex       = FSCommUtil.gfn_dataSet(1, ''  , flag); // 성별
      this.lv_agnt_rltn          = FSCommUtil.gfn_dataSet(1, ''  , flag); // 본인과의 관계

      // 자택주소
      this.lv_home_addr = {
        disabled: flag,
        class: false,
        error: false,
        delete: false,
        standard: false,
        zipNum: "",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      }

      // 이메일주소
      this.lv_email_addr = {
        disabled: flag,
        class: false,
        error: false,        
        value1: "",
        value2: "",        
      }

      // 자택전화번호
      this.lv_home_no = {
        disabled: flag,
        class: false,
        error: false,        
        value1: "",
        value2: "",
        value3: "",
      }

      // 휴대폰번호
      this.lv_phone_no = {
        disabled: flag,
        class: false,
        error: false,
        delete: flag,
        value1: "",
        value2: "",
        value3: "",
      }      

      // 위임내용
      this.lv_agnt_trst_cntnt = {
        disabled: flag,
        value: [],
        isAll: false,
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 고객정보 1: 우편번호
     ******************************************************************************/
    fn_OpenPopup(type, flag) {
      let t_popupObj = {}
      switch (type) {
        case 0:          
          this.$refs.popup308.fn_Open();          
          break
        case 1:
          this.pPopup100Obj.callbackType = type
          this.pPopup100Obj.btnFlag = flag
          this.$refs.popup100.fn_Open()
          break;
        case 2:
          break;
      }
    },
    
    /******************************************************************************
     * Function명 : fn_com_res_chk
     * 설명       : 실명번호 체크(공통)
     ******************************************************************************/
    fn_com_res_chk() {
      this.lv_cob_agnt_rname_gb.error = false;
      this.lv_ed_agnt_rname_no.error = false;

      if (
        FSCommUtil.gfn_length(this.lv_ed_agnt_rname_no.value1) != 6 ||
        !FSCommUtil.gfn_isNum(this.lv_ed_agnt_rname_no.value1)
      ) {
        FSCommUtil.gfn_validate(this, "실명번호의 형식에 맞지 않습니다.");
        this.lv_ed_agnt_rname_no.error = true;
        this.$refs["ed_rname_no1"].focus();
        return false;
      } else if (
        FSCommUtil.gfn_length(this.lv_ed_agnt_rname_no.value2) != 7 ||
        !FSCommUtil.gfn_isNum(this.lv_ed_agnt_rname_no.value2)
      ) {
        FSCommUtil.gfn_validate(this, "실명번호의 형식에 맞지 않습니다.");
        this.lv_ed_agnt_rname_no.error = true;
        this.$refs["ed_rname_no2"].focus();
        return false;
      }
      return true;
    },

    /******************************************************************************
     * Function명 : fn_rname_chk
     * 설명       : 실명번호종류 체크(공통)
     ******************************************************************************/
    fn_rname_chk() {
      // 주민등록번호
      if (this.lv_cob_agnt_rname_gb.value == "01") {
        this.lv_cob_inc_earner_gb.value = "111"; // 소득자 구분

        // 외국인등록번호
      } else if (this.lv_cob_agnt_rname_gb.value == "04") {
        this.lv_cob_inc_earner_gb.value = "131"; // 소득자 구분
      }
      return true;
    },

    /******************************************************************************
     * Function명 : fn_SetCalBirthDt
     * 설명       : 실명번호종류가 주민번호일 경우 생일 세팅
     ******************************************************************************/
    fn_SetCalBirthDt () {
      let t_birthDt = null
      let t_year = 19
      let t_date = this.lv_ed_agnt_rname_no.value.substr(0,6)

      // 주민번호가 3이상이면 2000년도 출생
      if (Number(FSCommUtil.gfn_substr(this.lv_ed_agnt_rname_no.value, 6, 1)) > 2) {
        t_year = 20
      }

      t_birthDt = t_year + t_date
      if( !FSCommUtil.gfn_isNull(t_birthDt) ) {
        this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, t_birthDt)
      }
    },

    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log("fn_SetCommCode...");

      let params = [
        { cId: "211", dayChkYn: "N" },
        { cId: "981", dayChkYn: "N" },
        { cId: "410", dayChkYn: "N" },
        { cId: "370", dayChkYn: "N" },
        { cId: "215", dayChkYn: "N" },
      ];
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult);
    },

    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult;
      this.lv_commCode.code370.push({value: '' , text: ''})
    },

    /***************************************************************************************
     * Function명 : fn_Keyup
     * 설명       : keyup
     * flag      : trim (공백제거) / del (제거)
    *****************************************************************************************/
    fn_Keyup (event, pData, flag) {
      const t_objName   = pData.objName
      const t_keyName = pData.keyName

      if( FSCommUtil.gfn_isNull(flag) ) {
        // flag 없을 경우 처리

      } else {
        if( flag == 'trim' ) {
          FSCommUtil.gfn_validate(this, '공백입력은 허용되지 않습니다. 작성에 주의하시기 바랍니다.')
          this[t_objName][t_keyName] = FSCommUtil.gfn_trim(this[t_objName][t_keyName])
          
        } else if( flag == 'del' ) {
          this[t_objName][t_keyName] = ''
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_DeletePhoneNo
     * 설명       : 휴대번호 번호 삭제
     ******************************************************************************/
    fn_DeletePhoneNo() {
      // 휴대폰번호
      this.lv_phone_no = {
        disabled: false,        
        error: false,
        delete: true,
        value1: "",
        value2: "",
        value3: "",
      }
    },

    /*********************************************************
     * Function명: fn_SetChk
     * 설명: 위임내용 체크 이벤트
     * 파라미터 : type ( 1: all 체크 )
     *********************************************************/
    fn_SetChk(type) {
      switch(type){
        case 1:
          let tmpChk = Object.assign({}, this.lv_agnt_trst_cntnt);
          if (!tmpChk.isAll) {
            if (!tmpChk.value.includes("accnOpng")) {
              tmpChk.value.push("accnOpng");
            }

            if (!tmpChk.value.includes("buyRpus")) {
              tmpChk.value.push("buyRpus");
            }

            if (!tmpChk.value.includes("chgApl")) {
              tmpChk.value.push("chgApl");
            }

            if (!tmpChk.value.includes("trsfDfra")) {
              tmpChk.value.push("trsfDfra");
            }

            if (!tmpChk.value.includes("etc")) {
              tmpChk.value.push("etc");
            }
          } else {
            tmpChk.value = [];
          }

          this.lv_agnt_trst_cntnt = tmpChk;        
          break
      }      
    },

    /*********************************************************
     * Function명: fn_GetChk
     * 설명: 위임내용 체크 가져오기
     * 파라미터 : type ( 1: 조회 후 설정, 2: 등록/수정 시 설정 ), val (체크 데이터)
     *********************************************************/
    fn_GetChk(type, val = '') {
      let tmpChk = null
      switch(type){
        case 1:
          tmpChk = []
          if(FSCommUtil.gfn_isNull(val)){
            return tmpChk
          }

          let chkVal = FSCommUtil.gfn_trim(val)
          if(chkVal.length !== 5){
            return tmpChk
          }
          
          if(chkVal.substr(0,1) === 'Y'){
            tmpChk.push("accnOpng")
          }

          if(chkVal.substr(1,1) === 'Y'){
            tmpChk.push("buyRpus")
          }

          if(chkVal.substr(2,1) === 'Y'){
            tmpChk.push("chgApl")
          }

          if(chkVal.substr(3,1) === 'Y'){
            tmpChk.push("trsfDfra")
          }

          if(chkVal.substr(4,1) === 'Y'){
            tmpChk.push("etc")
          }          
          break
        case 2:
          tmpChk = ''
          let tmpCurChk = Object.assign([], this.lv_agnt_trst_cntnt.value)
          if(tmpCurChk.includes('accnOpng')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }

          if(tmpCurChk.includes('buyRpus')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }

          if(tmpCurChk.includes('chgApl')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }

          if(tmpCurChk.includes('trsfDfra')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }

          if(tmpCurChk.includes('etc')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }
          break
      }
      return tmpChk      
    },
     
    /******************************************************************************
     * Function명 : fn_SearchList, fn_SearchListResult
     * 설명       : 업무대리인 기간별 조회
     * svcId     : selectSI410000_custList     
     ******************************************************************************/
    fn_SearchList () {
      console.log('fn_SearchList...')

      this.eaiCommObj.lv_vm = this      
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570180_S'
      this.eaiCommObj.params = {
        bfcer_cust_no     : this.lv_ed_cust_no.value,
        proc_gb           : 'S'  // 영업점코드
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchListResult)
    },

    fn_SearchListResult (pResultData) {
      console.log('fn_SearchListResult...', pResultData)
      let t_data = pResultData.data.si410000_O_00VO
      if(pResultData.data.error_msg !== '809900'){
        this.fn_AlertPopup(0,{content: '정상적으로 조회되지 않았습니다.'})
        return
      }

      // 기존 대리인 목록 구분
      if (t_data.length > 0) {
        this.ds_list = t_data                        
        this.fn_OnSelect(this.ds_list[this.ds_list.length - 1])
      } else {
        this.fn_AlertPopup(0,{content: '고객이 존재하지 않습니다.'})
      }
    },

    /******************************************************************************
     * Function명 : fn_AgntListSearch, fn_AgntListSearchResult
     * 설명       : 대리인목록조회
     ******************************************************************************/
    fn_AgntListSearch () {
      console.log('fn_AgntListSearch...')

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570184_S'
      this.eaiCommObj.params = {
        bfcer_cust_no     : this.lv_ed_cust_no.value, 
        agnt_sno          : this.lv_cob_agnt_rname_gb.value,
        asgn_ymd      : this.lv_ed_agnt_rname_no.value1 + this.lv_ed_agnt_rname_no.value2,  
        exp_ymd      : this.lv_cal_exp_dt.value,
        reg_ymd      : this.lv_cal_exp_dt.value,
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AgntListSearchResult)
    },

    fn_AgntListSearchResult (pResultData, pErrorCode) {
        console.log('fn_AgntListSearchResult...')
        let t_data = pResultData.data.cu100000_O_01VO
        console.log(t_data)

        if (t_data.length > 0) {
        }
    },
    
    /******************************************************************************
     * Function명 : fn_OnSelect
     * 설명       : 기간별 내역에서 대리인 선택 이벤트 (대리인 조회)
     * 파라미터    : 목록에서 선택한 데이터
     * svcId      : selectSI410000_agentList.do
     ******************************************************************************/
    fn_OnSelect(data) {
      console.log("fn_OnSelect.....", data)

      let lv_Vm = this;      
      lv_Vm.eaiCommObj.lv_Vm = this;
      lv_Vm.eaiCommObj.auth = "S";

      lv_Vm.eaiCommObj.commHeaderVO.eaiId = "C391_F10570184_S";
      lv_Vm.eaiCommObj.params = {
        bfcer_cust_no: this.lv_ed_cust_no.value,
        agnt_sno: data.agnt_sno,
        asgn_ymd: data.asgn_ymd,
        exp_ymd: data.exp_ymd,
        reg_ymd: data.reg_ymd
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_OnSelectResult)
    },
    fn_OnSelectResult(pResultData){
      console.log("fn_OnSelectResult.....", pResultData)            
      let t_data = pResultData.data.tfoc02095_O_VO
      if(pResultData.data.error_msg !== '809900'){
        this.fn_AlertPopup(0,{content: '정상적으로 조회되지 않았습니다.'})
        return
      }

      if (t_data.length > 0) {
        this.fn_SetData(1, t_data[0])

      } else {
        this.fn_AlertPopup(0,{content: '고객이 존재하지 않습니다.'})
      }
    },
    
    /******************************************************************************
     * Function명 : fn_SetData
     * 설명       : 호출 결과 데이터 설정
     * 파라미터    : type (1 : 조회) , data (호출 결과 데이터), flag (true = disabled , false = !disabled)
     ******************************************************************************/
    fn_SetData(type, data = {}, flag = true){
      switch(type){
        case 1: // 대리인 정보
          // 성명
          this.lv_ed_agnt_nm.value = data.bfcer_agnt_nm
          this.lv_ed_agnt_nm.disabled = flag

          // 국적
          this.lv_cob_naty_cntry_cd.value = data.agnt_naty_cntry_cd
          this.lv_cob_naty_cntry_cd.disabled = flag

          // 실명번호 종류
          this.lv_cob_agnt_rname_gb.value = data.agnt_rlnm_no_knd_cd
          this.lv_cob_agnt_rname_gb.disabled = flag

          // 실명번호종류가 주민번호일 경우 체크
          // if (this.lv_cob_agnt_rname_gb.value == "01") {
          //   this.fn_SetCalBirthDt();
          // }

          // 실명번호
          this.lv_ed_agnt_rname_no.value1 = data.agnt_rlnm_cnf_no_encr.substr(0, 6)
          this.lv_ed_agnt_rname_no.value2 = data.agnt_rlnm_cnf_no_encr.substr(6, 13)
          this.lv_ed_agnt_rname_no.disabled = flag

          // 영문명
          this.lv_ed_agnt_enm.value = data.agnt_eng_nm          
          this.lv_ed_agnt_enm.disabled = flag

          // 여권번호
          this.lv_ed_agnt_psprt_no.value = data.agnt_psprt_no_encr          
          this.lv_ed_agnt_psprt_no.disabled = flag

          // 생년월일
          if(!FSCommUtil.gfn_isNull(data.agnt_birth_mmdd)){
            this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, data.agnt_birth_mmdd)    
            this.lv_cal_birth_dt.disabled = flag
          }

          // 성별
          this.lv_rdo_agnt_sex.value = data.agnt_sxds_cd
          this.lv_rdo_agnt_sex.disabled = flag

          // 주소 유형
          this.lv_cob_main_dm.value = data.bfcer_agnt_mn_ctadr_cd
          this.lv_cob_main_dm.disabled = flag

          // 우편번호
          this.lv_home_addr.zipNum = data.agnt_addr_pstcd01 + data.agnt_addr_pstcd02

          // 주소
          this.lv_home_addr.value1 = data.agnt_pstcd_addr     // 본주소
          this.lv_home_addr.value2 = data.agnt_bldg_no_encr   // 건물번호
          this.lv_home_addr.value3 = data.agnt_dtlad_encr     // 상세주소
          this.lv_home_addr.value4 = data.agnt_adtn_addr_encr // 부가주소
          this.lv_home_addr.disabled = flag

          // 전화번호
          this.lv_home_no.value1 = data.agnt_dstno_encr
          this.lv_home_no.value2 = data.agnt_tlnum_no_encr
          this.lv_home_no.value3 = data.agnt_bkno_sno_encr
          this.lv_home_no.disabled = flag
          
          // 휴대폰번호
          this.lv_phone_no.value1 = data.agnt_celph_bman_no_encr
          this.lv_phone_no.value2 = data.agnt_celph_tlnum_no_encr
          this.lv_phone_no.value3 = data.agnt_celph_bkno_sno_encr
          this.lv_phone_no.disabled = flag

          // 이메일
          this.lv_email_addr.value1 = data.agnt_emai_addr_encr
          this.lv_email_addr.value2 = data.agnt_emai_dm_nm          
          this.lv_email_addr.disabled = flag

          // 본인과의 관계
          this.lv_agnt_rltn.value = data.agnt_rltn_cd
          this.lv_agnt_rltn.disabled = flag

          // 위임내용
          this.lv_agnt_trst_cntnt.value = this.fn_GetChk(1, data.trst_sc_list_cntnt)
          this.lv_agnt_trst_cntnt.disabled = flag

          // 지정일
          this.lv_cal_asgn_dt.value = FSCommUtil.gfn_dateReplace(1, data.asgn_ymd)
          this.lv_cal_asgn_dt.disabled = flag

          // 만기일
          this.lv_cal_exp_dt.value = FSCommUtil.gfn_dateReplace(1, data.exp_ymd)
          this.lv_cal_exp_dt.disabled = flag
          break        
      }
    },
    /******************************************************************************
     * Function명 : fn_GetData
     * 설명       : 데이터 설정
     * 파라미터    : type (1 : 수정)
     ******************************************************************************/
    fn_GetData(type){
      let rtn = {}
      switch (type){
        case 1:
          rtn.bfcer_cust_no =  this.lv_ed_cust_no.value          
          rtn.agnt_addr_pstcd01 = this.lv_home_addr.zipNum.substr(0,3)
          rtn.agnt_addr_pstcd02 = this.lv_home_addr.zipNum.substr(2,2)
          rtn.agnt_addr_pstcd03 = ''
          rtn.agnt_addr_stdzt_yn = '' // 주소표준화여부                    
          rtn.agnt_birth_mmdd = this.lv_cal_birth_dt.value.replace(/-/g, '')          
          rtn.agnt_celph_bkno_sno_encr = 
          rtn.agnt_celph_bman_no_encr = this.lv_phone_no.value1
          rtn.agnt_celph_tlnum_no_encr = this.lv_phone_no.value2
          rtn.agnt_celph_bkno_sno_encr = this.lv_phone_no.value3          
          rtn.agnt_emai_addr_encr = this.lv_email_addr.value1
          rtn.agnt_emai_dm_nm = this.lv_email_addr.value2
          rtn.agnt_eng_nm = this.lv_ed_agnt_enm.value
          rtn.agnt_naty_cntry_cd = this.lv_cob_naty_cntry_cd.value
          rtn.agnt_psprt_no_encr = this.lv_ed_agnt_psprt_no.value
          rtn.agnt_pstcd_addr = this.lv_home_addr.value1
          rtn.agnt_bldg_no_encr = this.lv_home_addr.value2
          rtn.agnt_dtlad_encr = this.lv_home_addr.value3
          rtn.agnt_adtn_addr_encr = this.lv_home_addr.value4
          rtn.agnt_dstno_encr = this.lv_home_no.value1
          rtn.agnt_tlnum_no_encr = this.lv_home_no.value2
          rtn.agnt_bkno_sno_encr = this.lv_home_no.value3
          rtn.agnt_rlnm_cnf_no_encr = this.lv_ed_agnt_rname_no.value
          rtn.agnt_rlnm_no_knd_cd = this.lv_cob_agnt_rname_gb.value
          rtn.agnt_rltn_cd = this.lv_agnt_rltn.value
          rtn.agnt_sno = this.ds_agent.agnt_sno // 선택한 대리인 일련번호
          rtn.agnt_sxds_cd = this.lv_rdo_agnt_sex.value          
          rtn.asgn_ymd = this.lv_cal_asgn_dt.value.replace(/-/g, '')
          rtn.bfcer_agnt_mn_ctadr_cd = this.lv_cob_main_dm.value
          rtn.bfcer_agnt_nm = this.lv_ed_agnt_nm.value          
          rtn.cbl_yn = '' // 유선여부
          rtn.exp_ymd = this.lv_cal_exp_dt.value.replace(/-/g, '')
          rtn.reg_ymd = moment(new Date().toISOString()).format("YYYYMMDD") // 등록일
          rtn.trst_sc_list_cntnt = this.fn_GetChk(2)          
          break
      }      
      return rtn
    },
    
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/
    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        this.pAlertPopupObj.content = pPopupObj.content;
        this.pAlertPopupObj.confirm = false;
        this.pAlertPopupObj.cancel = true;

        if (!pPopupObj.cancel) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel;
        }
        if (pPopupObj.confirm) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm;
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData;
        }
        if (pPopupObj.closeFunc) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
          this.pAlertPopupObj.closeData = pPopupObj.closeData;
        }
      }
      this.$refs.alertPopup.fn_Open();
    },
  },
};
</script>
<style scoped>
</style>