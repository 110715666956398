/*
* 업무구분: 영업
* 화 면 명: MSPFS305P
* 화면설명: 고위험승인요청_P(개인/법인) CU009910P
* 작 성 일: 2023.05.11
* 작 성 자: 이태흥
* 수정일 : 2024-06-18 강현철 사유 : ASR240600395 고위험승인내역 화면 개선 요청
* 수정일 : 2024-09-24 강현철 사유 : ASR240701139 AML 및 고객확인 체크보완
*/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="고위험승인요청">
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-table">
          <table class="table col-type">
            <tbody>
              <tr>
                <th class="w20p">
                  <span> EDD, PEP </span>
                </th>
                <td><mo-text-field v-model="lv_me_edd_pep.value" placeholder="입력하세요" disabled /> 고객입니다.</td>
              </tr>
              <tr>
                <th>
                  <span> 정보제공여부 </span>
                </th>
                <td>
                  <mo-radio-wrapper :items="radio1" v-model="lv_rad_edd_inf_reci_yn.value" class="row" :class="lv_rad_edd_inf_reci_yn.error ? 'error' : ''" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 승인요청 메세지 </span>
                </th>
                <td>
                  <mo-text-area class="input-long" v-model="lv_aml_sanc_trt_msg_cntnt.value" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 1차 결재자 </span>
                </th>
                <td>
                  <div class="wrap-table">
                    <table class="table row-type">
                      <thead>
                        <tr>
                          <th class="w90">선택</th>
                          <th>결재자명</th>
                          <th>직위</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, idx) in lv_aml_list1" :key="idx" :class="{ checked: lv_isAmlList1 == String(idx + 1) }" @click="lv_isAmlList1 = String(idx + 1)">
                          <td>
                            <mo-radio v-model="lv_isAmlList1" :value="`${String(idx + 1)}`"></mo-radio>
                          </td>
                          <td>{{ row.bfcer_eply_nm }}</td>
                          <td>{{ row.user_auth_grp_nm }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 2차 결재자 </span>
                </th>
                <td>
                  <div class="wrap-table">
                    <table class="table row-type">
                      <thead>
                        <tr>
                          <th class="w90">선택</th>
                          <th>결재자명</th>
                          <th>직위</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, idx) in lv_aml_list2" :key="idx" :class="{ checked: lv_isAmlList2 == String(idx + 1), disabled: lv_isList2 }" @click="lv_isAmlList2 = String(idx + 1)">
                          <td>
                            <mo-radio v-model="lv_isAmlList2" :value="`${String(idx + 1)}`"></mo-radio>
                          </td>
                          <td>{{ row.bfcer_eply_nm }}</td>
                          <td>{{ row.user_auth_grp_nm }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Submit()">승인요청</mo-button>
        <!-- <mo-button primary size="large" @click="fn_TestSubmitResult()">승인요청TEST</mo-button> -->
      </div>
    </template>

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>

    <!-- popup306 -->
    <msp-fs-306p ref="popup306" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup306Obj"></msp-fs-306p>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import MSPFS306P from '~/src/ui/fs/MSPFS306P' // EDD/PEP 추가정보 입력 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS305P',
  screenId: 'MSPFS305P',
  props: {
    popupObj: { type: Object, default: null }
  },
  components: {
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-306p': MSPFS306P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.$bizUtil.insSrnLog('MSPFS305P')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pAlertPopupObj: {},
      lv_commCode: {},

      // 팝업 객체
      pPopup306Obj: {},

      // 입력사항
      lv_me_edd_pep: {}, // EDD/PEP입력
      lv_rad_edd_inf_reci_yn: {}, // 정보제공여부
      lv_aml_sanc_trt_msg_cntnt: {}, // AML결재처리메시지내용

      lv_aml_list1: [], // 1차결재자
      lv_isAmlList1: null, // 1차결재자 체크여부

      lv_aml_list2: [], // 2차결재자
      lv_isAmlList2: null, // 2차결재자 체크여부
      lv_isList2: false, // 2차결재자 List 활성화 여부

      lv_aml_pphse: '', // 거래승인진행상태코드

      // 조회결과
      lv_cddCustInfo: {}, // CDD고객정보조회결과

      // submit data
      lv_submit_data: {},

      // 로그인정보
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,

      /***********************************************************************************
       * F10570117 : CDD고객정보조회
       * F10570282 : 결재자재조회
       * F10570290 : AML승인요청
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    modal() {
      return this.$refs.modal
    },

    radio1() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 직업구분 선택
    'lv_rad_edd_inf_reci_yn.value': {
      handler(after, before) {
        if (after == 'N') {
          let t_popupObj = { content: 'EDD/PEP 정보제공을 거부하실 경우\n수익증권 모든 신규거래가 제한됩니다.' }
          this.fn_AlertPopup(0, t_popupObj)
          return
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Open() {
      this.fn_Init()

      this.modal.open()

      setTimeout(() => {
        this.fn_CddCustInfo()
      }, 100)
    },
    fn_Close() {
      this.modal.close()
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS305P] fn_Init...')

      // 입력사항
      this.lv_me_edd_pep = FSCommUtil.gfn_dataSet(0, '') // EDD/PEP입력
      this.lv_rad_edd_inf_reci_yn = FSCommUtil.gfn_dataSet(0, 'Y') // 정보제공여부
      this.lv_aml_sanc_trt_msg_cntnt = FSCommUtil.gfn_dataSet(0, '') // AML결재처리메시지내용

      this.lv_aml_list1 = [] // 1차결재자
      this.lv_isAmlList1 = null // 1차결재자 체크여부

      this.lv_aml_list2 = [] // 2차결재자
      this.lv_isAmlList2 = null // 2차결재자 체크여부
      this.lv_isList2 = false // 2차결재자 List 활성화 여부

      this.lv_aml_pphse = '' // 거래승인진행상태코드

      // 조회결과
      this.lv_cddCustInfo = {}

      // submit data
      this.lv_submit_data = {}
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     ******************************************************************************/
    fn_Popup_CallBack(pData) {
      console.log('fn_Popup_CallBack...')

      // 메인 화면으로 고객번호 값 넘긴 후 팝업 종료
      // 2024.06.18 ASR240600395 고위험승인내역 화면 개선 요청
      this.$emit('fs-popup-callback', 'MSPFS305P', pData)
      this.modal.close()
    },

    /******************************************************************************
     * Function명 : fn_CddCustInfo, fn_CddCustInfoResult
     * 설명       : CDD고객정보조회
     ******************************************************************************/
    fn_CddCustInfo() {
      console.log('fn_CddCustInfo...')
      console.log('acct_no =' + this.popupObj.acct_no)

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570117_S'
      this.eaiCommObj.params = {
        bfcer_accn_no: this.popupObj.acct_no,
        rlnm_no_knd_cd: '',
        rlnm_cnf_no_encr: '',
        fnd_cust_nm: ''
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CddCustInfoResult)
    },

    fn_CddCustInfoResult(pResultData) {
      console.log('fn_CddCustInfoResult...')

      let t_data = pResultData.data
      console.log(t_data)

      // CDD고객정보 매핑
      this.lv_cddCustInfo = t_data

      // EDD/PEP 매핑
      this.lv_me_edd_pep.value = this.popupObj.call_tp

      // 결재자재조회 호출
      this.fn_AmlAdmList()
    },

    /******************************************************************************
     * Function명 : fn_AmlAdmList, fn_AmlAdmListResult
     * 설명       : 결재자재조회
     ******************************************************************************/
    fn_AmlAdmList() {
      console.log('fn_AmlAdmList...')
      console.log('lv_basInfo=' + this.lv_basInfo.usid)

      // let t_loginInfo = await FSInfoUtil.commInfo(this, 3, {})
      if (FSCommUtil.gfn_isNull(this.lv_basInfo) == false) {
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570282_S'
        this.eaiCommObj.params = {
          staf_eno: this.lv_basInfo.usid
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AmlAdmListResult)
      }
    },

    fn_AmlAdmListResult(pResultData) {
      console.log('fn_AmlAdmListResult...')

      let t_data = pResultData.data.cu171000_O_00VO
      console.log(t_data)

      if (t_data.length > 0) {
        t_data.forEach((item) => {
          // ASR240600395 고위험승인내역 화면 개선 요청
          if (item.bfcer_user_auth_grp_cd !== '35') { // 1차결재자 추가
            this.lv_aml_list1.push(item)
          }
          // ASR240701139 / AML 및 고객확인 체크보완 (24.09.24)
          if (this.popupObj.edd_sanc_obj_sc_cd === '20' && item.bfcer_user_auth_grp_cd === '35') {
            this.lv_aml_list2.push(item)
          }
          /* if (this.lv_me_edd_pep.value === 'PEP') {
            // 35: 본사임원
            if (item.bfcer_user_auth_grp_cd === '35') {
              // 2차결재자 추가
              this.lv_aml_list2.push(item)
            }
          } */
        })
        // 1차, 2차 결재자 선택은 제일 위에 있는 사람으로 디폴트 선택 되어 있도록 초기화 함
        if (FSCommUtil.gfn_length(this.lv_aml_list1) > 0) {
          this.lv_isAmlList1 = '1'
        }

        // PEP일때만 2차결재까지
        if (FSCommUtil.gfn_length(this.lv_aml_list2) > 0 && this.lv_me_edd_pep.value == 'PEP') {
          this.lv_isAmlList2 = '1'
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_Submit, fn_SubmitResult
     * 설명       : AML승인요청
     ******************************************************************************/
    fn_Submit() {
      console.log('fn_Submit...')

      // 초기화
      this.lv_rad_edd_inf_reci_yn.error = false

      if (FSCommUtil.gfn_isNull(this.lv_rad_edd_inf_reci_yn.value) == true) {
        FSCommUtil.gfn_validate(this, '정보제공 여부를 선택해주시기 바랍니다.')
        this.lv_rad_edd_inf_reci_yn.error = true
        return
      } else if (this.lv_rad_edd_inf_reci_yn.value == 'N') {
        FSCommUtil.gfn_validate(this, '정보제공을 거부하실 경우 수익증권 모든 신규거래가 제한됩니다.')
        this.lv_rad_edd_inf_reci_yn.error = true
        return
      }

      // WL동일인여부
      this.lv_submit_data.wl_samp_yn = 'Y'

      // AML진행단계코드
      this.lv_submit_data.aml_prg_phse_cd = this.lv_me_edd_pep.value == 'PEP' ? '40' : '30'
      this.lv_aml_pphse = this.lv_submit_data.aml_prg_phse_cd

      // 1차승인자 세팅
      this.lv_submit_data.aml_sanc_aprvr_eno01 = ''
      if (FSCommUtil.gfn_length(this.lv_aml_list1) > 0) {
        this.lv_submit_data.aml_sanc_aprvr_eno01 = this.lv_aml_list1[Number(this.lv_isAmlList1) - 1].staf_eno
      }

      // 2차승인자 세팅
      this.lv_submit_data.aml_sanc_aprvr_eno02 = ''
      if (FSCommUtil.gfn_length(this.lv_aml_list2) > 0) {
        this.lv_submit_data.aml_sanc_aprvr_eno02 = this.lv_aml_list2[Number(this.lv_isAmlList2) - 1].staf_eno
      }

      this.lv_submit_data.rlnm_cnf_no_encr = this.lv_cddCustInfo.rlnm_cnf_no_encr // 실명확인번호
      this.lv_submit_data.bfcer_rlnm_no_knd_cd = this.lv_cddCustInfo.bfcer_rlnm_no_knd_cd // 실명번호종류코드
      this.lv_submit_data.bfcer_dl_sc_cd = '3' // 수익증권거래구분코드
      this.lv_submit_data.upp_trt_srn_no = this.popupObj.parent_tr_cd // 상위처리화면번호
      this.lv_submit_data.aml_refr_dtm = this.popupObj.aml_refr_dtm // AML참조일시
      this.lv_submit_data.bfcer_aml_busn_sc_cd = this.popupObj.aml_busi_gb // 수익증권AML업무구분코드
      this.lv_submit_data.bfcer_trt_amt = this.popupObj.trt_amt // 수익증권처리금액

      this.lv_submit_data.bfcer_accn_no = this.popupObj.acct_no // 수익증권계좌번호
      this.lv_submit_data.bfcer_wl_nvtn_rslt_cd = this.lv_me_edd_pep.value // 수익증권WL대사결과코드
      this.lv_submit_data.aml_sanc_trt_msg_cntnt = this.lv_aml_sanc_trt_msg_cntnt.value // AML결재처리메시지내용

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570290_S'
      this.eaiCommObj.params = this.lv_submit_data

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SubmitResult)
    },

    fn_SubmitResult(pResultData) {
      console.log('fn_SubmitResult...')
      let lv_vm = this
      let t_data = pResultData.data
      console.log(t_data)

      if (t_data.error_msg === '809901' && this.lv_rad_edd_inf_reci_yn.value === 'Y') {
        let t_popupObj = {
          content: ['승인 요청 되었습니다.', 'FOSS [1650] 고위험승인내역 확인 바랍니다.'],
          cancel: false,
          confirm: true,
          confirmFunc: function() {
            lv_vm.pPopup306Obj = {
              acct_no: lv_vm.popupObj.acct_no,
              call_tp: lv_vm.lv_me_edd_pep.value,
              ref_no: lv_vm.popupObj.ref_no,
              busi_gb: lv_vm.popupObj.aml_busi_gb,
              aml_pphse: lv_vm.lv_aml_pphse,
              wl_samr_yn: 'Y',
              edd_inf_reci_yn: lv_vm.lv_rad_edd_inf_reci_yn.value,
              aml_refr_dtm: lv_vm.popupObj.aml_refr_dtm,
              cust_nm: lv_vm.lv_cddCustInfo.cust_cnf_syst_cust_nm
            }
            // AML 처리하는 실명번호 구분 제한
            if (
              lv_vm.lv_cddCustInfo.bfcer_rlnm_no_knd_cd == '01' ||
              lv_vm.lv_cddCustInfo.bfcer_rlnm_no_knd_cd == '04' ||
              lv_vm.lv_cddCustInfo.bfcer_rlnm_no_knd_cd == '05' ||
              lv_vm.lv_cddCustInfo.bfcer_rlnm_no_knd_cd == '06'
            ) {
              lv_vm.$refs.popup306.fn_Open()
            } else {
              lv_vm.fn_Popup_CallBack(lv_vm.popupObj)
              lv_vm.fn_Close()
            }
          }
        }
        lv_vm.fn_AlertPopup(0, t_popupObj)
      } else {
        lv_vm.fn_AlertPopup(0, { content: 'AML승인요청 에러 발생' })
      }
    },

    fn_TestSubmitResult() {
      console.log('fn_SubmitResult...')

      // let t_data = pResultData.data
      // console.log(t_data)

      this.pPopup306Obj = {
        acct_no: this.popupObj.acct_no,
        call_tp: this.lv_me_edd_pep.value,
        ref_no: this.popupObj.ref_no,
        busi_gb: this.popupObj.aml_busi_gb,
        aml_pphse: this.lv_aml_pphse,
        wl_samr_yn: 'Y',
        edd_inf_reci_yn: this.lv_rad_edd_inf_reci_yn.value,
        aml_refr_dtm: this.popupObj.aml_refr_dtm,
        cust_nm: this.lv_cddCustInfo.cust_cnf_syst_cust_nm
      }
      this.$refs.popup306.fn_Open()
    }
  }
}
</script>
<style scoped></style>
