/*
 * 업무구분: 영업
 * 화 면 명: MSPFS322P
 * 화면설명: 지역단FP찾기
 * AS-IS: SY990400P
 * 작 성 일: 2023.06.01
 * 작 성 자: 이지수
 */
<template>

  <mo-modal class="fts-modal large" ref="modal" title="지역단FP">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label> 사원번호 </label>
              <mo-text-field 
                numeric
                mask="##########"
                type='number'  
                clearable 
                placeholder="입력하세요" 
                v-model="staf_eno" 
                class="w130" 
                />
            </div>
            <div class="wrap-input row">
              <label> 성명 </label>
              <mo-text-field clearable placeholder="입력하세요" v-model="bfcer_eply_nm" ref="eplyNm" class="w130" />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button primary class="btn-inquiry" @click="fn_selectDetail" > 조회 </mo-button>
            </div>
          </div>
        </div>
        <div class="wrap-table mt16 h-scroll">
          <table class="table row-type">
            <!-- <colgroup>
              <col width="40px">
              <col width="49%">
              <col width="49%">
            </colgroup> -->
            <thead>
              <tr>
                <th class="w40"> </th>
                <th class="w49p"> 사원번호 </th>
                <th class="w49p"> 성명 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in ds_list" :key="idx" :class="{'checked': lv_select_radio == String(idx+1)}" @click="lv_select_radio = String(idx+1)">
                <td>
                  <mo-radio v-model="lv_select_radio" :value="`${String(idx+1)}`"></mo-radio>
                </td>
                <td>
                  {{row.staf_eno}}
                </td>
                <td>
                  {{row.bfcer_eply_nm}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
  </mo-modal>

</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
   ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
     ***********************************************************************************/
  name: 'MSPFS322P',
  screenId: 'MSPFS322P',
  props: {
    popupObj: {type: Object, default: null}
  },
  components: {

  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
     ***********************************************************************************/
  created () {
  },
  mounted () {
    this.$bizUtil.insSrnLog('MSPFS322P')
  },
  /***********************************************************************************
     * 화면변수 선언 영역
     ***********************************************************************************/
  data () {
    return {
      ds_list: [],
      bfcer_eply_nm: '',
      staf_eno: '',
      lv_select_radio: null,

      /***********************************************************************************
         * F10570197 : 지역단FP찾기(임직원목록조회)
         ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()

    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
     ***********************************************************************************/
  computed: {
    modal () {
      return this.$refs.modal
    }

  },
  /***********************************************************************************
     * watch 함수 정의 영역
     ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
     ***********************************************************************************/
  methods: {
    fn_init () {
      this.ds_list = []
      this.staf_eno = ''
      this.bfcer_eply_nm = ''
      this.lv_select_radio = null
      if (!FSCommUtil.gfn_isNull(this.popupObj.pStaf_eno) || !FSCommUtil.gfn_isNull(this.popupObj.pEply_nm)) {
        this.staf_eno = this.parent.pStaf_eno
        this.bfcer_eply_nm = this.parent.pEply_nm
      }
    },

    fn_selectDetail () {
      this.ds_list = []
      if (FSCommUtil.gfn_length(this.staf_eno) + FSCommUtil.gfn_length(this.bfcer_eply_nm) < 1) {
        FSCommUtil.gfn_validate(this, '사원번호, 사원명 둘중 하나는 2글자 이상 입력하여 주십시요!')
        return
      }
      if (FSCommUtil.gfn_length(this.staf_eno) > 0 && FSCommUtil.gfn_length(this.staf_eno) < 2) {
        FSCommUtil.gfn_validate(this, '사원번호는 2글자 이상 입력하셔야 합니다.')
        return
      } else if (FSCommUtil.gfn_length(this.bfcer_eply_nm) > 0 && FSCommUtil.gfn_length(this.bfcer_eply_nm) < 2) {
        FSCommUtil.gfn_validate(this, '사원명을 2글자 이상 입력하셔야 합니다.')
        return
      }
      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570197_S'
      this.eaiCommObj.params = {
        staf_eno: this.staf_eno,
        bfcer_eply_nm: this.bfcer_eply_nm,
        bfcer_fnd_cd: this.popupObj.pBfcer_fnd_cd,
        bfcer_qlfd_typ_cd: this.popupObj.pFund_type
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    fn_callBack (response) {
      this.ds_list = response.data.sy990400_O_00VO
      if ( FSCommUtil.gfn_length(this.ds_list) === 0 ) {
        FSCommUtil.gfn_validate(this, '해당 펀드의 권유자격을 가진 직원이 존재하지 않습니다.')
      }
    },

    fn_Confirm () {
      if ( FSCommUtil.gfn_isNull(this.lv_select_radio) ) {
        FSCommUtil.gfn_validate(this, '사원이 존재하지 않습니다.')
        return
      }
      // 메인 화면으로 고객번호 값 넘긴 후 팝업 종료
      this.$emit('fs-popup-callback', 'MSPFS322P', this.ds_list[this.lv_select_radio - 1], this.popupObj.subId)
      this.modal.close()
    },

    fn_Open () {
      setTimeout(() => {
        this.fn_init()

        if ( FSCommUtil.gfn_isNull(this.popupObj.pFund_type) ) {
          FSCommUtil.gfn_validate(this, '펀드를 먼저 선택해 주시기 바랍니다.')
        } else {
          this.modal.open()
        }
      }, 10)
    },

    fn_Close () {
      this.modal.close()
    }

  }
}
</script>
<style scoped>
</style>
