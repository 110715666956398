/**
 * <PRE>
 * System Name : 사랑On NEXT 시스템(TSS2-MSP)
 * Business Name : 수익증권
 * Class Name : FSDateUtil.js
 * Description : 수익증권 공통 유틸 (AS-IS: ComDate.js)
 * </PRE>
 */

  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'

  
  const FSDateUtil = {}



  /************************************************************************************************
   * Function   : gfn_getSalesYmd(nDay) 
   * 설명       : 해당 서버의 N일 전/후의 영업일자를 구한다
   * Parameter  : nDay(int)
   * Return     : String - yyyymmdd
   ************************************************************************************************/
  FSDateUtil.gfn_getSalesYmd = async function(pContext, nDay) {
    console.log('gfn_getSalesYmd...')

    const context = !pContext ? this : pContext
    let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

    t_eaiCommObj.lv_vm = context
    t_eaiCommObj.auth = 'S'
    t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570264_S'
    t_eaiCommObj.params = {
      days : nDay,
    }

    let t_result = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

    return t_result.data.sy000200_O_00VO[0].sales_ymd
  }




  export default FSDateUtil
  // eslint-disable-next-line
