/* 
* 업무구분: 영업 
* 화 면 명: MSPFS306P 
* 화면설명: EDD_PEP추가정보입력_P(개인) CU009912P.xfdl 
* 작 성 일: 2023.05.18 
* 작 성 자: 이태흥 
* 수정일 : 2024-06-18 강현철  사유 : ASR240600395 고위험승인내역 화면 개선 요청
*/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="EDD, PEP 추가정보 입력">
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-table">
          <table class="table col-type col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span> 성명 </span>
                </th>
                <td colspan="3">
                  <mo-text-field v-model="lv_cust_nm.value" placeholder=" " :disabled="lv_cust_nm.disabled" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 직장인여부 </span>
                </th>
                <td colspan="3">
                  <mo-radio-wrapper :items="radio1" v-model="lv_rad_slry_yn.value" class="row" :class="lv_rad_slry_yn.error ? 'error' : ''" :disabled="lv_rad_slry_yn.disabled" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 부서 </span>
                </th>
                <td>
                  <mo-text-field ref="job_dept" v-model="lv_job_dept.value" class="input-long" :class="lv_job_dept.error ? 'error' : ''" placeholder="입력하세요" :disabled="lv_job_dept.disabled" />
                </td>
                <th>
                  <span> 사업자등록번호 </span>
                </th>
                <td>
                  <mo-text-field
                    ref="biz_reg_no"
                    v-model="lv_biz_reg_no.value"
                    class="input-long"
                    :class="lv_biz_reg_no.error ? 'error' : ''"
                    placeholder="입력하세요"
                    :disabled="lv_biz_reg_no.disabled"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 직위 </span>
                </th>
                <td>
                  <mo-text-field
                    ref="job_title"
                    v-model="lv_job_title.value"
                    class="input-long"
                    :class="lv_job_title.error ? 'error' : ''"
                    placeholder="입력하세요"
                    :disabled="lv_job_title.disabled"
                  />
                </td>
                <th>
                  <span> 사업체 설립일 </span>
                </th>
                <td>
                  <mo-date-picker v-model="lv_biz_open_dt.value" :bottom="false" class="input-long" :class="lv_biz_open_dt.error ? 'error' : ''" :disabled="lv_biz_open_dt.disabled" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 입사일자 </span>
                </th>
                <td>
                  <mo-date-picker v-model="lv_job_ent_dt.value" :bottom="false" class="input-long" :class="lv_job_ent_dt.error ? 'error' : ''" :disabled="lv_job_ent_dt.disabled" />
                </td>
                <th>
                  <span> 자금의 원천/출처 </span>
                </th>
                <td>
                  <mo-dropdown :items="lv_commCode.code405" v-model="lv_cob_fund_pl_gb.value" placeholder="선택하세요" :disabled="lv_cob_fund_pl_gb.disabled" ref="dropdown1" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 자산현황 </span>
                </th>
                <td>
                  <mo-dropdown :items="lv_commCode.code406" v-model="lv_cob_prsm_aset_gb.value" placeholder="선택하세요" :disabled="lv_cob_prsm_aset_gb.disabled" ref="dropdown2" />
                </td>
                <th>
                  <span> 거래목적 </span>
                </th>
                <td>
                  <mo-dropdown :items="lv_commCode.code411" v-model="lv_cob_ent_rsn.value" placeholder="선택하세요" :disabled="lv_cob_ent_rsn.disabled" ref="dropdown3" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 직업구분 </span>
                </th>
                <td>
                  <mo-dropdown :items="lv_commCode.code217" v-model="lv_cob_job_gb.value" placeholder="선택하세요" :disabled="lv_cob_job_gb.disabled" ref="dropdown4" />
                </td>
                <th>
                  <span> 업종 </span>
                </th>
                <td>
                  <mo-dropdown :items="lv_commCode.code400" v-model="lv_cob_indcl_sort.value" placeholder="선택하세요" :disabled="lv_cob_indcl_sort.disabled" ref="dropdown5" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 회사명 </span>
                </th>
                <td colspan="3">
                  <mo-text-field v-model="lv_ed_o_nm.value" class="input-long" placeholder="입력하세요" :disabled="lv_ed_o_nm.disabled" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="wrap-table-title row justify-between">
          <div class="row gap20">
            <span>예금주가 고객이 아닌 경우 ('예'인 경우 아래 정보 입력)</span>
            <mo-radio-wrapper :items="radio2" v-model="lv_rad_chk1.value" class="row gap6" :disabled="lv_rad_chk1.disabled" />
          </div>
          <div class="row gap6">
            <mo-button :disabled="lv_add_del_btn" @click="fn_AddList()">추가</mo-button>
            <mo-button :disabled="lv_add_del_btn" @click="fn_DelList()">삭제</mo-button>
          </div>
        </div>

        <div class="right-bg-white mt10">
          <div class="wrap-table x-scroll h-scroll">
            <table class="table row-type">
              <thead>
                <tr>
                  <th rowspan="2"></th>
                  <th colspan="2">성명</th>
                  <th>구분</th>
                  <th>실명번호</th>
                  <th>관계</th>
                  <th>국적</th>
                  <th>생년월일</th>
                  <th>직업</th>
                </tr>
                <tr>
                  <th class="bl-ddd" colspan="2">체크(본인주소와 동일)</th>
                  <th colspan="5">주소</th>
                  <th>전화번호</th>
                </tr>
              </thead>
              <tbody v-for="(row, idx) in lv_cust_list" :key="idx">
                <tr>
                  <td rowspan="2">
                    <mo-checkbox :value="'checked_' + idx" v-model="row.checked"></mo-checkbox>
                  </td>
                  <td colspan="2">
                    <div class="wrap-input row">
                      <mo-text-field v-model="row.cust_nm" class="input-long" placeholder="입력" />
                    </div>
                  </td>
                  <td>
                    <mo-dropdown :items="lv_commCode.code211" v-model="row.reg_no_gb" placeholder="선택" class="w150" ref="dropdown6" />
                  </td>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field v-model="row.reg_dob" type="number" placeholder="앞 6자리" maxlength="6" class="w80" />
                      <m-trans-key-input
                        class="w80"
                        v-if="isMobile && isMtrans"
                        v-model="row.reg_fin"
                        type="numberMax7"
                        start="1"
                        end="-1"
                        dialog="Y"
                        :key="lv_select_row"
                        @masked-txt="fn_SetMaskedTxt"
                      />
                      <mo-text-field v-else v-model="row.reg_fin" type="password" placeholder="뒤 7자리" maxlength="7" class="w80" />
                    </div>
                  </td>
                  <td>
                    <mo-dropdown :items="lv_commCode.code410" v-model="row.aml_rel_spec" placeholder="선택" class="w150" ref="dropdown7" />
                  </td>
                  <td>
                    <mo-dropdown :items="lv_commCode.code981" v-model="row.naty_cd" placeholder="선택" class="w150" ref="dropdown8" />
                  </td>
                  <td>
                    <div class="picker row">
                      <mo-date-picker :bottom="false" v-model="row.real_dob_date" class="w130" />
                    </div>
                  </td>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown :items="lv_commCode.code217" v-model="row.insr_indcl_cd" placeholder="선택" ref="dropdown9" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bl-ddd">
                    <div class="wrap-input row">
                      <mo-checkbox :value="'addr_checked_' + idx" v-model="row.addr_checked"></mo-checkbox>
                    </div>
                  </td>
                  <td>
                    <div class="wrap-input row">
                      <p class="w60">{{ row.pst_no1 }}{{ row.pst_no2 }}</p>
                      <mo-button class="btn-zip" @click="fn_OpenPopup('MSPFS100P', idx, 'C')"> 우편번호 </mo-button>
                    </div>
                  </td>
                  <td colspan="5">
                    <div class="wrap-input row">
                      <mo-text-field v-model="row.pst_addr" class="input-long" placeholder="입력" />
                      <mo-text-field v-model="row.dtl_addr" class="input-long" placeholder="입력" />
                    </div>
                  </td>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field v-model="row.tel_ddd" placeholder="입력" class="w60" maxlength="3" />
                      <mo-text-field v-model="row.tel_guk" placeholder="입력" class="w60" maxlength="4" />
                      <mo-text-field v-model="row.tel_no_seq" placeholder="입력" class="w60" maxlength="4" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--법인추가 st-->
        <div class="wrap-table-title row justify-between">
          <div class="row gap20">
            <span>고객이 관련된 법인이 있는 경우 ('예'인 경우 아래 정보 입력)</span>
            <mo-radio-wrapper :items="radio3" v-model="lv_rad_chk2.value" class="row gap6" :disabled="lv_rad_chk2.disabled" />
          </div>
          <div class="row gap6">
            <mo-button :disabled="lv_add_del_btn1" @click="fn_AddList1()">추가</mo-button>
            <mo-button :disabled="lv_add_del_btn1" @click="fn_DelList1()">삭제</mo-button>
          </div>
        </div>

        <div class="right-bg-white mt10">
          <div class="wrap-table x-scroll h-scroll">
            <table class="table row-type">
              <thead>
                <tr>
                  <th rowspan="2"></th>
                  <th colspan="3" rowspan="2">법인/단체명</th>
                  <th>사업자등록번호</th>
                  <th>직위</th>
                  <th>지분율</th>
                  <th>국적</th>
                  <th colspan="2">업종</th>
                </tr>
                <tr>
                  <th class="bl-ddd" colspan="4">주소</th>
                  <th colspan="2">전화번호</th>
                </tr>
              </thead>
              <tbody v-for="(row, idx) in lv_corp_list" :key="idx">
                <tr>
                  <td rowspan="2">
                    <mo-checkbox :value="'checked_' + idx" v-model="row.checked"></mo-checkbox>
                  </td>
                  <td colspan="3">
                    <div class="wrap-input row">
                      <mo-text-field v-model="row.cust_nm" class="input-long" placeholder="입력" />
                    </div>
                  </td>
                  <td>
                    <mo-decimal-field numeric mask="###-##-####" class="w130" v-model="row.corp_reg_no" placeholder="입력" />
                  </td>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="w120" v-model="row.psit_nm" placeholder="입력" />
                    </div>
                  </td>
                  <td>
                    <div class="wrap-input row"><mo-text-field class="w80" v-model="row.shar_rto" placeholder="입력" /> %</div>
                  </td>
                  <td>
                    <mo-dropdown :items="lv_commCode.code981" v-model="row.naty_cd" placeholder="선택" class="w120" ref="dropdown10" />
                  </td>
                  <td colspan="2">
                    <mo-dropdown :items="lv_commCode.code217" v-model="row.insr_job_cd" placeholder="선택" class="input-long" ref="dropdown11" />
                  </td>
                </tr>

                <tr>
                  <td class="bl-ddd" colspan="2">
                    <div class="wrap-input row">
                      <p class="w60">{{ row.pst_no1 }}{{ row.pst_no2 }}</p>
                      <mo-button class="btn-zip" @click="fn_OpenPopup('MSPFS100P', idx, 'E')"> 우편번호 </mo-button>
                    </div>
                  </td>
                  <td colspan="5">
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" v-model="row.pst_addr" placeholder="입력" />
                      <mo-text-field class="input-long" v-model="row.dtl_addr" placeholder="입력" />
                    </div>
                  </td>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field placeholder="입력" class="w60" v-model="row.tel_ddd" maxlength="3" />
                      <mo-text-field placeholder="입력" class="w60" v-model="row.tel_guk" maxlength="4" />
                      <mo-text-field placeholder="입력" class="w60" v-model="row.tel_no_seq" maxlength="4" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--법인추가 ed-->
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Submit()">확인</mo-button>

        <!-- <mo-button primary size="large" @click="fn_ReportPrint()">전자서식테스트</mo-button> -->
      </div>
    </template>

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>

    <!-- popup100 -->
    <msp-fs-100p ref="popup100" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup100Obj"></msp-fs-100p>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import MSPFS100P from '~/src/ui/fs/MSPFS100P' // 우편번호 (공통)

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS306P',
  screenId: 'MSPFS306P',
  props: {
    popupObj: { type: Object, default: null }
  },
  components: {
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-100p': MSPFS100P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
  },
  mounted() {
    this.$bizUtil.insSrnLog('MSPFS306P')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pAlertPopupObj: {},
      lv_commCode: {},

      // 팝업 객체
      pPopup100Obj: {},

      // 입력사항
      lv_cust_nm: {}, // 성명

      lv_rad_slry_yn: {}, // 직장인여부

      lv_job_dept: {}, // 부서
      lv_biz_reg_no: {}, // 사업자등록번호

      lv_job_title: {}, // 직위
      lv_biz_open_dt: {}, // 사업체 설립일

      lv_job_ent_dt: {}, // 입사일자
      lv_cob_fund_pl_gb: {}, // 자금의 원천/출처

      lv_cob_prsm_aset_gb: {}, // 자산현황
      lv_cob_ent_rsn: {}, // 거래목적

      lv_cob_job_gb: {}, // 직업구분
      lv_cob_indcl_sort: {}, // 업종

      lv_ed_o_nm: {}, // 회사명

      // list 예금주
      lv_rad_chk1: {}, // 예금주고객여부
      lv_add_del_btn: false, // 추가/삭제 버튼
      lv_cust_list: [], // 예금주고객여부 list

      // list 법인
      lv_rad_chk2: {}, // 법인고객여부
      lv_add_del_btn1: false, // 추가/삭제 버튼
      lv_corp_list: [], // 법인고객여부 list

      // 조회결과
      lv_cddCustInfo: {}, // CDD고객정보조회결과

      // submit data
      lv_edd_info: {},

      lv_submit_data: {},

      lv_select_row: '',
      isMobile: this.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans, // 보안키패드 적용 여부

      /***********************************************************************************
       * F10570117 : CDD고객정보조회
       * F10570291 : EDD/PEP결재승인
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

      customerInfo2: [
        { value1: '', value2: '', value3_1: '', value3_2: '', value4: '', value5: '', value6: '', value7: '', value8: '', value9: '', value10: '', value11: '', value12: '', value13: '' }
      ],
      valueRadio3: '',
      accountCheck3: false,
      accountCheck4: false
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    modal() {
      return this.$refs.modal
    },

    radio1() {
      let rtn = []
      rtn.push({ value: 'Y', text: '직장인' })
      rtn.push({ value: 'N1', text: '개인사업자' })
      rtn.push({ value: 'N2', text: '직장정보없음' })
      return rtn
    },

    radio2() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },

    radio3() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 직장인여부 선택
    'lv_rad_slry_yn.value': {
      handler(after, before) {
        if (after == 'Y') {
          // 직장인
          this.lv_biz_reg_no.value = ''
          this.lv_biz_reg_no.disabled = true

          this.lv_biz_open_dt.value = ''
          this.lv_biz_open_dt.disabled = true

          this.lv_job_dept.disabled = false
          this.lv_job_title.disabled = false
          this.lv_job_ent_dt.disabled = false
        } else if (after == 'N1') {
          // 개인사업자
          this.lv_job_dept.value = ''
          this.lv_job_dept.disabled = true

          this.lv_job_title.value = ''
          this.lv_job_title.disabled = true

          this.lv_job_ent_dt.value = ''
          this.lv_job_ent_dt.disabled = true

          this.lv_biz_reg_no.disabled = false
          this.lv_biz_open_dt.disabled = false
        } else if (after == 'N2') {
          // 직장정보없음
          this.lv_job_dept.value = ''
          this.lv_job_dept.disabled = true

          this.lv_job_title.value = ''
          this.lv_job_title.disabled = true

          this.lv_job_ent_dt.value = ''
          this.lv_job_ent_dt.disabled = true

          this.lv_biz_reg_no.value = ''
          this.lv_biz_reg_no.disabled = true

          this.lv_biz_open_dt.value = ''
          this.lv_biz_open_dt.disabled = true
        }
      },
      deep: true
    },

    // 예금주고객여부 선택
    'lv_rad_chk1.value': {
      handler(after, before) {
        if (after == 'N') {
          // 추가/삭제 버튼 비활성화
          this.lv_add_del_btn = true
        } else {
          // 추가/삭제 버튼 활성화
          this.lv_add_del_btn = false
        }
      },
      deep: true
    },

    // 법인고객여부 선택
    'lv_rad_chk2.value': {
      handler(after, before) {
        if (after == 'N') {
          // 추가/삭제 버튼 비활성화
          this.lv_add_del_btn1 = true
        } else {
          // 추가/삭제 버튼 활성화
          this.lv_add_del_btn1 = false
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_SetMaskedTxt(val) {
      const target = this[this.lv_select_row]
      target.masked_val = val
    },
    fn_Open() {
      // 초기화
      this.fn_Init()

      setTimeout(() => {
        // CDD고객정보조회
        this.fn_CddCustInfo()

        // EDD일 경우 비활성화
        if (this.popupObj.call_tp == 'EDD') {
          this.lv_rad_chk1.disabled = true
          this.lv_add_del_btn = true

          //법인
          this.lv_rad_chk2.disabled = true
          this.lv_add_del_btn1 = true
        }
      }, 100)

      this.modal.open()
    },
    fn_Close() {
      // 2024.06.18 ASR240600395 고위험승인내역 화면 개선 요청
      this.$emit('fs-popup-callback', this.popupObj)
      this.modal.close()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
      this.$refs.dropdown2.close()
      this.$refs.dropdown3.close()
      this.$refs.dropdown4.close()
      this.$refs.dropdown5.close()
      for (let i = 0; i < this.lv_cust_list.length; i++) {
        this.$refs.dropdown6[i].close()
        this.$refs.dropdown7[i].close()
        this.$refs.dropdown8[i].close()
        this.$refs.dropdown9[i].close()
      }

      for (let i = 0; i < this.lv_corp_list.length; i++) {
        this.$refs.dropdown10[i].close()
        this.$refs.dropdown11[i].close()
      }
    },

    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        { cId: '411', dayChkYn: 'N' }, // 거래목적
        { cId: '405', dayChkYn: 'N' }, // 자금의 원천/출처
        { cId: '406', dayChkYn: 'N' }, // 자산현황
        { cId: '410', dayChkYn: 'N' }, // AML관계코드
        { cId: '981', dayChkYn: 'N' }, // 국적
        { cId: '217', dayChkYn: 'N' }, // 직업구분
        { cId: '400', dayChkYn: 'N' }, // 업종
        { cId: '211', dayChkYn: 'N' } // 실명번호종류
      ]

      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult

      // 실명번호종류 코드 재설정
      let t_codeList = []
      pResult.code211.forEach((item) => {
        if (item.value == '01' || item.value == '04') {
          t_codeList.push(item)
        }
      })
      this.lv_commCode.code211 = t_codeList
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS306P] fn_Init...')

      // 입력사항
      this.lv_cust_nm = FSCommUtil.gfn_dataSet(0, '', true) // 성명

      this.lv_rad_slry_yn = FSCommUtil.gfn_dataSet(0, '') // 직장인여부

      this.lv_job_dept = FSCommUtil.gfn_dataSet(0, '') // 부서
      this.lv_biz_reg_no = FSCommUtil.gfn_dataSet(0, '') // 사업자등록번호

      this.lv_job_title = FSCommUtil.gfn_dataSet(0, '') // 직위
      this.lv_biz_open_dt = FSCommUtil.gfn_dataSet(0, '') // 사업체 설립일

      this.lv_job_ent_dt = FSCommUtil.gfn_dataSet(0, '') // 입사일자
      this.lv_cob_fund_pl_gb = FSCommUtil.gfn_dataSet(0, '01') // 자금의 원천/출처

      this.lv_cob_prsm_aset_gb = FSCommUtil.gfn_dataSet(0, '1') // 자산현황
      this.lv_cob_ent_rsn = FSCommUtil.gfn_dataSet(0, '01') // 거래목적

      this.lv_cob_job_gb = FSCommUtil.gfn_dataSet(0, '01') // 직업구분
      this.lv_cob_indcl_sort = FSCommUtil.gfn_dataSet(0, '10') // 업종

      this.lv_ed_o_nm = FSCommUtil.gfn_dataSet(0, '') // 회사명

      // list
      this.lv_rad_chk1 = FSCommUtil.gfn_dataSet(0, '') // 예금주고객여부
      this.lv_add_del_btn = false // 추가/삭제 버튼

      this.lv_cust_list = [] // 예금주고객여부 list

      // 법인 list
      this.lv_rad_chk2 = FSCommUtil.gfn_dataSet(0, '') // 법인고객여부
      this.lv_add_del_btn1 = false // 추가/삭제 버튼

      this.lv_corp_list = [] // 법인고객여부 list

      // 조회결과
      this.lv_cddCustInfo = {}

      // submit data
      this.lv_edd_info = {
        ent_rsn: '',
        fund_pl_gb: '',
        job_dept: '',
        job_ent_dt: '',
        job_nm: '',
        job_title: '',
        biz_reg_no: '',
        biz_open_dt: '',
        prsm_aset_gb: '',
        ref_no: '',
        busi_gb: '',
        aml_pphse: '',
        wl_samr_yn: '',
        edd_inf_reci_yn: '',
        slry_yn: '',
        acct_no: '',
        call_tp: ''
      }

      this.lv_submit_data = {}
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) 
          MSPFS100P : 우편번호
       ******************************************************************************/
    fn_OpenPopup(type, rowIdx, gb) {
      switch (type) {
        case 'MSPFS100P':
          this.pPopup100Obj.callbackType = 'MSPFS100P'
          this.pPopup100Obj.rowIdx = rowIdx
          this.pPopup100Obj.gb = gb
          this.$refs.popup100.fn_Open()
          break
      }
    },

    /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type)
          MSPFS100P : 우편번호
       ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      switch (type) {
        case 'MSPFS100P':
          console.log('우편번호 callback...')
          console.log(pData)
          if (this.pPopup100Obj.gb == 'C') {
            this.lv_cust_list[pData.rowIdx].pst_no1 = pData.vZipcd_head
            this.lv_cust_list[pData.rowIdx].pst_no2 = pData.vZipcd_tail
            this.lv_cust_list[pData.rowIdx].pst_addr = pData.vZipcd_ubmyundong + ' ' + pData.v_bldg_no + ' ' + pData.v_stand_addr
            this.lv_cust_list[pData.rowIdx].dtl_addr = pData.v_adtn_addr
          } else if (this.pPopup100Obj.gb == 'E') {
            this.lv_corp_list[pData.rowIdx].pst_no1 = pData.vZipcd_head
            this.lv_corp_list[pData.rowIdx].pst_no2 = pData.vZipcd_tail
            this.lv_corp_list[pData.rowIdx].pst_addr = pData.vZipcd_ubmyundong + ' ' + pData.v_bldg_no + ' ' + pData.v_stand_addr
            this.lv_corp_list[pData.rowIdx].dtl_addr = pData.v_adtn_addr
          }
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_CddCustInfo, fn_CddCustInfoResult
     * 설명       : CDD고객정보조회
     ******************************************************************************/
    fn_CddCustInfo() {
      console.log('fn_CddCustInfo...')

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570117_S'
      this.eaiCommObj.params = {
        bfcer_accn_no: this.popupObj.acct_no
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CddCustInfoResult)
    },

    fn_CddCustInfoResult(pResultData) {
      console.log('fn_CddCustInfoResult...')

      let t_data = pResultData.data
      console.log(t_data)

      // CDD고객정보 매핑
      this.lv_cddCustInfo = t_data

      // 성명 매핑
      this.lv_cust_nm.value = this.lv_cddCustInfo.cust_cnf_syst_cust_nm
    },

    /******************************************************************************
     * Function명 : fn_AddList
     * 설명       : LIST 추가
     ******************************************************************************/
    fn_AddList() {
      console.log('fn_AddList...')

      let t_cust_list_data = this.fn_PepDataSet()

      if (this.lv_cust_list.length < 3) {
        t_cust_list_data['checked'] = false
        t_cust_list_data['addr_checked'] = false

        this.lv_cust_list.push(t_cust_list_data)
      }
    },

    /******************************************************************************
     * Function명 : fn_AddList1
     * 설명       : LIST 추가
     ******************************************************************************/
    fn_AddList1() {
      console.log('fn_AddList1...')

      let t_corp_list_data = this.fn_PepDataSet1()

      if (this.lv_corp_list.length < 3) {
        t_corp_list_data['checked'] = false
        t_corp_list_data['addr_checked'] = false

        this.lv_corp_list.push(t_corp_list_data)
      }
    },

    /******************************************************************************
     * Function명 : fn_DelList
     * 설명       : LIST 삭제
     ******************************************************************************/
    fn_DelList() {
      console.log('fn_DelList...')

      let lv_vm = this
      let t_popupObj = {}
      let t_new_list = []
      let t_cnt = 0

      this.lv_cust_list.forEach(function(item) {
        if (item['checked']) {
          t_cnt = t_cnt + 1
        }
      })

      if (t_cnt == 0) {
        FSCommUtil.gfn_validate(this, '현재 선택된 행이 없습니다.')
        return
      }

      t_popupObj = {
        confirm: true,
        confirmFunc: function() {
          lv_vm.lv_cust_list.forEach(function(item, idx) {
            if (item['checked'] == false) {
              t_new_list.push(item)
            }
          })

          lv_vm.lv_cust_list = t_new_list
        },
        content: '현재 선택된 행을 삭제 하시겠습니까?'
      }
      this.fn_AlertPopup(0, t_popupObj)
    },

    /******************************************************************************
     * Function명 : fn_DelList1
     * 설명       : LIST 삭제
     ******************************************************************************/
    fn_DelList1() {
      console.log('fn_DelList1...')

      let lv_vm = this
      let t_popupObj = {}
      let t_new_list = []
      let t_cnt = 0

      this.lv_corp_list.forEach(function(item) {
        if (item['checked']) {
          t_cnt = t_cnt + 1
        }
      })

      if (t_cnt == 0) {
        FSCommUtil.gfn_validate(this, '현재 선택된 행이 없습니다.')
        return
      }

      t_popupObj = {
        confirm: true,
        confirmFunc: function() {
          lv_vm.lv_corp_list.forEach(function(item, idx) {
            if (item['checked'] == false) {
              t_new_list.push(item)
            }
          })

          lv_vm.lv_corp_list = t_new_list
        },
        content: '현재 선택된 행을 삭제 하시겠습니까?'
      }
      this.fn_AlertPopup(0, t_popupObj)
    },

    /******************************************************************************
     * Function명 : fn_PepDataSet
     * 설명       : PEP dataset
     ******************************************************************************/
    fn_PepDataSet() {
      console.log('fn_PepDataSet...')

      let t_pep_info = {
        agent_birth_dt: '',
        agent_enm: '',
        agent_passport_no: '',
        agent_rel_gb: '',
        agent_sex: '',
        aml_rel_cd: '11',
        aml_rel_spec: '',
        biz_open_dt: '',
        biz_reg_no: '',
        chk: '',
        corp_reg_no: '',
        cust_addr_chk: '',
        addr_checked: '',
        cust_enm: '',
        cust_id: '',
        cust_name: '',
        cust_nm: '',
        dom_lis_gb: '',
        dtl_addr: '',
        edd_corp_yn: '',
        edd_rel_yn: '',
        emp_cnt: '',
        ent_rsn: '',
        for_lis_gb: '',
        fund_pl_gb: '',
        insr_indcl_cd: '',
        insr_job_cd: '',
        job_dept: '',
        job_ent_dt: '',
        job_nm: '',
        job_title: '',
        lis_yn: '',
        naty_cd: '',
        o_nm: '',
        prsm_aset_gb: '',
        psit_nm: '',
        pst_addr: '',
        pst_no1: '',
        pst_no2: '',
        pst_no_seq: '',
        ql10_yn: '',
        real_dob: '',
        real_dob_date: '',
        reg_dob: '',
        reg_fin: '',
        reg_no_gb: '',
        shar_rto: '',
        ssn_dob: '',
        ssn_fin: '',
        tel_ddd: '',
        tel_guk: '',
        tel_no_seq: '',
        ref_no: '',
        busi_gb: '',
        aml_pphse: '',
        wl_samr_yn: '',
        edd_inf_reci_yn: '',
        slry_yn: ''
      }
      return t_pep_info
    },

    /******************************************************************************
     * Function명 : fn_PepDataSet1
     * 설명       : PEP dataset
     ******************************************************************************/
    fn_PepDataSet1() {
      console.log('fn_PepDataSet1...')

      let t_pep_info = {
        agent_birth_dt: '',
        agent_enm: '',
        agent_passport_no: '',
        agent_rel_gb: '',
        agent_sex: '',
        aml_rel_cd: '12',
        aml_rel_spec: '',
        biz_open_dt: '',
        biz_reg_no: '',
        chk: '',
        corp_reg_no: '',
        cust_addr_chk: '',
        addr_checked: '',
        cust_enm: '',
        cust_id: '',
        cust_name: '',
        cust_nm: '',
        dom_lis_gb: '',
        dtl_addr: '',
        edd_corp_yn: '',
        edd_rel_yn: '',
        emp_cnt: '',
        ent_rsn: '',
        for_lis_gb: '',
        fund_pl_gb: '',
        insr_indcl_cd: '',
        insr_job_cd: '',
        job_dept: '',
        job_ent_dt: '',
        job_nm: '',
        job_title: '',
        lis_yn: '',
        naty_cd: '',
        o_nm: '',
        prsm_aset_gb: '',
        psit_nm: '',
        pst_addr: '',
        pst_no1: '',
        pst_no2: '',
        pst_no_seq: '',
        ql10_yn: '',
        real_dob: '',
        real_dob_date: '',
        reg_dob: '',
        reg_fin: '',
        reg_no_gb: '07',
        shar_rto: '',
        ssn_dob: '',
        ssn_fin: '',
        tel_ddd: '',
        tel_guk: '',
        tel_no_seq: '',
        ref_no: '',
        busi_gb: '',
        aml_pphse: '',
        wl_samr_yn: '',
        edd_inf_reci_yn: '',
        slry_yn: ''
      }
      return t_pep_info
    },

    /******************************************************************************
     * Function명 : fn_Submit, fn_SubmitResult
     * 설명       : EDD/PEP결재승인
     ******************************************************************************/
    async fn_Submit() {
      console.log('fn_Submit...')

      // 초기화
      this.lv_rad_slry_yn.error = false
      this.lv_job_dept.error = false
      this.lv_job_title.error = false
      this.lv_job_ent_dt.error = false
      this.lv_cob_prsm_aset_gb.error = false
      this.lv_cob_fund_pl_gb.error = false

      if (FSCommUtil.gfn_isNull(this.lv_rad_slry_yn.value) == true) {
        FSCommUtil.gfn_validate(this, '직장인여부를 선택해 주세요.')
        this.lv_rad_slry_yn.error = true
        return
      }

      // 직장인일 경우
      if (this.lv_rad_slry_yn.value == 'Y') {
        if (FSCommUtil.gfn_isNull(this.lv_job_dept.value) == true) {
          FSCommUtil.gfn_validate(this, '부서명을 입력해 주세요.')
          this.lv_job_dept.error = true
          this.$refs['job_dept'].focus()
          return
        }

        if (FSCommUtil.gfn_isNull(this.lv_job_title.value) == true) {
          FSCommUtil.gfn_validate(this, '직위를 입력해 주세요.')
          this.lv_job_title.error = true
          this.$refs['job_title'].focus()
          return
        }

        if (FSCommUtil.gfn_isNull(this.lv_job_ent_dt.value) == true) {
          FSCommUtil.gfn_validate(this, '입사일을 입력해 주세요.')
          this.lv_job_ent_dt.error = true
          return
        }
      }

      if (FSCommUtil.gfn_isNull(this.lv_cob_prsm_aset_gb.value) == true) {
        FSCommUtil.gfn_validate(this, '자산현황을 선택해 주세요.')
        this.lv_cob_prsm_aset_gb.error = true
        return
      }

      if (FSCommUtil.gfn_isNull(this.lv_cob_fund_pl_gb.value) == true) {
        FSCommUtil.gfn_validate(this, '자금의 원천/출처를 선택해 주세요.')
        this.lv_cob_fund_pl_gb.error = true
        return
      }

      // EDD 데이터 세팅
      this.lv_edd_info.slry_yn = this.lv_rad_slry_yn.value == 'Y' ? 'Y' : 'N' // 직장인여부

      this.lv_edd_info.job_nm = this.lv_ed_o_nm.value // 회사명
      this.lv_edd_info.job_dept = this.lv_job_dept.value // 부서
      this.lv_edd_info.job_title = this.lv_job_title.value // 직위
      this.lv_edd_info.job_ent_dt = this.lv_job_ent_dt.value.replaceAll('-', '') // 입사일자
      console.log('job_ent_dt::>', this.lv_edd_info.job_ent_dt)
      this.lv_edd_info.biz_reg_no = this.lv_biz_reg_no.value // 사업자등록번호
      this.lv_edd_info.biz_open_dt = this.lv_biz_open_dt.value.replaceAll('-', '') // 사업체설립일
      this.lv_edd_info.prsm_aset_gb = this.lv_cob_prsm_aset_gb.value // 자산현황
      this.lv_edd_info.fund_pl_gb = this.lv_cob_fund_pl_gb.value // 자금의 원천/출처

      this.lv_edd_info.ref_no = this.popupObj.ref_no
      this.lv_edd_info.busi_gb = this.popupObj.busi_gb
      this.lv_edd_info.aml_pphse = this.popupObj.aml_pphse
      this.lv_edd_info.wl_samr_yn = this.popupObj.wl_samr_yn
      this.lv_edd_info.edd_inf_reci_yn = this.popupObj.edd_inf_reci_yn
      this.lv_edd_info.acct_no = this.popupObj.acct_no
      this.lv_edd_info.call_tp = this.popupObj.call_tp

      // EDD 데이터 매핑
      this.lv_submit_data = this.lv_edd_info

      // PEP 데이터 세팅
      let t_pep_info_all = []

      if (this.popupObj.call_tp == 'PEP') {
        t_pep_info_all = this.lv_cust_list

        t_pep_info_all.forEach((item, idx) => {
          // 본인주소와동일 체크값 치환
          if (item.addr_checked == 'addr_checked_' + idx) {
            item.cust_addr_chk = 'Y'
          } else {
            item.cust_addr_chk = 'N'
          }

          // 생년월일값 치환
          item.real_dob = item.real_dob_date.replace(/-/g, '')
        })

        if (t_pep_info_all.length == 0) {
          t_pep_info_all.push(this.fn_PepDataSet())
        }

        t_pep_info_all[0].cust_name = this.lv_cddCustInfo.cust_nm
        t_pep_info_all[0].ssn_dob = this.lv_cddCustInfo.rname_no
      }

      if (t_pep_info_all.length == 0) {
        t_pep_info_all.push(this.fn_PepDataSet())
      }

      t_pep_info_all[0].edd_rel_yn = 'Y'
      t_pep_info_all[0].edd_corp_yn = 'N'

      //법인정보추가
      if (this.lv_corp_list.length > 0) {
        this.lv_corp_list.forEach((item) => {
          t_pep_info_all.push(item)
        })
      }

      // PEP 데이터 매핑
      this.lv_submit_data.CU172000_O_00VO = t_pep_info_all
      console.log('lv_submit_data::>>>>>', this.lv_submit_data)

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570291_S'
      this.eaiCommObj.params = this.lv_submit_data

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SubmitResult)
    },

    fn_SubmitResult(pResultData) {
      console.log('fn_SubmitResult...')
      //리스트 초기화
      // this.lv_cust_list = []
      // this.lv_corp_list = []

      let t_popupObj = {}
      let t_data = pResultData.data
      console.log(t_data)

      if (t_data.error_msg == '809901') {
        t_popupObj = { content: '정상 등록 되었습니다.' }
        this.fn_AlertPopup(0, t_popupObj)

        // 전자서식 호출
        this.fn_ReportPrint()
        // 2024.06.18 ASR240600395 고위험승인내역 화면 개선 요청
        // this.$emit('fs-popup-callback', this.popupObj.acct_no)
        this.fn_Close()
      } else {
        t_popupObj = { content: '에러 발생하였습니다.' }
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    fn_ReportPrint() {
      console.log('fn_ReportPrint....')

      let custNm = ''
      let relGbNm = ''
      let rnameNo = ''
      let birthMmdd = ''
      let nationality = ''
      let addr = ''
      let entrTelNo = ''
      let upjong = ''

      // 업종명 SETTING
      let select3 = this.lv_cob_indcl_sort.value
      this.lv_commCode.code400.forEach(function(item) {
        if (item.value == select3) {
          upjong = item.text
        }
      })

      // 고객리스트 setting
      if (this.lv_rad_chk1.value == 'Y' && this.lv_cust_list.length > 0) {
        let select1 = this.lv_cust_list[0].aml_rel_spec
        let select2 = this.lv_cust_list[0].naty_cd

        this.lv_commCode.code410.forEach(function(item) {
          if (item.value == select1) {
            relGbNm = item.text
          }
        })

        this.lv_commCode.code981.forEach(function(item) {
          if (item.value == select2) {
            nationality = item.text
          }
        })

        custNm = this.lv_cust_list[0].cust_nm
        if (this.isMobile) {
          rnameNo = FSCommUtil.gfn_isNull(this.lv_cust_list[0].reg_dob) ? '' : this.lv_cust_list[0].reg_dob + '-' + this.lv_select_row[0].masked_val // this.lv_cust_list[0].reg_fin
        } else {
          rnameNo = FSCommUtil.gfn_isNull(this.lv_cust_list[0].reg_dob) ? '' : this.lv_cust_list[0].reg_dob + '-' + this.lv_cust_list[0].reg_fin
        }
        birthMmdd = this.lv_cust_list[0].real_dob_date
        addr = this.lv_cust_list[0].pst_addr + this.lv_cust_list[0].dtl_addr
        entrTelNo = FSCommUtil.gfn_isNull(this.lv_cust_list[0].tel_ddd) ? '' : this.lv_cust_list[0].tel_ddd + '-' + this.lv_cust_list[0].tel_guk + '-' + this.lv_cust_list[0].tel_no_seq
      }

      let prhsReCom = ''
      let estbPrhsRel = '' //직위
      let estbBsno = ''
      let insrStndIndsTypeCom = '' //업종
      let natC = ''
      let estbAddr = ''
      let estbTelNo = ''

      // 법인리스트 setting
      if (this.lv_rad_chk2.value == 'Y' && this.lv_corp_list.length > 0) {
        let select4 = this.lv_corp_list[0].naty_cd
        let select5 = this.lv_corp_list[0].insr_job_cd

        this.lv_commCode.code981.forEach(function(item) {
          if (item.value == select4) {
            natC = item.text
          }
        })

        // 업종명 SETTING
        this.lv_commCode.code400.forEach(function(item) {
          if (item.value == select5) {
            insrStndIndsTypeCom = item.text
          }
        })

        prhsReCom = this.lv_corp_list[0].cust_nm
        estbPrhsRel = this.lv_corp_list[0].psit_nm
        estbBsno = this.lv_corp_list[0].corp_reg_no //법인번호
        estbAddr = this.lv_corp_list[0].pst_addr + this.lv_corp_list[0].dtl_addr
        estbTelNo = FSCommUtil.gfn_isNull(this.lv_corp_list[0].tel_ddd) ? '' : this.lv_corp_list[0].tel_ddd + '-' + this.lv_corp_list[0].tel_guk + '-' + this.lv_corp_list[0].tel_no_seq
      }

      let custCnfSystCoNm = this.lv_ed_o_nm.value
      if (!FSCommUtil.gfn_isNull(this.lv_job_dept.value)) {
        custCnfSystCoNm = custCnfSystCoNm + '/' + this.lv_job_dept.value
      }

      let formList = []
      let FS000009 = { formId: 'FS000009' }

      FS000009.mappingData = {
        bfcerAccnPpsCd: this.lv_cob_ent_rsn.value, // 거래의목적(01.가족보장,02.노후준비,03.상속준비,04.자녀양육비,05.저축,07.재계약,99.기타)
        bfcerAccnPpsCdEtc: '', // 거래목적 기타
        bfcerFdSrcScCd: this.lv_cob_fund_pl_gb.value, // 자금의원천(01.근로및연금소득,02.퇴직소득,03.사업소득,04.부동산임대소득,05.부동산양도소득,06.금융소득,07.상속/증여,08.일시재산양도로인한소득,99.기타)
        bfcerFdSrcScCdEtc: '', // 자금의원천 기타
        asetPnstaScCd: this.lv_cob_prsm_aset_gb.value, // 추정자산(1.5억원미만,2.5억원이상-10억원미만,3.10억원이상-100억원미만,4.100억원이상-1000억원미만,5.1000억원이상)
        offwkYn: this.lv_rad_slry_yn.value, // 직장인여부(01.직장인,02.개인사업자,03.무직)
        custCnfSystCoNm: custCnfSystCoNm,
        custCnfSystJobpsNm: this.lv_job_title.value,
        ecmpYmd: this.lv_job_ent_dt.value,
        custCnfSystBsnno: this.lv_biz_reg_no.value,
        indclSort: upjong,
        openDt: this.lv_biz_open_dt.value,
        offwkYnEtc: '', // 기타
        pepYn: this.popupObj.call_tp == 'PEP' ? 'Y' : 'N', // PEP고객 Y.관련있음 N.관련없음
        chkYn: this.lv_rad_chk1.value, // 계좌주동일여부 Y or N
        custNm: custNm, // 계좌주 성명
        relGbNm: relGbNm, // 계좌주 관계
        rnameNo: rnameNo, // 계좌주 실명번호
        birthMmdd: birthMmdd, // 계좌주 생년월일
        nationality: nationality, // 계좌주 국적
        addr: addr, // 계좌주 주소
        entrTelNo: entrTelNo, // 계좌주 연락처
        currYmd: '', // 없음
        agntNm: '', // 없음

        chkYn3: this.lv_rad_chk2.value, // 법인동일여부 Y or N
        prhsReCom: prhsReCom, // 법인단체성명
        estbPrhsRel: estbPrhsRel, // 법인관계(직위로대체)
        estbBsno: estbBsno, // 법인번호
        insrStndIndsTypeCom: insrStndIndsTypeCom, // 설립목적
        natC: natC, // 설립국가
        estbAddr: estbAddr, // 법인주소
        estbTelNo: estbTelNo // 법인전화번호
      }
      formList.push(FS000009)

      FSInfoUtil.commReportInfo(this, formList, '', false, 'MSPFS306P')
      // FSInfoUtil.commReportInfo(this, formList)
    }
  }
}
</script>
<style scoped></style>
