/*
 * 업무구분: 영업
 * 화 면 명: MSPFS303P
 * 화면설명: FATCA_개인_P CU009903P
 * 작 성 일: 2023.05.12
 * 작 성 자: 이태흥
 */

<template>

    <mo-modal class="fts-modal medium" ref="modal" title="FATCA 개인 해외거주자 및 납세의무자 확인">
      <template>
        <div class="wrap-modalcontents">
          
          <p class="exp"> FATCA/CRS 사전/본인확인서 대상자입니다. </p>
          <div class="wrap-content mt16">
            <p class="exp">
              미국 납세의무자(이중국적 포함 시민권자, 영주권자, 미국 세법상 미국거주자)에 해당되거나, <!-- 문구수정 "시민권자 > 이중국적 포함 시민권자" 2023-04-19 jy -->
              한국과 미국 이외에 조세목적상 거주지(=해외 납세의무 국가 또는 지역)가 있습니까? <!-- 문구수정 "한국 이외에 > 한국과 미국 이외에" 2023-04-19 jy -->
            </p>
            <div class="wrap-input mt20">
              <mo-radio-wrapper 
                :items="radio1" 
                v-model="lv_rad_fatca_person_gb.value" 
                class="row"
                :class="lv_rad_fatca_person_gb.error ? 'error' : ''"
              />
            </div>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_InputValid1()">확인</mo-button>
        </div>
      </template>

      <!-- alert modal popup -->
      <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>
    </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS303P",
    screenId: "MSPFS303P",
    props: {
        popupObj: {type:Object, default:null}
    },
    components: {
      'fs-alert-popup': FSAlertPopup
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
      this.$bizUtil.insSrnLog("MSPFS303P")
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pAlertPopupObj: {},        
        lv_commCode: {},
        // 입력사항
        lv_rad_fatca_person_gb: {},   // 해당유무
        // 고객정보
        lv_cust_info: {},
        /***********************************************************************************
         * F10570283 : 국적/거주국 체크
         * F10570284 : FATCA 등록
         ***********************************************************************************/
        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj()
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      modal() {
        return this.$refs.modal
      },

      radio1() {
        let rtn= []
        rtn.push({value: 'P0', text: '해당사항 없음'})
        rtn.push({value: 'P1', text: '해당함'})
        return rtn
      }      

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_Open() {
        this.fn_Init()
        this.modal.open()
      },
      fn_Close (fatcaGb) {
        // 2024.01.17 ASR240100575 FATCA팝업 안닫힘 수정
        this.lv_rad_fatca_person_gb.value = fatcaGb
        this.fn_ReportPrint1()
        if ( fatcaGb === 'P0') {
          this.$emit('fs-popup-callback', 'MSPFS303P', {cust_no: this.popupObj.bfcer_cust_no})
        } else {
          this.$emit('fs-popup-callback', 61)
        }
        this.modal.close()
      },
      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        // 입력사항
        this.lv_rad_fatca_person_gb   = FSCommUtil.gfn_dataSet(0, 'P0', false, '', 'rad_fatca_person_gb')    // 해당유무
        // 고객정보
        this.lv_cust_info = {}
      },
      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },


      /**********************************************************************************
       * Function명 : fn_InputValid1
       * 설명       : 입력값 체크
       **********************************************************************************/
      fn_InputValid1 () {
        console.log('fn_InputValid1...')

        let t_popupObj = {}

        if( FSCommUtil.gfn_length(this.popupObj.bfcer_cust_no) != 7 ) {
          t_popupObj = {
            content: '고객번호 입력오류입니다.'
          }
          this.fn_AlertPopup(0, t_popupObj)
          return
        }
        
        if( FSCommUtil.gfn_isNull(this.lv_rad_fatca_person_gb.value) ) {
          t_popupObj = {
            content: '대상자구분을 선택해주십시오.'
          }
          this.fn_AlertPopup(0, t_popupObj)
          this.lv_rad_fatca_person_gb.value = 'P0'
          return
        }

        this.fn_InputValid2()
      },


      /**********************************************************************************
       * Function명 : fn_InputValid2
       * 설명       : 입력값 체크
       **********************************************************************************/
      fn_InputValid2 () {
        console.log('fn_InputValid2...')

        // 선택값이 '해당사항없음'일 시 국적이나 거주국이 한국 이외인지  체크
        if ( this.lv_rad_fatca_person_gb.value === 'P0' ) {
          // 국적/거주국이 한국 이외인지 체크
          this.fn_InquiryUsaYn()
        } else {
          //this.fn_ReportPrint1()  
          let t_popupObj = {
            confirm: true,
            confirmData: this.lv_rad_fatca_person_gb.value,
            confirmFunc: this.fn_Close
          }
          this.fn_AlertPopup(5, t_popupObj)
        }
      },
      /**********************************************************************************
       * Function명 : fn_InquiryUsaYn, fn_InquiryUsaYnResult
       * 설명       : 국적/거주국 체크
       **********************************************************************************/
      fn_InquiryUsaYn () {
        console.log('fn_InquiryUsaYn...')

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570283_S'
        this.eaiCommObj.params = {
          bfcer_cust_no     : this.popupObj.bfcer_cust_no,
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_InquiryUsaYnResult)
      },

      fn_InquiryUsaYnResult (pResultData) {
        console.log('fn_InquiryUsaYnResult...')

        let t_data = pResultData.data
        this.lv_cust_info = pResultData.data
        console.log(this.lv_cust_info)

        // 국적,거주국이 한국이 아니여도  고객이 계속해서 해당사항없음으로 진행하면 그대로 받아들임 (확인의무를 고객에게 부과한다) 
        // 2024.01.17 ASR240100575 FATCA팝업 안닫힘 수정        
        if( t_data.bfcer_naty_cntry_cd != 'KR' || t_data.fnd_dmcl_cntry_cd != 'KR' ) {
          let t_popupObj = {
            cancel : true,
            closeFunc: this.fn_Close,
            closeData: 'P1',
            confirm: true,
            confirmFunc: this.fn_InsertFatcaCheck,
            content: "국적 또는 거주국이 '한국' 이외 국가로 등록된 고객입니다.\n계속해서 '해당사항없음' 으로 진행하시겠습니까?"
          }
          this.fn_AlertPopup(0, t_popupObj)

        } else {
          this.fn_InsertFatcaCheck()
        }
      },
      /**********************************************************************************
       * Function명 : fn_InsertFatcaCheck, fn_InsertFatcaCheckResult
       * 설명       : FATCA 등록
       **********************************************************************************/
      fn_InsertFatcaCheck () {
        console.log('fn_InsertFatcaCheck...')
        this.lv_rad_fatca_person_gb.value = 'P0'

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'I'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570284_S'
        this.eaiCommObj.params = {
          bfcer_cust_no     : this.popupObj.bfcer_cust_no,
          media             : 'I',
          corp_yn           : 'N',
          fatca_gb          : this.lv_rad_fatca_person_gb.value,
          tr_code           : this.popupObj.parent_tr_cd
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_InsertFatcaCheckResult)
      },

      fn_InsertFatcaCheckResult (pResultData) {
        console.log('fn_InsertFatcaCheckResult...')
        let t_data = pResultData.data
        
        if ( FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.error_msg)) ) {
          // 해당함 일 경우 0014 본인확인서 출력 (제외)
          // 2024.01.17 ASR240100575 FATCA팝업 안닫힘 수정
          let t_popupObj = {
            cancel: false,
            confirm: true,
            confirmData: this.lv_rad_fatca_person_gb.value,
            confirmFunc: this.fn_Close,
            content: '정상 등록 되었습니다.'
          }
          this.fn_AlertPopup(0, t_popupObj)
        }
      },
      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출 - 사전확인서
       ******************************************************************************/
      fn_ReportPrint1 () {
        console.log('fn_ReportPrint1....')
        let formList = []
        let FS000016 = {formId: 'FS000016'}

        FS000016.mappingData = {
          cnm     : this.lv_cust_info.fnd_cust_nm === undefined ? this.popupObj.pNm : this.lv_cust_info.fnd_cust_nm,                         //고객 성명
          btdt    : this.lv_cust_info.rlnm_no_stnd_birth_mmdd === undefined ? this.popupObj.pRno : this.lv_cust_info.rlnm_no_stnd_birth_mmdd,             //고객 생년월일
          chkYn   : this.lv_rad_fatca_person_gb.value === 'P0' ? '0' : '1'  //납세의무자 확인 체크박스
        }
        formList.push(FS000016)

        FSInfoUtil.commReportInfo(this, formList, '', false, 'MSPFS303P')
      }
    }
  }
</script>
<style scoped>
</style>